import { GET_ACCIDENT_REPORTS_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  reports: [],
  totalReports: 0,  // for pagination
};

const accidentReports = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACCIDENT_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.payload.records || [],
        totalReports: action.payload.totalRecords || 0,
      };
    default:
      return state;
  }
};

export default accidentReports;
