import { GET_VENDORS_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  vendors: [],
  vendorTypes: [],
  totalVendors: 0,  // for pagination
};

const vendorList = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VENDORS_SUCCESS:
      return {
        ...state,
        vendors: action.payload.data || [],
        totalVendors: action.payload.totalVendors || 0,
        vendorTypes: action.payload.types || [],
      };
    default:
      return state;
  }
};

export default vendorList;
