import { takeEvery, put, call } from "redux-saga/effects";
import { GET_MAINTANANCE_REQUESTS, GET_MAINTANANCE_REQUESTS_FAIL, GET_MAINTANANCE_REQUESTS_SUCCESS } from "./actionTypes";
import { apiSuccess, apiFail } from './actions';
import {
  getPosMaintance
} from "helpers/fakebackend_helper"; // updated import name to avoid conflicts
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function* fetchMaintananceRequests({ payload }) {
  try {
    const { status, category, startDate, endDate, web, page, limit, laundry} = payload;
    const response = yield call(getPosMaintance, { status, category, startDate, endDate, web, laundry, page, limit });
    yield put(apiSuccess(response, GET_MAINTANANCE_REQUESTS_SUCCESS));
    // Check if the response is successful, if so, dispatch the success action
    if (response.statusCode == 200) {
      yield put(apiSuccess(response, GET_MAINTANANCE_REQUESTS_SUCCESS));
    } else {
      // Handle error response
      yield put(apiFail(response.message || 'Something went wrong', GET_MAINTANANCE_REQUESTS_FAIL));
    }
  } catch (error) {
    // Handle any unexpected errors
    console.log('error', error)
    yield put(apiFail(error.message, GET_MAINTANANCE_REQUESTS_FAIL));
    toastr.error('failed to fetch maintanance requests');
  }
}

function* posMaintananceSaga() {
  yield takeEvery(GET_MAINTANANCE_REQUESTS, fetchMaintananceRequests);
}

export default posMaintananceSaga;
