import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags";
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"
import MapsGoogle from "pages/Maps/MapsGoogle";
import MultiSelect from "react-multiple-select-dropdown-lite";
import Pages404 from "pages/Utility/pages-404";
import DataTable from "react-data-table-component";
import {validateEmail, validatePassword} from "../../helpers/validationHelper"
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Card,
  CardBody,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Form,
  Input,
  InputGroup, InputGroupText
} from "reactstrap"
import classnames from "classnames"

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { postArea } from "helpers/LaundryHelper"
import { createRedeemPackage, updateRedeemPackage } from "helpers/redeemPackagesHelper"
import { searchUsers, filterUsers, getRedeemPkgsList, getRedeemPkgDetail } from "../../store/actions";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom';
import google_map_logo from '../../assets/images/google_map_logo.png'
import Switch from "react-switch"
import ReactSwitch from "react-switch"
import Select from "react-select"
import { Offsymbol, OnSymbol } from "components/Common/Symbol"

const RedeemPackages = props => {
  const [pkgModal, SetPkgModal] = useState(false)
  const [lat, SetLat] = useState("24.71894638877753")
  const [lng, SetLng] = useState("46.675559402959095")
  const [partnerSubscription, setPartnerSubscription] = useState(true)
  const [carpetWash, setCarpetWash] = useState(true)
  const [clothWash, setClothWash] = useState(true)
  const [status, setStatus] = useState(true)
  const [vatToggle, setVatToggle] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [isEditItem, setIsEditItem] = useState({})
  const [userData, setUserData] = useState({});
  const [listData, setListData] = useState([]);

  const [points, setPoints] = useState("");
  const [Commission, setCommission] = useState("");
  const [VatPercent, setVatPercent] = useState("");
  const [Type, setType] = useState("");
  const [areaList, setAreaList] = useState([]);
  const [areaVal, setAreaVal] = useState([]);
  const [areaOption, setAreaOption] = useState([]);
  const [addressAr, setAddressAr] = useState("");
  const [addressEn, setAddressEn] = useState("");
  const [shortName, setShortName] = useState("");
  const [crNumber, setCrNumber] = useState("");
  const [error, setError] = useState({});
  const [activeTab, setactiveTab] = useState("1");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [searchText, setSearchText] = useState("")
  const [pkgValue, setPkgValue] = useState(0)
  const [packageType, setPackageType] = useState("")
  

  const { RedeemPkgList, PackageDetail, users, roles, filteredUsers} = useSelector(state => ({
    RedeemPkgList: state.RedeemPackages.Packages,
    PackageDetail: state.RedeemPackages.PackageDetail,
    roles: state.permission.permissions,
    users: state.Users.users,
    filteredUsers: state.Users.filteredUsers
  }))

  useEffect(() => {
    if(!users) dispatch(searchUsers({ webPermissions: true }))
    dispatch(getRedeemPkgsList())
  }, [])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {
    setListData(RedeemPkgList)
  }, [RedeemPkgList])

  const handleSearch = (val) => {
    
    val = val?.toLowerCase()
    let filterBySearch = RedeemPkgList.filter(item => item?.areas?.findIndex((data) => data?.name?.toLowerCase()?.includes(val)) !== -1 || (item?.status && val == "on") || (!item?.status && val == "off") || JSON.stringify(item?.points).includes(val) || JSON.stringify(item?.value).includes(val));
    if (!val) {
      setListData(RedeemPkgList)
    } else {
      setListData(filterBySearch)
    }
    // console.log("filterBySearch", filterBySearch);
  }

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  function getLocation() {
    setIsEditItem({});
    setEmail("");
    setPassword("");
    SetPkgModal(true)
  }

  function showPosition(position) {
    SetLat(position.coords.latitude);
    SetLng(position.coords.longitude);
  }

  useEffect(() => {
    if (!pkgModal) {
      setPoints("")
      setPackageType("")
      setPkgValue(0)
      setAreaVal("")
      setError({})
      setStatus(true)
      setIsEdit(false);
    }
    
  }, [pkgModal])


  // useEffect(async () => {
  //   const AreaResponse = await postArea({});
  //   setAreaList(AreaResponse?.record);
  //   setAreaOption(
  //     AreaResponse?.record?.map((item) => {
  //       return { label: item?.name, value: item?._id }
  //     })
  //   )
  // }, [])

  const handleOnchange = (val) => {
    setAreaVal(val ? val.split(",") : [])
  }

  const GET_AREA = async () => {
    getLocation()
  }

  const UPDATE_PACKAGE = (item) => {
    console.log(item);
    setPoints(item.points)
    setPackageType(item.packageType)
    setPkgValue(item.value)
    setAreaVal(item.areas)
    setAreaVal(item.areas.map((item2) => item2._id));
    setStatus(item.status)
    setIsEdit(true);
    setIsEditItem(item)
    SetPkgModal(true)
  }

  var node = useRef();
  const keyField = "id";
  const { SearchBar } = Search;
  const sizePerPage = 10;

  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: listData.length, // replace later with size(users),
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  let ListColumns = [
    {
      name: "Points",
      sortable: true,
      selector: row => row.points,
      wrap: true,
    }, {
      name: "Type",
      sortable: true,
      selector: row => row.packageType,
      center: true,
      wrap: true,
    },
    {
      name: "Value",
      sortable: true,
      selector: row => (
        row.packageType=="SAR"?(row.value + " SR"):"-"
      ),
      center: true,
      wrap: true,
    }, {
      name: "Area",
      wrap: true,
      sortable: true,
      center: true,
      selector: row => (
        <div>
          {row?.areas?.map((item2, index) => {
            return `${item2?.name} |`
          })}
        </div>
      )
    },
    //  {
    //   text: "Area",
    //   dataField: "areas",
    //   sortable: true,
    //   formatter: (cell, row, rowIndex, extraData) => (
    //     <div>
    //       {row?.areas?.map((item2, index) => {
    //         return areaList?.find(data1 => data1?._id === item2) ? ` ${areaList?.find(data1 => data1?._id === item2).name} |` : null

    //       })}
    //     </div>
    //   ),
    // },
    {
      name: "Status",
      sortable: true,
      center: true,
      selector: row => (
        <div>
          {row?.status ? "On" : "Off"}
        </div>
        // <div style={{ paddingLeft: 0 }} className="row d-flex form-check form-switch form-switch-lg mb-3">
        //   <Col sm={9} style={{ paddingLeft: 50 }}>
        //     <input
        //       type="checkbox"
        //       className="form-check-input"
        //       id="customSwitchsizelg horizontal-status-Input"
        //       defaultChecked={row?.status}
        //     />
        //   </Col>
        // </div>
      ),

      // eslint-disable-next-line react/display-name
    },

    // {
    //   dataField: "nameAr",
    //   text: "Name (Arabic)",
    //   sort: true,
    // },
    // {
    //   dataField: "commission",
    //   text: "Commission",
    //   sort: true,
    // },
    // {
    //   text: "Type",
    //   dataField: "type",
    //   sort: true,
    //   formatter: (cell, row, rowIndex, extraData) => (
    //     <div>
    //       {row.type == "0" ? "Laundry" : "Partner Laundry"}
    //     </div>
    //   )
    //   // eslint-disable-next-line react/display-name
    // },


  ];

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.redeemPackages?.update == true) {
    ListColumns.push(
      {
        name: "View",
        center: true,
        selector: row => {
          return <>
            <button onClick={() => UPDATE_PACKAGE(row)} className="btn btn-primary btn-sm" >
              View
            </button>
            {/* <a onClick={() => showEditLaundry(row._id)} >Edit</a>
             &nbsp; &nbsp;
             <a onClick={() => handleDeleteLaundry(row._id)} >Delete</a> */}
            {/* <a href="#" onClick={() => showPerms(row._id)} >Permissions</a> */}
          </>
        }
      });
  }

  function togglePkgModal() {
    SetPkgModal(!pkgModal);
    removeBodyCss();
  }


  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 3000,
    extendedTimeOut: 1000,
    closeButton: true,
    debug: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000
  };


  const handleValidSubmit = async (event, values) => {
    if (areaVal?.length < 1) {
      setError({ area: "Area Required" });
      return
    }
    const AddData = {
      points: points,
      packageType: packageType,
      areas: areaVal,
      value: pkgValue,
      status: status
    }

    if(pkgValue <=0 && packageType == "CARWASH"){
      AddData.value = 1;
    }

    let PackageResponse;
    let resTitle, resMsg;
    if (isEdit) {
      AddData["_id"] = isEditItem._id;
      PackageResponse = await updateRedeemPackage(AddData)
      resMsg = PackageResponse.statusCode == 200 ? "Package Updated" : "Unable to Update Package";
    } else {
      
      PackageResponse = await createRedeemPackage(AddData);
      resMsg = PackageResponse.statusCode == 200 ? "Package Added" : "Unable to Add Package";
      setIsEdit(true);
    }

    dispatch(getRedeemPkgsList());
    if (PackageResponse.statusCode == 200) {
      toastr.success(resMsg, "Success");
    } else {
      toastr.error(resMsg, "Error");
    }
    togglePkgModal();
  }

  const dispatch = useDispatch();

  useEffect(() =>{
    if(!email)
      return 

    if(!validateEmail(email)){
      setError({
        ...error, 
        email: true
      })
    }
    else{
      setError({
        ...error, 
        email: false
      })
    }
  }, [email])


  useEffect(async () => {
    if (PackageDetail?.areas?.length > 0) {
      // setAreaList([]);
      // setAreaVal(PackageDetails.areas)
      const AreaResponse = await postArea({});
      setAreaList(AreaResponse?.record);
    }
  }, [PackageDetail]);

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText])


  const [fetchStatusTrue, setFetchStatusTrue] = useState(true)
  const [fetchStatusFalse, setFetchStatusFalse] = useState(true)

  const filterItem = (item) => {
    if(fetchStatusTrue && fetchStatusFalse){
      return true
    } else if(fetchStatusTrue && !fetchStatusFalse){
      return item.status
    } else if(fetchStatusFalse && !fetchStatusTrue){
      return !item.status
    } else {
      return false
    }
  }

  const [selectedArea, setSelectedArea] = React.useState()
  const [areas, setAreas] = React.useState([])

  React.useEffect(async () => {
    const AreaResponse = await postArea({})
    setAreaOption(
      AreaResponse?.record?.map((item) => {
        return { label: item?.name, value: item?._id }
      })
    )
    setAreas(
      AreaResponse?.record?.map(item => {
        return { label: item?.name, value: item?._id, ...item }
      })
    )
  }, [])

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.redeemPackages?.read == true) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Redeem Packages | Kleen Admin Panel</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={props.t("Home")}
              breadcrumbItem={props.t("Redeem Packages")}
            />
            <Card>
              <CardBody>
                <Row className="mb-2">
                  <Col sm="6">
                    <div className="me-2 mb-2 d-inline-block">
                      <div className="position-relative d-flex">
                        <input
                          id="search-bar-10"
                          type="text"
                          aria-labelledby="search-bar-10-label"
                          className="form-control "
                          placeholder="Search"
                          autoComplete={false}
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                        <div className="ms-2" style={{ width: "20rem" }}>
                          <Select
                            placeholder="All Areas"
                            onChange={val => {
                              setSelectedArea(val)
                            }}
                            isClearable={true}
                            options={areas}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col sm="6" className="text-end d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center mx-3">
                    <span className={`me-2 ${fetchStatusTrue? "text-success font-weight-semibold": ""}`}>Show Enabled</span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={fetchStatusTrue}
                            onChange={(e) => { setFetchStatusTrue(!fetchStatusTrue) }}
                          />
                        </div>
                      </div>
                    <div className="d-flex align-items-center">
                    <span className={`me-2 ${fetchStatusFalse? "text-success font-weight-semibold": ""}`}>Show Disabled</span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={fetchStatusTrue}
                            onChange={(e) => { setFetchStatusFalse(!fetchStatusFalse) }}
                          />
                        </div>
                      </div>
                      </div>
                    {(userData?.permissions?.allPermissions == true || userData?.permissions?.laundries?.create == true) ?
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  "
                        onClick={() => GET_AREA()}
                      >
                        <i className="mdi mdi-plus me-1" />
                        Add Package
                      </Button>
                      : null
                    }
                  </Col>
                </Row>
                <Row>
                  <DataTable
                    columns={ListColumns}
                    data={listData.filter((item) => {
                      return filterItem(item)
                    }).filter(slot => {
                      if (!selectedArea) {
                        return true
                      }
                      return slot.areas
                        .map(area => area._id)
                        .includes(selectedArea?.value)
                    })}
                    pagination
                  />
                </Row>
              </CardBody>
            </Card>
            <div>
              <Modal
                size="lg"
                isOpen={pkgModal}
                toggle={() => {
                  togglePkgModal();
                }}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    <span >{isEdit ? "Update Redeem Package" : "Create Redeem Package"}</span>
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      SetPkgModal(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                
              <div className="modal-body">
                <TabContent activeTab={activeTab} className="p-3 text-muted">
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <CardText className="mb-0">
                          <AvForm
                            onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                            }}
                            >
                            <div className="row mb-2">
                              <Col sm={6}>
                                <Label htmlFor="horizontal-firstname-Input" className="col-sm-4 col-form-label">
                                  Points <span className="mandatory_feild">*</span>
                                </Label>
                                <AvField
                                  type="text"
                                  onChange={(e) => { setPoints(e.target.value) }}
                                  className="form-control"
                                  id="horizontal-firstname-Input"
                                  placeholder="Enter points"
                                  name="points"
                                  errorMessage="Please Enter Points"
                                  validate={{ required: { value: true } }}
                                  value={points}
                                />
                              </Col>
                              
                            </div>
                            <div className="row mb-2">
                              <Col sm={6}>
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-6 col-form-label"
                                >Package Type <span className="mandatory_feild">*</span></Label>
                                <AvField
                                  type="select"
                                  onChange={(e) => { setPackageType(e.target.value) }}
                                  className="form-control"
                                  id="horizontal-firstname-Input"
                                  placeholder="packageType"
                                  name="packageType"
                                  errorMessage="Please Select Package Type"
                                  validate={{ required: { value: true } }}
                                  value={packageType}
                                >
                                  <option value="">Select Package Type</option>  
                                  <option value="SAR">SAR</option>  
                                  <option value="CARWASH">Free Car Wash</option>  
                                </AvField>
                              </Col>

                              <Col sm={6}>
                                
                              </Col>
                            </div> 
                             
                            {packageType != "CARWASH"?
                            <div className="row mb-2">
                              <Col sm={6}>
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-6 col-form-label"
                                >Value. <span className="mandatory_feild">*</span></Label>
                                <InputGroup>
                                <AvField
                                  type="text"
                                  onChange={(e) => { setPkgValue(e.target.value) }}
                                  className="form-control"
                                  id="horizontal-firstname-Input"
                                  placeholder="Package Value"
                                  name="pkgValue"
                                  errorMessage="Please Enter Package Value"
                                  validate={{ required: { value: true } }}
                                  value={pkgValue}
                                />
                      <InputGroupText>SAR</InputGroupText>
                      </InputGroup>

                                
                              </Col>


                              <Col sm={6}>
                                
                              </Col>
                              </div>: null}

                              <div className="row mb-2">

                            
                              <Col sm={12}>
                                <Label
                                  htmlFor="horizontal-password-Input"
                                  className="col-sm-3 col-form-label"
                                >
                                  Areas <span className="mandatory_feild">*</span>
                                </Label>
                                <MultiSelect
                                  defaultValue={areaVal.toString()}
                                  className="multi-selects form-selects2 form-controlss areas-box"
                                  onChange={handleOnchange}
                                  options={areaOption}
                                />
                                {
                                  error?.area ? (
                                    <label style={{ color: "red" }}>{error?.area}</label>
                                  ) : null
                                }
                              </Col>
                              </div>
                              

                            <div className="row mb-2">
                              <Col sm={6} className="d-flex mt-2">
                                <Label
                                  htmlFor="horizontal-password-Input"
                                  className="col-form-label col-md-3"
                                >
                                  Status
                                </Label>
                                <div className="form-check form-switch">
                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    onColor="#626ed4"
                                    onChange={(e) => setStatus(!status)}
                                    checked={status}
                                  />
                                  {/* <AvField
                                  name="status"
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitchsizelg horizontal-status-Input"
                                  defaultChecked={status}
                                  onChange={(e) => { setStatus(e.target.checked) }}
                                /> */}
                                </div>
                              </Col>
                            </div>

                            <div className="row justify-content-end">
                              <Col sm={6} className="col-sm-6 text-end">
                                <div>
                                  <Button
                                    type="submit"
                                    color="primary"
                                  >
                                    {isEdit ? "Update Package" : "Add Package"}
                                  </Button>

                                  <button
                                    type="button"
                                    onClick={() => {
                                      togglePkgModal();
                                    }}
                                    className="m-1 btn btn-secondary"
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>

                                </div>
                              </Col>
                            </div>

                          </AvForm>
                        </CardText>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                          
                            <div className="row mb-2">
                              
                              <Col sm={6}>
                                <Label
                                  htmlFor="horizontal-email-Input"
                                  className="col-sm-3 col-form-label"
                                  >
                                  Email
                                  </Label>
                                  <Input
                                    type="email"
                                    name="email"
                                    className={"form-control " + (error?.email?"is-invalid":"")}
                                    id="horizontal-email-Input"
                                    placeholder="Email"
                                    errorMessage="Invalid Email"
                                    autoComplete={false}
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value) }}
                                    
                                  />
                                  {
                                    error?.email ? (
                                      <div className="invalid-feedback">Enter valid email</div>
                                    ) : null
                                  }
                              </Col>
                            </div>

                            <div className="row mb-2">
                              <Col sm={6}>
                                <Label
                                  htmlFor="horizontal-password-Input"
                                  className="col-sm-3 col-form-label"
                                >
                                  Set Password
                                </Label>
                                <input
                                  type="password"
                                  name="pass"
                                  className={"form-control " + (error?.password?"is-invalid":"")}
                                  autoComplete={false}
                                  id="horizontal-password-Input"
                                  value={password}
                                  onChange={(e) => { setPassword(e.target.value) }}
                                  placeholder="Set New Password"
                                  required
                                  // validate={{ required: { value: true } }}
                                />

                                  {
                                    error?.password ? (
                                      <div className="invalid-feedback">
                                        {(password && password.trim() == "" || !password)?"Please enter password":"Password must be at least 8 chars, 1 capital, 1 small letter, 1 number and 1 sepacial charater."}
                                      </div>
                                    ) : null
                                  }

                              </Col>

                            </div>

                            
                            
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                </TabContent>
              </div>
              </Modal>


            </div>
          </Container>
        </div>

      </React.Fragment>
    )
  } else {

    return <Pages404 />
  }

}
RedeemPackages.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(RedeemPackages)



