import { GET_MAINTANANCE_REQUESTS } from "./actionTypes";

export const apiSuccess = (data, actionType) => ({
  type: actionType,
  payload: data
})

export const apiFail = (data, actionType) => ({
  type: actionType,
  payload: data
})

export const getPosMaintanance = (data) => ({
  type: GET_MAINTANANCE_REQUESTS,
  payload: data
})