import React, { useEffect, useState, useRef, useCallback } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import DataTable from "react-data-table-component"
import Skeleton from "react-loading-skeleton"
import * as moment from "moment"
import classNames from "classnames"
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import Editable from "./components/Editable"
import "toastr/build/toastr.min.css"
import cancelIcon from "./icons/cancel.png"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { Container, 
  Row, 
  Col, 
  Badge, 
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Card, CardBody,
  Button
} from "reactstrap"

//import action
import {
  getOngoingOrders,
  getLaundrylist,
  getDriverlist,
  onGoingOrderLoaded,
  searchUsers,
  setOrdersActiveTab,
  getAllOrdersSearch,
  updateOrderPayWithCash,
  getReasons,
  updateOrdersInvoice,
  getFilterBySearch,
  removeBagFee,
} from "../../store/actions"

import ActionModal from "components/Modals/ActionModal"
import CancelOrderModal from "./modals/CancelModal"
import EditNoteModal from "./modals/EditNoteModal"

//redux
import { validateNumber } from "helpers/validationHelper"
import { debounce, filter } from "lodash"
import { postWeb } from "helpers/api_helper"
import { orderStatus } from "constants/app"; 
const OngoingOrders = () => {
  const {
    Allorders,
    laundryList,
    driverList,
    users,
    pickup_waiting_orders,
    delivery_waiting_orders,
    laundry_delayed_orders,
    delivery_inprogress_orders,
    all_count,
    cashOnDeliveryOrder,
    cancelReasons,
    searchedOrders
  } = useSelector(state => ({
    Allorders: state.Orders.Allorders,
    laundryList: state.ecommerce.laudrylist,
    driverList: state.ecommerce.driverlist,
    users: state.Users.users,
    pickup_waiting_orders: state.Orders.pickup_waiting_orders,
    delivery_waiting_orders: state.Orders.delivery_waiting_orders,
    laundry_delayed_orders: state.Orders.laundry_delayed_orders,
    delivery_inprogress_orders: state.Orders.delivery_inprogress_orders,
    all_count: state.Orders.all_count,
    cashOnDeliveryOrder: state.Orders.cashOnDeliveryOrder,
    cancelReasons: state.Orders.reason,
    searchedOrders : state.Orders.searchedOrders
  }))

  const history = useHistory()

  const [ordersData, setOrdersData] = useState([])
  const [pageLoading, setPageLoading] = useState(true)
  const [editPermission, setEditPermission] = useState(false)
  

  const [activeTab, setActiveTab] = useState("all-order")
  const [allOrderCount, setAllOrderCount] = useState(0)
  const [hideFillter, setHideFillter] = useState(false)
  const [allOrderFilter, setAllOrderFilter] = useState({
    status: [
      "created",
      "picked",
      "in_laundry",
      "washing",
      "ready",
      "out_laundry",
    ],
    paid: false,
    unpaid: true,
    category: ["online"],
    newCustomer: false,
    pickupDriver: undefined,
    laundry: undefined,
    delayed: false,
    type: undefined,
    dryClean: false
  })

  const [selectedOrder, setSelectedOrder] = useState({})

  const [showCashOnDeliveryModal, setShowCashOnDeliveryModal] = useState(false)
  const [cashOnDeliveryOrderID, setCashOnDeliveryOrderID] = useState("")

  const updateOrderCashOnDelivery = () => {
    dispatch(updateOrderPayWithCash({ order_id: cashOnDeliveryOrderID }))
  }

  useEffect(() => {
    reloadData()
  }, [cashOnDeliveryOrder])

  const [showCancelModal, setShowCancelModal] = useState(false)
  const [cancelOrderID, setCancelOrderID] = useState("")

  const onOrderCancel = (reasonID, customReason) => {
    dispatch(
      updateOrdersInvoice({
        _id: cancelOrderID,
        status: "cancelled",
        orderCancellation: {
          reason: reasonID,
          otherReason: customReason,
        },
      })
    )
    // TODO(akash): find a better way to refresh the data
    setTimeout(() => {
      reloadData()
    }, 500)
  }

  const [showEditNoteModal, setShowEditNoteModal] = useState(false)

  const onEditNote = note => {
    dispatch(
      updateOrdersInvoice({
        _id: selectedOrder._id,
        note: note,
      })
    )
    setTimeout(() => {
      reloadData()
    }, 500)
  }

  const [modal, setModal] = useState(false);
  const [searchCategory, setSearchCategory] = useState("mobile");
  const [searchLabel, setSearchLabel] = useState("Mobile");
  const [searchPlaceholder, setSearchPlaceholder] = useState("Enter mobile number");
  const [filterType, setFilterType] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [searchError, setSearchError] = useState("");
  
  const completedStatus = [orderStatus.CANCELLED, orderStatus.DELIVERED, orderStatus.ACCEPTED];
  const [showRemoveBagFeeModal, setShowRemoveBagFeeModal] = useState(false)

  const removeOrderBagFee = () => {
    dispatch(
      removeBagFee({
        order_id: selectedOrder._id,
      })
    )
    reloadData()
  }

  const getTimer = useCallback((order, typeOfTimer) => {
    let dateCompare

    if (typeOfTimer == "delivery") {
      if (order.deliveryDate && order.deliveryTimeslot?.end) {
        const delDateTime = new Date(
          order.deliveryDate + " " + order.deliveryTimeslot?.end + ":00"
        )
        dateCompare = delDateTime.setHours(delDateTime.getHours())
      } else {
        const startTime = new Date(order.startTime)
        const pickUpTime = new Date(
          order.pickupDate + " " + order.pickupTimeslot?.start + ":00"
        )
        if (order.pickupDate) {
          dateCompare = pickUpTime.setHours(pickUpTime.getHours() + 36)
        } else {
          dateCompare = startTime.setHours(startTime.getHours() + 36)
        }
      }
    } else if (typeOfTimer == "pickup") {
      if (order.pickupDate && order.pickupTimeslot?.start) {
        const pickupDateTime = new Date(
          order.pickupDate + " " + order.pickupTimeslot?.start + ":00"
        )

        dateCompare = pickupDateTime.setHours(pickupDateTime.getHours())
      } else {
        const startTime = new Date(order.startTime)
        dateCompare = startTime.setHours(startTime.getHours() + 4)
      }
    } else if (typeOfTimer == "laundry") {
      const invoiceTime = new Date(order.invoiceTime)
      if (order.type == "clothes") {
        dateCompare = invoiceTime.setHours(invoiceTime.getHours() + 12)
      } else if (order.type == "carpet") {
        dateCompare = invoiceTime.setHours(invoiceTime.getHours() + 72)
      }
    }

    const currentDateTime = new Date()
    const diffMs = dateCompare - currentDateTime // minus ((diff + 180) * 60 * 1000) for saudi arabia time zone
    const hours = Math.floor(diffMs / (1000 * 60 * 60))
    const minutes = Math.floor((diffMs / (1000 * 60)) % 60)
    const seconds = Math.floor((diffMs / 1000) % 60)
    if (diffMs > 0) {
      return (
        <Badge
          className={`text-capitalize font-size-10 badge-soft-success rounded-pill`}
          color="success"
        >
          {hours + "h " + minutes + "m " + seconds + "s "}{" "}
        </Badge>
      )
    } else {
      return (
        <Badge
          className={`text-capitalize font-size-10 badge-soft-danger blink_me rounded-pill`}
          color="success"
        >
          00:00:00
        </Badge>
      )
    }
  }, [])

  const onUpdate = (_id, key, value) => {
    let ordersCopy = [...ordersData]
    const index = ordersCopy.findIndex(item => item._id == _id)
    if (key == "bagNo") {
      ordersCopy[index]["customer"][key] = value
    } else {
      ordersCopy[index][key] = value
    }

    setOrdersData(ordersCopy)
  }

  const handlePaid = () => {
    setAllOrderFilter({ ...allOrderFilter, paid: !allOrderFilter.paid })
  }

  const handleUnPaid = () => {
    setAllOrderFilter({ ...allOrderFilter, unpaid: !allOrderFilter.unpaid })
  }

  const toggle = () => {
    setModal(!modal);
  };

  const handleApply = () => {
    setPageLoading(true)
    let apiData = {
      dashboard: true,
    }
    if (allOrderFilter.status.length > 0) {
      apiData = { ...apiData, status: allOrderFilter.status }
    }
    if (allOrderFilter.paid !== allOrderFilter.unpaid) {
      apiData = { ...apiData, paid: allOrderFilter.paid }
    }
    if (allOrderFilter.category.length == 1) {
      apiData = { ...apiData, category: allOrderFilter.category[0] }
    }
    if (allOrderFilter.laundry) {
      apiData = { ...apiData, laundry: allOrderFilter.laundry }
    }
    if (allOrderFilter.pickupDriver) {
      apiData = { ...apiData, pickupDriver: allOrderFilter.pickupDriver }
    }
    if (allOrderFilter.delayed) {
      apiData = { ...apiData, delayed: allOrderFilter.delayed }
    }
    if (allOrderFilter.type) {
      apiData = { ...apiData, type: allOrderFilter.type }
    }
    if (allOrderFilter.dryClean) {
      apiData = { ...apiData, dryClean: allOrderFilter.dryClean }
    }
    apiData = { ...apiData, newCustomer: allOrderFilter.newCustomer }
    dispatch(getAllOrdersSearch(apiData)) //
  }

  const validateNumeric = () => {
    if(["orderID", "customerID", "mobile"].includes(searchCategory)){
      if(!validateNumber(searchValue)){
        setSearchError("Please enter numeric value.");
        return false;
      }
    }
    setSearchError("");
    return true;

  }

  const formatMobileNumber = (mb) => {
    let mobile = mb;
    if(mb.charAt(0) === '0')
    {
      mobile = mb.substring(1);
    }
    return mobile;
  }

  const handleSearch = () => {

    if(searchValue == undefined || searchValue == ""){
      setSearchError("Search field is mandatory")
      return;
    }

    if(searchValue.trim() == ""){
      setSearchError("Search field is mandatory")
      return;
    }

    //Validate values
    if(!validateNumeric()){
      return;
    }

    const phonePrefix = process.env.REACT_APP_PHONE_PREFIX;
    const phoneMaxLen = phonePrefix==91?11:10;

    let value = searchValue;  
    if(searchCategory == "mobile"){
      const chars = Array.from(value);
      if(chars[0] != "0"){
        setSearchError("Mobile number should start with 0");
        return;
      }
      if(searchValue.length != phoneMaxLen){
        setSearchError("Invalid mobile number")
        return;
      }
      value = phonePrefix + formatMobileNumber(searchValue);
    }

    setPageLoading(true)
    let apiData = {
      [searchCategory]: value
    }
    
    dispatch(getFilterBySearch(apiData))
  }

  const orderListColumns = [
    {
      name: "Order",
      width: "150px",
      selector: row => {
        const hasNote = row.note ? true : false
        const isOnlineOrder = row.category === "online"
        return (
          <>
            <div className="p-1">
              {row.orderID}{" "}
              {isOnlineOrder && (
                <span className="text-truncate">
                  (
                  <a
                    className="editable-click"
                    style={
                      hasNote ? { color: "#0097CE" } : { color: "#F46A6A" }
                    }
                    onClick={() => {
                      setSelectedOrder(row)
                      setShowEditNoteModal(true)
                    }}
                  >
                    {row.note || <span>{"Add Note"}</span>}
                  </a>
                  )
                </span>
              )}
              <br />
              <span className="text-capitalize f-3">
                {row?.location?.type} - {row.type}
              </span>
            </div>
          </>
        )
      },
      sortable: true,
    },
    {
      name: "Customer",
      width: 30,
      grow: 0,
      compact: true,
      selector: row => {
        return (
          <>
            <div className="p-1">
              {row.customer?.customerID}
              <br />
              <span className="text-capitalize f-3">
                {row?.location?.area?.name}
              </span>
            </div>
          </>
        )
      },
    },
    {
      name: "Date",
      width: "210px",
      grow: 0,
      compact: true,
      selector: row => {
        return (
          <>
            <div className="p-1">
              {row.pickupDate ? (
                <>
                  P:{" "}
                  {moment(
                    row.pickupDate + " " + row.pickupTimeslot?.start
                  ).format("DD/MM/YYYY hh:mm A")}{" "}
                  <br />
                </>
              ) : null}

              {row.deliveryDate ? (
                <>
                  D:{" "}
                  {moment(
                    row.deliveryDate + " " + row.pickupTimeslot?.end
                  ).format("DD/MM/YYYY hh:mm A")}{" "}
                </>
              ) : (
                <>S: {moment(row.startTime).format("DD/MM/YYYY hh:mm A")}</>
              )}
            </div>
          </>
        )
      },
    },
    {
      name: "Bag",
      width: "120px",
      grow: 0,
      selector: row => {
        const isRemoveBagFeeVisible = row.bagFee && row.bagFee > 0; 
        const hasEditPermission = editPermission == true
        const isInvoiceGenerated = ["washing", "ready", "out_laundry"].includes(row.status)
        const isPaid = row?.paid == true;
        return (
          <>
            {((row.customer?.plan || row.customer?.bagNo) &&
            editPermission == true && !completedStatus.includes(row.status))? (
              <Editable fieldName="bagNo" row={row} onUpdate={onUpdate} />
            ) : (
              <div className="p-1">{row.customer?.bagNo}</div>
            )}
            {hasEditPermission && isRemoveBagFeeVisible && !isPaid && (
              <>
                <div
                  className="editable-click text-danger cursor-pointer"
                  onClick={() => {
                    setSelectedOrder(row)
                    setShowRemoveBagFeeModal(true)
                  }}
                >
                  Remove Bag Fee
                </div>
              </>
            )}
          </>
        )
      },
    },
    {
      name: "Laundry",
      sort: true,
      wrap: true,
      selector: row => {
        return (
          <>
            {(!row.invoiceTime && editPermission == true && !completedStatus.includes(row.status)) ? (
              <>
                <Editable
                  fieldName="laundry"
                  row={row}
                  itemlist={laundryList}
                  onUpdate={onUpdate}
                />
                <br />
              </>
            ) : (
              <>
                <div className="mt-1">
                  {row.laundry?.shortName
                    ? row.laundry?.shortName
                    : row.laundry?.nameEn}
                </div>
              </>
            )}

            {!row.readyToDeliveryTime && row.invoiceTime ? (
              <div className="mt-1">{getTimer(row, "laundry")}</div>
            ) : null}
          </>
        )
      },
    },
    {
      name: "Pickup",
      sort: true,
      wrap: true,
      selector: row => {
        return (
          <>
            {(!row.pickUpTime && !row.invoiceTime && !completedStatus.includes(row.status) && editPermission == true) ? (
              <Editable
                fieldName="pickup"
                row={row}
                itemlist={driverList}
                onUpdate={onUpdate}
              />
            ) : (
              <span>{row.pickupDriver?.name}</span>
            )}
            {!row.pickUpTime ? (
              <>
                <br />
                <div className="mt-1">{getTimer(row, "pickup")}</div>
              </>
            ) : null}
          </>
        )
      },
    },
    {
      name: "Delivery",
      sort: true,
      wrap: true,
      selector: row => {
        return (
          <>
            {(row.readyToDeliveryTime && !completedStatus.includes(row.status) && row.paid == true) ? (
              <>
                {editPermission == true ? (
                  <Editable
                    fieldName="delivery"
                    row={row}
                    itemlist={driverList}
                    onUpdate={onUpdate}
                  />
                ) : (
                  <span>{row.dropoffDriver?.name}</span>
                )}
              </>
            ) : (
              <div className="p-1 text-danger">Order not ready</div>
            )}
          </>
        )
      },
    },
    {
      name: "Status",
      maxWidth: "150px",
      width: "150px",
      grow: 0,
      selector: row => {
        const isInvoiceGenerated = ["washing", "ready"].includes(row.status)
        const isInvoiceGeneratedAndOnline =
          isInvoiceGenerated && row.category == "online"
        return (
          <div>
            <div>
              <Badge
                className={` text-capitalize font-size-10 badge-soft-primary mb-1 rounded-pill`}
                color="primary"
              >
                {row.status}
              </Badge>

              {row.paid == true ? (
                <Badge
                  className={` text-capitalize font-size-10 badge-soft-success mb-1 rounded-pill`}
                  color="success"
                >
                  PAID
                </Badge>
              ) : (
                <Badge
                  className={
                    ` text-capitalize font-size-10 badge-soft-danger mb-1 rounded-pill` +
                    `${(isInvoiceGeneratedAndOnline && row.whatsapp !== true) ? " cursor-pointer" : ""}`
                  }
                  color="danger"
                  onClick={e => {
                    if (isInvoiceGeneratedAndOnline && row.whatsapp !== true) {
                      setSelectedOrder(row)
                      setCashOnDeliveryOrderID(row._id)
                      setShowCashOnDeliveryModal(true)
                    }
                  }}
                >
                  UNPAID
                </Badge>
              )}
            </div>
            <span className="mt-1">{getTimer(row, "delivery")}</span>
          </div>
        )
      },
    },
    {
      width: "20px",
      compact: true,
      selector: row => {
        const isStatusBeforeWashing = [
          "created",
          "picked",
          "in_laundry",
        ].includes(row.status)
        const isOnlineOrder = row.category == "online"
        const isCancellable = isStatusBeforeWashing && isOnlineOrder
        return (
          <>
            {isCancellable && (
              <img
                src={cancelIcon}
                className="cursor-pointer"
                onClick={() => {
                  setSelectedOrder(row)
                  setCancelOrderID(row._id)
                  setShowCancelModal(true)
                }}
              />
            )}
          </>
        )
      },
    },
  ]

  const onChangeTabs = tabsType => {
    setPageLoading(true)
    setOrdersData([])

    dispatch(setOrdersActiveTab(tabsType))

    if (tabsType == activeTab) {
      setActiveTab("")
      setTimeout(() => {
        setActiveTab(tabsType)
      }, 10)
    } else {
      setActiveTab(tabsType)
    }
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (users.length > 0) {
      const userData = users[0]
      if (
        userData?.permissions?.allPermissions == true ||
        userData?.permissions?.orders?.update == true
      ) {
        setEditPermission(true)
      } else {
        setEditPermission(false)
      }
    }
  }, [users])

  useEffect(() => {
   if(!users) dispatch(searchUsers({ webPermissions: true }))
    dispatch(onGoingOrderLoaded(true))

    dispatch(getDriverlist({ online: true, status: true }))
    dispatch(getLaundrylist({ status: true }))
    dispatch(getReasons({ type: "cancellation" }))

    return () => {
      dispatch(onGoingOrderLoaded(false))
    }
  }, [])

  useEffect(() => {
    reloadOrders()
  }, [dispatch])

  useEffect(()=> {

    let _searchLabel, _searchPlaceholder;
    switch(searchCategory){
      case "mobile":
        _searchLabel = "Mobile"
        _searchPlaceholder = "Enter mobile number"
      break;

      case "customerID":
        _searchLabel = "Customer ID"
        _searchPlaceholder = "Enter customer ID"
      break;

      case "orderID":
        _searchLabel = "Order ID"
        _searchPlaceholder = "Enter order ID"
      break;

      case "billNo":
        _searchLabel = "Bill No"
        _searchPlaceholder = "Enter bill No"
      break;

      case "date":
        _searchLabel = "Invoice Date"
        _searchPlaceholder = "Enter Invoice Date"
      break;

      case "date":
        _searchLabel = "Invoice Date"
        _searchPlaceholder = "Enter Invoice Date"
      break;

      case "bagNo":
        _searchLabel = "Bag No"
        _searchPlaceholder = "Enter Bag No"
      break;

      case "customerID":
        _searchLabel = "Customer ID"
        _searchPlaceholder = "Enter customer ID"
      break;

    }

    setSearchLabel(_searchLabel);
    setSearchPlaceholder(_searchPlaceholder);
    setSearchValue("")
  }, [searchCategory])

  useEffect(() => {
    let fn = debounce(validateNumeric, 1000);
    fn();
  }, [searchValue])

  useEffect(() => {
    if(filterType?.key == "filter"){
      handleApply();
    }
    else if(filterType?.key == "search"){
      handleSearch();
    }
  }, [filterType])

  const reloadOrders = () => {
    setPageLoading(true)
    dispatch(
      getOngoingOrders({
        status: [
          "created",
          "picked",
          "in_laundry",
          "washing",
          "ready",
          "out_laundry",
        ],
        category: "online",
      })
    )
  }

  const onRowClicked = (row, e) => {
    if(row.status == orderStatus.CANCELLED){
      return;
    }

    history.push(`/create-invoice/${row._id}`, {
      prevPath: "/ongoing-orders",
      orderID: row._id,
    })
  }

  const reloadData = () => {
    setPageLoading(true)

    let apiData = {}
    if (allOrderFilter.status.length > 0) {
      apiData = { ...apiData, status: allOrderFilter.status }
    }
    if (allOrderFilter.paid !== allOrderFilter.unpaid) {
      apiData = { ...apiData, paid: allOrderFilter.paid }
    }
    if (allOrderFilter.category.length == 1) {
      apiData = { ...apiData, category: allOrderFilter.category[0] }
    }

    apiData = { ...apiData, newCustomer: allOrderFilter.newCustomer }

    if (activeTab == "waiting-pickup") {
      dispatch(
        getAllOrdersSearch({
          status: ["created"],
          category: "online",
          dashboard: true,
          allTabData: apiData,
        })
      )
    } else if (activeTab == "waiting-delivery") {
      dispatch(
        getAllOrdersSearch({
          status: ["ready"],
          category: "online",
          paid: true,
          dashboard: true,
          allTabData: apiData,
        })
      )
    } else if (activeTab == "laundry-delay") {
      dispatch(
        getAllOrdersSearch({
          status: ["washing"],
          category: "online",
          dashboard: true,
          allTabData: apiData,
        })
      )
    } else if (activeTab == "delivery-in-progress") {
      dispatch(
        getAllOrdersSearch({
          status: ["out_laundry"],
          category: "online",
          dashboard: true,
          allTabData: apiData,
        })
      )
    } else if (activeTab == "all-order") {
      handleApply()
    }
  }

  useEffect(reloadData, [activeTab])

  useEffect(() => {
    console.log("Called::", filterType, searchedOrders)
    if(filterType?.key != "search"){
      if (Allorders?.length) {
        if (activeTab == "all-order") {
          setAllOrderCount(Allorders.length)
        } else {
          setAllOrderCount(all_count)
        }
        setOrdersData(Allorders)
      } else {
        if (activeTab == "all-order") {
          setAllOrderCount(0)
        }
        setOrdersData([])
      }
    }
    else{
      if(searchedOrders && searchedOrders.length){
        setOrdersData(searchedOrders);
      }
      else{
        setOrdersData([])
      }
      setModal(false);
    }
    setPageLoading(false)
  }, [Allorders, searchedOrders])

  const handleFilterCategory = status => {
    let statusData = [...allOrderFilter.status]
    let indexOfCat = statusData.indexOf(status)
    if (indexOfCat !== -1) {
      statusData.splice(indexOfCat, 1)
    } else {
      statusData.push(status)
    }
    setAllOrderFilter({ ...allOrderFilter, status: statusData })
  }

  const handleSearchCategory = searchCategory => {

  }

  const _setFilterType = (type) => {
    if(type != "search"){
      setSearchValue("")
    }
    setFilterType({
      key : type,
      ts : new Date()
    });
  }

  const handleCategory = category => {
    let categoryData = [...allOrderFilter.category]
    let indexOfCat = categoryData.indexOf(category)
    if (indexOfCat !== -1) {
      categoryData.splice(indexOfCat, 1)
    } else {
      categoryData.push(category)
    }
    setAllOrderFilter({ ...allOrderFilter, category: categoryData })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Ongoing Orders | Kleen Admin Panel</title>
        </MetaTags>
        <Container fluid>
         
          <Row>
            <Col>
              <div className="mt-5 fixed-tabs">
                <div className="ms-auto mb-0">
                  <ul className="nav nav-pills nav-tabs-pills" role="tablist">
                    <li className="nav-item tab-item">
                      <div
                        // to="#"
                        className={classNames(
                          { active: activeTab == "all-order" },
                          "nav-link"
                        )}
                        onClick={() => {
                          onChangeTabs("all-order")
                        }}
                        id="wash"
                      >
                        All Orders ({allOrderCount})
                      </div>{" "}
                    </li>
                    <li className="nav-item tab-item">
                      <div
                        // to="#"
                        className={classNames(
                          { active: activeTab == "waiting-pickup" },
                          "nav-link"
                        )}
                        onClick={() => {
                          onChangeTabs("waiting-pickup")
                        }}
                        id="dryclean"
                      >
                        Waiting for Pickup ({pickup_waiting_orders})
                      </div>
                    </li>
                    <li className="nav-item tab-item">
                      <div
                        // to="#"
                        className={classNames(
                          { active: activeTab == "waiting-delivery" },
                          "nav-link"
                        )}
                        onClick={() => {
                          onChangeTabs("waiting-delivery")
                        }}
                        id="iron"
                      >
                        Waiting for delivery ({delivery_waiting_orders})
                      </div>
                    </li>
                    <li className="nav-item tab-item">
                      <div
                        className={classNames(
                          { active: activeTab == "laundry-delay" },
                          "nav-link"
                        )}
                        onClick={() => {
                          onChangeTabs("laundry-delay")
                        }}
                        id="iron"
                      >
                        laundry delay ({laundry_delayed_orders})
                      </div>
                    </li>
                    <li className="nav-item tab-item">
                      <div
                        className={classNames(
                          { active: activeTab == "delivery-in-progress" },
                          "nav-link"
                        )}
                        onClick={() => {
                          onChangeTabs("delivery-in-progress")
                        }}
                        id="iron"
                      >
                        Delivery in progress ({delivery_inprogress_orders})
                      </div>
                    </li>
                    {activeTab == "all-order" ? (
                      <li className="nav-item ms-auto">
                        <button
                          type="button"
                          disabled={pageLoading}
                          onClick={reloadData}
                          className="btn btn-primary rounded-circle mx-2"

                        >
                          <i className="fas fa-sync" style={{ fontSize: 10 }}></i>
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            setModal(true)
                          }
                          className="btn btn-success w-xs waves-effect waves-light"
                          style={{ height: "fit-content" }}
                        >
                          <i className="bx bx-search"></i> Search
                        </button>
                        {filterType?.key != "search"?
                        <button
                          type="button"
                          onClick={() =>
                            setHideFillter(hideFillter == false ? true : false)
                          }
                          className="btn btn-warning w-xs waves-effect waves-light ms-2"
                          style={{ height: "fit-content" }}
                        >
                          <i className="bx bxs-filter-alt"></i> Filter
                        </button>: null}
                      </li>
                    ) : null}
                  </ul>
                </div>
                <div></div>
                {filterType?.key == "search"?
                <div className="mt-2">
                  <Row>
                    <Col lg="12">
                      <Card className="mb-0">
                        <CardBody>
                          
                          <div className="d-flex align-items-center justify-content-start">
                          <h5 className="me-3">Searched By </h5>
                          <label className="text-capitalize d-block me-2">{searchCategory} : </label>
                          <Button
                            color="primary"
                            outline
                            size="sm"
                            
                          >
                            {searchValue}
                          </Button>

                          <Button
                            color="link text-info"
                            onClick={() => _setFilterType("filter")}
                            size="sm"
                            
                          >
                          <i className="bx bx-x"></i>  Clear Search
                          </Button>

                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>:null}
                
                <div
                  className={classNames("row mt-2", {
                    "d-none": activeTab !== "all-order" || hideFillter || filterType?.key == "search",
                  })}
                >
                  <Row>
                    <div className="col-md-2">
                      <div className="card card-body card-minimal-spacing remove-bottom-margin">
                        <h5 className="card-title text-center">Paid</h5>
                        <div className="control-group">
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={allOrderFilter.paid}
                              id="closeButton"
                              onChange={handlePaid}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="closeButton"
                            >
                              Paid
                            </label>
                          </div>
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={allOrderFilter.unpaid}
                              id="addBehaviorOnToastClick"
                              onChange={handleUnPaid}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="addBehaviorOnToastClick"
                            >
                              Not Paid
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card card-body card-minimal-spacing remove-bottom-margin">
                        <h4 className="card-title text-center">Category</h4>
                        <div className="control-group">
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={allOrderFilter?.category?.includes(
                                "offline"
                              )}
                              id="debugInfo"
                              onChange={() => handleCategory("offline")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="debugInfo"
                            >
                              Offline Orders
                            </label>
                          </div>
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={allOrderFilter?.category?.includes(
                                "online"
                              )}
                              id="progressBar"
                              onChange={() => handleCategory("online")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="progressBar"
                            >
                              Online Orders
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="card card-body card-minimal-spacing remove-bottom-margin">
                        <h4 className="card-title text-center">Status</h4>
                        <div className="control-group d-flex justify-content-between">
                          <div className="form-check col mb-2">
                            <input
                              className="form-check-input"
                              checked={allOrderFilter.status.includes(
                                "created"
                              )}
                              type="checkbox"
                              id="preventDuplicates"
                              onChange={() => handleFilterCategory("created")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="preventDuplicates"
                            >
                              Created
                            </label>
                          </div>
                          <div className="form-check col mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={allOrderFilter.status.includes("picked")}
                              id="addClear"
                              onChange={() => handleFilterCategory("picked")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="addClear"
                            >
                              Picked
                            </label>
                          </div>
                          <div className="form-check col mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={allOrderFilter.status.includes(
                                "in_laundry"
                              )}
                              id="newestOnTop"
                              onChange={() =>
                                handleFilterCategory("in_laundry")
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="newestOnTop"
                            >
                              In Laundry
                            </label>
                          </div>
                        </div>
                        <div className="control-group d-flex justify-content-between">
                          <div className="form-check col mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={allOrderFilter.status.includes(
                                "washing"
                              )}
                              id="Washing"
                              onChange={() => handleFilterCategory("washing")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Washing"
                            >
                              Washing
                            </label>
                          </div>
                          <div className="form-check col mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={allOrderFilter.status.includes("ready")}
                              id="Ready"
                              onChange={() => handleFilterCategory("ready")}
                            />
                            <label className="form-check-label" htmlFor="Ready">
                              Ready
                            </label>
                          </div>
                          <div className="form-check col mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={allOrderFilter.status.includes(
                                "out_laundry"
                              )}
                              id="Out-Laundry"
                              onChange={() =>
                                handleFilterCategory("out_laundry")
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Out-Laundry"
                            >
                              Out Laundry
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 d-flex">
                      <div className="card card-body card-minimal-spacing remove-bottom-margin">
                        <h4 className="card-title text-center">Filter</h4>
                        <div>
                          <div className="mb-1">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm"
                              onChange={e =>
                                setAllOrderFilter({
                                  ...allOrderFilter,
                                  laundry: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Laundry</option>
                              {laundryList.map(item => {
                                return (
                                  <option
                                    key={item._id}
                                    value={item._id}
                                    selected={
                                      allOrderFilter.laundry === item._id
                                    }
                                  >
                                    {item.shortName
                                      ? item.shortName
                                      : item.nameEn}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                          <div>
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm"
                              onChange={e =>
                                setAllOrderFilter({
                                  ...allOrderFilter,
                                  pickupDriver: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Driver</option>
                              {driverList.map(item => {
                                return (
                                  <option
                                    key={item._id}
                                    value={item._id}
                                    selected={
                                      allOrderFilter.pickupDriver === item._id
                                    }
                                  >
                                    {item.name}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-1">
                      <button
                        type="button"
                        onClick={() => _setFilterType("filter")}
                        className="btn btn-primary w-xs waves-effect waves-light btn-sm"
                        style={{ height: "fit-content" }}
                      >
                        Apply
                      </button>
                      <button
                        type="button"
                        onClick={() => setHideFillter(true)}
                        className="btn btn-secondary w-xs btn-sm mt-3"
                        style={{ height: "fit-content" }}
                      >
                        Close
                      </button>
                    </div>
                  </Row>
                  <Row className="mt-1">
                    <div className="col-md-2 d-flex">
                      <div className="card card-body card-minimal-spacing remove-bottom-margin">
                        <h4 className="card-title text-center">Only New</h4>
                        <div className="control-group d-flex justify-content-between">
                          <div className="form-check mb-2 my-auto">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={allOrderFilter.newCustomer}
                              value="checked"
                              id="New-Customer"
                              onChange={() =>
                                setAllOrderFilter({
                                  ...allOrderFilter,
                                  newCustomer: !allOrderFilter.newCustomer,
                                })
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="New-Customer"
                            >
                              Only New Customer
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 d-flex">
                      <div className="card card-body card-minimal-spacing remove-bottom-margin">
                        <h4 className="card-title text-center">Service Type</h4>
                        <div>
                          <div className="mb-1">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm"
                              onChange={e =>
                                setAllOrderFilter({
                                  ...allOrderFilter,
                                  type: e.target.value,
                                })
                              }
                            >
                              <option value="">Select</option>
                              <option value="clothes">Clothes</option>
                              <option value="carpet">Carpet</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card card-body card-minimal-spacing remove-bottom-margin">
                        <h4 className="card-title text-center">Delay</h4>
                        <div className="control-group">
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="label-laundry-delay"
                              checked={allOrderFilter.delayed}
                              onChange={() =>
                                setAllOrderFilter(filters => {
                                  return {
                                    ...filters,
                                    delayed: !filters.delayed,
                                  }
                                })
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="label-laundry-delay"
                            >
                              Laundry Delay
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <React.Fragment>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive table-ongoing-orders">
                      {pageLoading ? (
                        <table
                          className="table table align-middle table-nowrap"
                          style={{
                            marginTop:
                              (activeTab == "all-order" && hideFillter == false && filterType?.key != "search")
                                ? 215
                                : (filterType?.key == "search"?100:29),
                          }}
                        >
                          <tbody>
                            {[...Array(10).keys()].map(i => (
                              <tr key={i}>
                                <td>
                                  <Skeleton count={1} />
                                </td>
                                <td>
                                  <Skeleton count={1} />
                                </td>
                                <td>
                                  <Skeleton count={1} />
                                </td>
                                <td>
                                  <Skeleton count={1} />
                                </td>
                                <td>
                                  <Skeleton count={1} />
                                </td>
                                <td>
                                  <Skeleton count={1} />
                                </td>
                                <td>
                                  <Skeleton count={1} />
                                </td>
                                <td>
                                  <Skeleton count={1} />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div
                          style={{
                            marginTop:
                              (activeTab == "all-order" && hideFillter == false && filterType?.key != "search")
                                ? 215
                                : (filterType?.key == "search"?100:29),
                          }}
                        >
                          <DataTable
                            columns={orderListColumns}
                            data={ordersData}
                            pagination
                            onRowClicked={onRowClicked}
                            paginationPerPage={30}
                            customStyles={{
                              headRow: {
                                style: {
                                  minHeight: "30px",
                                },
                              },
                              rows: {
                                style: {
                                  minHeight: "30px",
                                },
                              },
                              headCells: {
                                style: {
                                  fontSize: "10px",
                                },
                              },
                              cells: {
                                style: {
                                  fontSize: "10px",
                                },
                              },
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-md-center mt-30">
                  <Col className="pagination pagination-rounded justify-content-end mb-2"></Col>
                </Row>
              </React.Fragment>
            </Col>
          </Row>
        </Container>
      </div>

      <ActionModal
        isOpen={showCashOnDeliveryModal}
        toggle={() => setShowCashOnDeliveryModal(!showCashOnDeliveryModal)}
        title={`Cash On Delivery (Order ID - ${selectedOrder.orderID})`}
        body="Are you sure you want to mark this order as cash on delivery?"
        actionButtonLabel="Yes"
        onAction={updateOrderCashOnDelivery}
      />

      <CancelOrderModal
        isOpen={showCancelModal}
        toggle={() => setShowCancelModal(!showCancelModal)}
        reasons={cancelReasons}
        onAction={onOrderCancel}
        order={selectedOrder}
      />

      <EditNoteModal
        isOpen={showEditNoteModal}
        toggle={() => setShowEditNoteModal(!showEditNoteModal)}
        onAction={onEditNote}
        order={selectedOrder}
        editPermission={editPermission}
      />

      <ActionModal
        isOpen={showRemoveBagFeeModal}
        toggle={() => setShowRemoveBagFeeModal(!showRemoveBagFeeModal)}
        title={`Remove Bag (Order ID - ${selectedOrder.orderID})`}
        body="Are you sure you want to remove bag?"
        actionButtonLabel="Yes"
        onAction={removeOrderBagFee}
      />

      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} tag="h4">
          Search
        </ModalHeader>
        <ModalBody>
          
          <Row className="px-2">
            <div className="form-check col form-radio-outline form-radio-primary mb-3">
              <input
                className="form-check-input"
                checked={searchCategory=="mobile"}
                type="radio"
                id="ongoing-order-search-mobile"
                onChange={() => setSearchCategory("mobile")}
              />
              <label
                className="form-check-label"
                htmlFor="ongoing-order-search-mobile"
              >
                Mobile
              </label>
            </div>

            <div className="form-check col form-radio-outline form-radio-primary mb-3">
              <input
                className="form-check-input"
                checked={searchCategory=="orderID"}
                type="radio"
                id="ongoing-order-search-orderid"
                onChange={() => setSearchCategory("orderID")}
              />
              <label
                className="form-check-label"
                htmlFor="ongoing-order-search-orderid"
              >
                Order ID
              </label>
            </div>

            <div className="form-check form-radio-outline col form-radio-primary mb-3">
              <input
                className="form-check-input"
                checked={searchCategory=="billNo"}
                type="radio"
                id="ongoing-order-search-billno"
                onChange={() => setSearchCategory("billNo")}
              />
              <label
                className="form-check-label"
                htmlFor="ongoing-order-search-billno"
              >
                Bill No
              </label>
            </div>

            <div className="form-check form-radio-outline col form-radio-primary mb-3">
              <input
                className="form-check-input"
                checked={searchCategory=="date"}
                type="radio"
                id="ongoing-order-search-date"
                onChange={() => setSearchCategory("date")}
              />
              <label
                className="form-check-label"
                htmlFor="ongoing-order-search-date"
              >
                Invoice Date
              </label>

            </div>

            <div className="form-check form-radio-outline col form-radio-primary mb-3">
              <input
                className="form-check-input"
                checked={searchCategory=="bagNo"}
                type="radio"
                id="ongoing-order-search-bagno"
                onChange={() => setSearchCategory("bagNo")}
              />
              <label
                className="form-check-label"
                htmlFor="ongoing-order-search-bagno"
              >
                Bag No
              </label>
            </div>
            <div className="form-check form-radio-outline col form-radio-primary mb-3">
              <input
                className="form-check-input"
                checked={searchCategory=="customerID"}
                type="radio"
                id="ongoing-order-search-customerid"
                onChange={() => setSearchCategory("customerID")}
              />
              <label
                className="form-check-label"
                htmlFor="ongoing-order-search-customerid"
              >
                Customer ID
              </label>
            </div>
          </Row>
          <Row className="mb-2">
            <Col md={6}>
            <label
                className="form-check-label"
                htmlFor="ongoing-order-search-customerid"
                
              >
                {searchLabel}
              </label>
            
            <Input
                type={searchCategory=="date"?"date":"text"}
                placeholder={searchPlaceholder}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />  
              {searchError?<label className="text-danger">{searchError}</label>:null}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-success save-user"
                  onClick={() => _setFilterType("search")}
                >
                  <i className="bx bx-search"></i> Search
                </button>
              </div>
            </Col>
          </Row>
          
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

OngoingOrders.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(OngoingOrders)
