import { GET_PART_CHANGES } from "./actionTypes";

export const apiSuccess = (data, actionType) => ({
  type: actionType,
  payload: data
})

export const apiFail = (data, actionType) => ({
  type: actionType,
  payload: data
})

export const getPartChanges = (data) => ({
  type: GET_PART_CHANGES,
  payload: data
})