import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Nav, NavItem, NavLink } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import classnames from "classnames"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import { useSelector, useDispatch } from "react-redux"
import { getLaundrys } from "../../store/laundry/actions"
import { getArealist, getB2bCustomer, searchUsers } from "../../store/actions"
import Pages404 from "pages/Utility/pages-404"
import ReactSwitch from "react-switch"
import { Offsymbol, OnSymbol } from "components/Common/Symbol"

const PriceListNew = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { areaList, laundryList, customersList, users } = useSelector(
    state => ({
      areaList: state.ecommerce.arealist,
      customersList: state.ecommerce.b2bCustomers,
      laundryList: state.Laundry.LaundryList,
      users: state.Users.users,
    })
  )

  const [areas, SetAreas] = useState([])
  const [customers, SetCustomers] = useState([])
  const [laundries, SetLaundries] = useState([])

  const [activeTab1, setactiveTab1] = useState("laundry")
  const [userData, setUserData] = useState({})
  const [fetchStatusTrue, setFetchStatusTrue] = useState(true)
  const [fetchStatusFalse, setFetchStatusFalse] = useState(true)

  useEffect(() => {
   if(!users) dispatch(searchUsers({ webPermissions: true }))
  }, [])

  useEffect(() => {
    let requestBody = {}
    if (fetchStatusTrue && fetchStatusFalse) {
      requestBody.status = {$in: [true, false]}
    } else if (fetchStatusTrue && !fetchStatusFalse) {
      requestBody.status = true
    } else if (fetchStatusFalse && !fetchStatusTrue) {
      requestBody.status = false
    }

    dispatch(getLaundrys(requestBody))
    dispatch(getArealist(requestBody))
    dispatch(getB2bCustomer({ ...requestBody, panel: true }))
  }, [fetchStatusTrue, fetchStatusFalse])

  const handleEditPrice = row => {
    history.push("/update-price-list", { priceType: activeTab1, data: row })
  }

  useEffect(() => {
    SetAreas(
      areaList.map(item => {
        return { label: item.name, value: item._id, status: item.status }
      })
    )
  }, [areaList])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0])
    }
  }, [users])

  useEffect(() => {
    SetCustomers(
      customersList.map(item => {
        return { label: item.nameEn, value: item._id, ...item }
      })
    )
  }, [customersList])

  useEffect(() => {
    SetLaundries(
      laundryList.map(item => {
        const label = item.shortName || item.nameEn
        return { label, value: item._id, ...item }
      })
    )
  }, [laundryList])

  const columns = [
    {
      dataField: "label",
      classes: "reason-wrap",
      text: "Name",
      sort: false,
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      formatter: (cellContent, row) => {
        console.log("row:: ", row)
        return (
          <div>
            {row.status ? (
              <span className="badge bg-success p-2">Active</span>
            ) : (
              <span className="badge bg-danger p-2">Inactive</span>
            )}
          </div>
        )
      },
    },
    {
      dataField: "",
      text: "Action",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <button
            className="btn btn-primary btn-sm"
            onClick={() => handleEditPrice(row)}
          >
            View
          </button>
        )
      },
    },
  ]

  const defaultSorted = [
    {
      dataField: "salary",
      order: "asc",
    },
  ]

  if (
    userData?.permissions?.allPermissions == true ||
    userData?.permissions?.pricelist?.read == true
  ) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Price List / Kleen admin</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Price List" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-2 border-bottom2121 d-flex mb-2 justify-content-between">
                      <Col lg="3">
                        <Nav pills className="navtab-bg nav-justified">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames("border", {
                                active: activeTab1 === "laundry",
                              })}
                              onClick={() => {
                                setactiveTab1("laundry")
                              }}
                            >
                              Laundry
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames("border", {
                                active: activeTab1 === "area",
                              })}
                              onClick={() => {
                                setactiveTab1("area")
                              }}
                            >
                              Area
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames("border", {
                                active: activeTab1 === "b2bCustomer",
                              })}
                              onClick={() => {
                                setactiveTab1("b2bCustomer")
                              }}
                            >
                              B2B
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                      <Col className="d-flex" lg="5">
                        <div className="d-flex align-items-center mx-3">
                          <span>Show Enabled</span>
                          <ReactSwitch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            onColor="#626ed4"
                            onChange={() =>
                              setFetchStatusTrue(!fetchStatusTrue)
                            }
                            checked={fetchStatusTrue}
                            className="mx-2"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <span>Show disabled</span>
                          <ReactSwitch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            onColor="#626ed4"
                            onChange={() =>
                              setFetchStatusFalse(!fetchStatusFalse)
                            }
                            checked={fetchStatusFalse}
                            className="mx-2"
                          />
                        </div>
                      </Col>
                    </Row>

                    {!fetchStatusTrue && !fetchStatusFalse ? null : (
                      <div>
                        {activeTab1 == "area" ? (
                          <PaginationProvider
                            pagination={paginationFactory({
                              sizePerPage: 10,
                              totalSize: areas.length, // replace later with size(customers),
                              custom: true,
                            })}
                            keyField="id"
                            columns={columns}
                            data={areas}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={columns}
                                data={areas}
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            // selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        ) : null}

                        {activeTab1 == "b2bCustomer" ? (
                          <PaginationProvider
                            pagination={paginationFactory({
                              sizePerPage: 10,
                              totalSize: customers.length, // replace later with size(customers),
                              custom: true,
                            })}
                            keyField="id"
                            columns={columns}
                            data={customers}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={columns}
                                data={customers}
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            // selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        ) : null}

                        {activeTab1 == "laundry" ? (
                          <PaginationProvider
                            pagination={paginationFactory({
                              sizePerPage: 10,
                              totalSize: laundries.length, // replace later with size(customers),
                              custom: true,
                            })}
                            keyField="id"
                            columns={columns}
                            data={laundries}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={columns}
                                data={laundries}
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            // selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        ) : null}
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  } else {
    return <Pages404 />
  }
}

export default PriceListNew
