import { call, put, takeEvery, all, fork } from "redux-saga/effects"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

// Crypto Redux States

//Include Both Helper File with needed methods
import {
  UPDATE_CUSTOMERS_BAG,
  REFUND_AMOUNT,
  REFUND_SUCCESS,
  REFUND_FAIL,
  GET_REASONS,
  GET_REASON_SUCCESS,
  GET_REASON_FAIL,
  GET_ONGOING_ORDERS,
  GET_HISTORY_ORDERS,
  GET_ALL_ORDERS,
  GET_ALL_ORDERS_SEARCH,
  UPDATE_ORDERS_INVOICE,
  API_SUCCESS,
  UPDATE_CUSTOMERS_BAG_SUCCESS,
  UPDATE_ORDER_LIST,
  UPDATE_ORDER_PAY_WITH_CASH,
  SET_ACTIVE_TAB,
  SEARCH_ORDERS,
  GET_ORDER_DETAILS,
  GET_REFUND_ORDERS,
  GET_ALL_ORDERS_PAGINATED,
  GET_FILTER_BYSEARCH,
  REMOVE_BAG_FEE,
  GET_CANCELLED_ORDERS,
  GET_COMPLETED_ORDERS,
  GET_RETURNED_ORDERS,
  GET_WHATSAPP_ORDERS,
  GET_ORDER_BY_ID,

} from "./actionTypes"

import { apiSuccess, apiFail } from "./actions"

//Include Both Helper File with needed methods
import {
  fetchOngoingOrders,
  fetchOrderHistory,
  fetchAllOrders,
  fetchAllOrdersSearch,
  updateOrderInvoice,
  updateCustomersBag,
  _updateOrderPayWithCash,
  fetchFilteredOrders,
  fetchOrdersearch,
  fetchRefundOrders,
  fetchFilterBySearch,
  _removeBagFee,
  fetchCancelledOrders,
  fetchCompletedOrders,
  fetchReturnedOrders,
  fetchWhatsappOrders,
  fetchOrderByID
} from "../../helpers/orderHelper"

import { searchReason, refundOrder } from "../../helpers/fakebackend_helper"

function* getOngoingOrders({ payload: data }) {
  try {
    const response = yield call(fetchOngoingOrders, data)
    yield put(apiSuccess(GET_ONGOING_ORDERS, response.record))
  } catch (error) {
    yield put(apiFail(GET_ONGOING_ORDERS, error))
  }
}

function* getFilterBySearch({ payload : data}) {
  try {
    const response = yield call(fetchFilterBySearch, data);
    yield put(apiSuccess(GET_FILTER_BYSEARCH, response.record));
  } catch (error) {
    yield put(apiFail(GET_FILTER_BYSEARCH, error));
  }
}



function* getReasons({ payload: record }) {
  try {
    const response = yield call(searchReason, record)
    yield put(apiSuccess(GET_REASON_SUCCESS, response.record))
  } catch (error) {
    yield put(apiFail(GET_REASON_FAIL, error))
  }
}

function* refundAmount({ payload: record }) {
  try {
    const response = yield call(refundOrder, record)
    if (response?.statusCode == "200") {
      toastr.success(`Refund Success`, `Your refund is successfully`)
      yield put(apiSuccess(REFUND_SUCCESS, record))
    } else {
      yield put(apiFail(REFUND_FAIL, response))
      toastr.error(response.msg, `Refund Fail`)
    }
  } catch (error) {
    yield put(apiFail(REFUND_FAIL, error))
    toastr.error(`Refund Fail`, `Your refund is Failed`)
  }
}

function* getAllOrders({}) {
  try {
    const response = yield call(fetchAllOrders)
    yield put(apiSuccess(GET_ALL_ORDERS, response.record))
  } catch (error) {
    yield put(apiFail(GET_ALL_ORDERS, error))
  }
}

function* searchOrders({ payload: data }) {
  try {
    const response = yield call(fetchFilteredOrders, data)
    yield put(apiSuccess(SEARCH_ORDERS, response.record))
  } catch (error) {
    yield put(apiFail(SEARCH_ORDERS, error))
  }
}

function* updateOrdersInvoice({ payload: record }) {
  try {
    const response = yield call(updateOrderInvoice, record)
    toastr.success(`Order Updated`)
    yield put(apiSuccess(UPDATE_ORDERS_INVOICE, response.record))
  } catch (error) {
    yield put(apiFail(UPDATE_ORDERS_INVOICE, error))
  }
}

function* updateOrderPayWithCash({ payload: record }) {
  try {
    const response = yield call(_updateOrderPayWithCash, record)
    if (response?.statusCode == "200") {
      toastr.success(`Order Pay With Cash`, `Order Pay With Cash Successfully`)
      yield put(apiSuccess(UPDATE_ORDER_PAY_WITH_CASH, record.order_id))
    } else {
      toastr.error(`Order Pay With Cash`, `Order Pay With Cash Failed`)
      yield put(apiFail(UPDATE_ORDER_PAY_WITH_CASH, response))
    }
  } catch (error) {
    yield put(apiFail(UPDATE_ORDER_PAY_WITH_CASH, error))
  }
}

function* getAllOrdersSearch({ payload }) {
  try {
    const response = yield call(fetchAllOrdersSearch, payload)
    yield put(apiSuccess(GET_ALL_ORDERS_SEARCH, response))
  } catch (error) {
    yield put(apiFail(GET_ALL_ORDERS_SEARCH, error))
  }
}

function* getAllOrdersPaginated({ payload }) {
  try {
    const response = yield call(fetchAllOrdersSearch, payload)
    yield put(apiSuccess(GET_ALL_ORDERS_PAGINATED, response))
  } catch (error) {
    yield put(apiFail(GET_ALL_ORDERS_PAGINATED, error))
  }
}

function* getCompletedOrders({ payload }) {
  try {
    const response = yield call(fetchCompletedOrders, payload)
    yield put(apiSuccess(GET_COMPLETED_ORDERS, response))
  } catch (error) {
    yield put(apiFail(GET_COMPLETED_ORDERS, error))
  }
}

function* getReturnedOrders({ payload }) {
  try {
    const response = yield call(fetchReturnedOrders, payload)
    yield put(apiSuccess(GET_RETURNED_ORDERS, response))
  } catch (error) {
    yield put(apiFail(GET_RETURNED_ORDERS, error))
  }
}

function* getWhatsappOrders({ payload }) {
  try {
    const response = yield call(fetchWhatsappOrders, payload)
    yield put(apiSuccess(GET_WHATSAPP_ORDERS, response))
  } catch (error) {
    yield put(apiFail(GET_WHATSAPP_ORDERS, error))
  }
}


function* updateAllOrdersList({ payload }) {
  try {
    console.log("updateAllOrdersList::", payload)
    yield put(apiSuccess(UPDATE_ORDER_LIST, payload))
  } catch (error) {
    console.log("ERROR::", error)
    yield put(apiFail(UPDATE_ORDER_LIST, error))
  }
}

function* setOrdersActiveTab({ payload }) {
  try {
    yield put(apiSuccess(SET_ACTIVE_TAB, payload))
  } catch (error) {
    console.log("ERROR::", error)
    yield put(apiFail(SET_ACTIVE_TAB, error))
  }
}

function* getHistoryOrders() {
  try {
    const response = yield call(fetchOrderHistory)
    yield put(apiSuccess(GET_HISTORY_ORDERS, response.orders))
  } catch (error) {
    yield put(apiFail(GET_HISTORY_ORDERS, error))
  }
}

function* getOrderDetails({ payload }) {
  try {
    const response = yield call(fetchOrdersearch, payload)
    yield put(apiSuccess(GET_ORDER_DETAILS, response.record))
  } catch (error) {
    yield put(apiFail(GET_ORDER_DETAILS, error))
  }
}

function* getRefundOrders({ payload }) {
  try {
    const response = yield call(fetchRefundOrders, payload)
    yield put(apiSuccess(GET_REFUND_ORDERS, response))
  } catch (error) {
    yield put(apiFail(GET_REFUND_ORDERS, error))
  }
}

function* onUpdateCustomerBag({ payload: data }) {
  try {
    const response = yield call(updateCustomersBag, data)
    if (response.statusCode == "200") {
      toastr.success(`Customer data Updated`, `Customer`)
      yield put(apiSuccess(UPDATE_CUSTOMERS_BAG_SUCCESS, data))
    } else {
      toastr.error(`Customer data Update failed`, `Customer`)
      yield put(apiFail(UPDATE_CUSTOMERS_BAG, response.error))
    }
  } catch (error) {
    toastr.error(`Customer data Update failed`, `Customer`)
    yield put(apiFail(UPDATE_CUSTOMERS_BAG, error))
  }
}

function* removeBagFee({ payload: record }) {
  try {
    const response = yield call(_removeBagFee, record)
    if (response?.statusCode == "200") {
      toastr.success(`Removed Bag Fee`)
    } else {
      toastr.error(`Request Failed`)
    }
  } catch (error) {
    console.log("ERROR::", error)
  }
}

function* getCancelledOrders({ payload }) {
  try {
    const response = yield call(fetchCancelledOrders, payload)
    yield put(apiSuccess(GET_CANCELLED_ORDERS, response))
  } catch (error) {
    yield put(apiFail(GET_CANCELLED_ORDERS, error))
  }
}

function* getOrderByID({ payload }) {
  try {
    const response = yield call(fetchOrderByID, payload)
    yield put(apiSuccess(GET_ORDER_BY_ID, response))
  } catch (error) {
    yield put(apiFail(GET_ORDER_BY_ID, error))
  }
}

function* ordersSaga() {
  yield takeEvery(GET_REASONS, getReasons)
  yield takeEvery(REFUND_AMOUNT, refundAmount)
  yield takeEvery(GET_ONGOING_ORDERS, getOngoingOrders)
  yield takeEvery(GET_HISTORY_ORDERS, getHistoryOrders)
  yield takeEvery(GET_ALL_ORDERS, getAllOrders)
  yield takeEvery(GET_ALL_ORDERS_SEARCH, getAllOrdersSearch)
  yield takeEvery(UPDATE_ORDER_LIST, updateAllOrdersList)
  yield takeEvery(UPDATE_ORDERS_INVOICE, updateOrdersInvoice)
  yield takeEvery(UPDATE_ORDER_PAY_WITH_CASH, updateOrderPayWithCash)
  yield takeEvery(SET_ACTIVE_TAB, setOrdersActiveTab)
  yield takeEvery(UPDATE_CUSTOMERS_BAG, onUpdateCustomerBag)
  yield takeEvery(SEARCH_ORDERS, searchOrders)
  yield takeEvery(GET_ORDER_DETAILS, getOrderDetails)
  yield takeEvery(GET_ALL_ORDERS_PAGINATED, getAllOrdersPaginated)
  yield takeEvery(GET_REFUND_ORDERS, getRefundOrders)
  yield takeEvery(GET_FILTER_BYSEARCH, getFilterBySearch)
  yield takeEvery(REMOVE_BAG_FEE, removeBagFee)
  yield takeEvery(GET_CANCELLED_ORDERS, getCancelledOrders)
  yield takeEvery(GET_COMPLETED_ORDERS, getCompletedOrders)
  yield takeEvery(GET_RETURNED_ORDERS, getReturnedOrders)
  yield takeEvery(GET_WHATSAPP_ORDERS, getWhatsappOrders)  
  yield takeEvery(GET_ORDER_BY_ID, getOrderByID)
}

export default ordersSaga
