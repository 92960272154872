import { GET_ACCIDENT_REPORTS } from "./actionTypes";

export const apiSuccess = (data, actionType) => ({
  type: actionType,
  payload: data
})

export const apiFail = (data, actionType) => ({
  type: actionType,
  payload: data
})

export const getAccidentReports = (data) => ({
  type: GET_ACCIDENT_REPORTS,
  payload: data
})