import 
{ 
  API_SUCCESS, 
  API_FAIL,
  GET_CUSTOMERS_LOCATION,
  GET_CUSTOMERS_LIST, 
  GET_CUSTOMERS_OTPLIST,
  GET_OTP,
  GET_CUSTOMER_DETAILS,
  GET_CUSTOMER_DETAILS_UI,
  SEARCH_CUSTOMER,
  CLEAR_CUSTOMER_DATA
} from "./actionTypes"

const INIT_STATE = {
  location: {},
  customers : {
    record : [],
    totalCustomers: 0
  },
  otp:"",
  customerDetails: {},
  customerDetailsUI: [],
  searchedCustomer: {}
}

const Customers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CUSTOMERS_LOCATION:
          return {
            ...state,
            location: action.payload.data.record 
          };
        case GET_CUSTOMERS_OTPLIST:
          return {
            ...state,
            customers: action.payload.data
          };
        case GET_CUSTOMERS_LIST: 
          return {
            ...state,
            customers: action.payload.data
          }
        case GET_OTP:
          return {
            ...state,
            otp: action.payload.data.data
          }

          case GET_CUSTOMER_DETAILS:
            return {
              ...state,
              customerDetails: action.payload.data
            };

           case GET_CUSTOMER_DETAILS_UI:
            return {
              ...state,
              customerDetailsUI: action.payload.data
            }; 

            case SEARCH_CUSTOMER:
              return {
                ...state,
                searchedCustomer: action.payload.data.record?.[0] || {}
              };


        default:
          return state;
      }
    case CLEAR_CUSTOMER_DATA:
      return {
        ...state,
        customerDetailsUI: [],
        searchedCustomer: {},
      };

    case API_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Customers
