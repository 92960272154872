import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ImagesModal = ({ images = [], title = "Image Gallery" }) => {
  const [imageModal, setImageModal] = useState(false);
  const [galleryImages, setGalleryImages] = useState(images);
  const [selectedImage, setSelectedImage] = useState(images[0] || null);

  useEffect(() => {
    setGalleryImages(images); 
    setSelectedImage(images[0] || null); 
  }, [images]);

  const toggleImageModal = () => {
    setImageModal(!imageModal);
  };

  return (
    <div>
      <Button
        color="success"
        outline
        size="md"
        onClick={toggleImageModal}
      >
        View Images
      </Button>

      <Modal
        isOpen={imageModal}
        toggle={toggleImageModal}
        size="lg"
      >
        <ModalHeader toggle={toggleImageModal}>
          {title}
        </ModalHeader>
        <ModalBody>
          {selectedImage && (
            <div className="mb-4">
              <img
                src={selectedImage}
                alt="Selected"
                className="w-100 rounded"
                style={{ maxHeight: "400px", objectFit: "contain" }}
              />
            </div>
          )}
          <div className="d-flex gap-2 flex-wrap">
            {galleryImages.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`Gallery image ${index + 1}`}
                className={`thumbnail rounded border ${img === selectedImage ? "border-primary" : ""}`}
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                onClick={() => setSelectedImage(img)}
              />
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleImageModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

ImagesModal.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

ImagesModal.defaultProps = {
  images: [],
  title: "Image Gallery",
};

export default ImagesModal;
