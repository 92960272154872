/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_REASONS = "GET_REASONS";
export const REFUND_AMOUNT = "REFUND_AMOUNT";
export const REFUND_FAIL = "REFUND_FAIL";
export const REFUND_SUCCESS = "REFUND_SUCCESS";
export const REMOVE_BAG_FEE = "REMOVE_BAG_FEE";
export const GET_REASON_SUCCESS = "GET_REASON_SUCCESS";
export const GET_REASON_FAIL = "GET_REASON_FAIL";
export const GET_ONGOING_ORDERS = "GET_ONGOING_ORDERS";
export const GET_HISTORY_ORDERS = "GET_HISTORY_ORDERS";
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_ALL_ORDERS_SEARCH = "GET_ALL_ORDERS_SEARCH";
export const UPDATE_ORDERS_INVOICE = "UPDATE_ORDERS_INVOICE";
export const UPDATE_ORDER_LIST = "UPDATE_ORDER_LIST";
export const UPDATE_ORDER_PAY_WITH_CASH = "UPDATE_ORDER_PAY_WITH_CASH";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const UPDATE_CUSTOMERS_BAG = "UPDATE_CUSTOMERS_BAG";
export const UPDATE_CUSTOMERS_BAG_SUCCESS = "UPDATE_CUSTOMERS_BAG_SUCCESS";
export const ONGOING_ORDERS_LOADED = "ONGOING_ORDERS_LOADED";
export const SEARCH_ORDERS = "SEARCH_ORDERS";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const GET_REFUND_ORDERS = "GET_REFUND_ORDERS";
export const GET_ALL_ORDERS_PAGINATED = "GET_ALL_ORDERS_PAGINATED";
export const GET_FILTER_BYSEARCH = "GET_FILTER_BYSEARCH";
export const GET_CANCELLED_ORDERS = "GET_CANCELLED_ORDERS";
export const GET_COMPLETED_ORDERS = "GET_COMPLETED_ORDERS";
export const GET_RETURNED_ORDERS = "GET_RETURNED_ORDERS";
export const GET_WHATSAPP_ORDERS = "GET_WHATSAPP_ORDERS";
export const GET_ORDER_BY_ID = "GET_ORDER_BY_ID";