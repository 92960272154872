import { GET_VEHICLES_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  vehicles: [],
  totalVehicles: 0,  // for pagination
};

const vehicleList = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VEHICLES_SUCCESS:

      return {
        ...state,
        vehicles: action.payload.data,
        totalVehicles: action.payload.totalVehicles,
      };
    default:
      return state;
  }
};

export default vehicleList;
