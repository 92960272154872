import { GET_LAUNDRY_WORKERS } from "./actionTypes";

export const apiSuccess = (data, actionType) => ({
  type: actionType,
  payload: data
})

export const apiFail = (data, actionType) => ({
  type: actionType,
  payload: data
})

export const getLaundryWorkers = (data) => ({
  type: GET_LAUNDRY_WORKERS,
  payload: data
})