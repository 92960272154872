import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Input, Button, CardText } from "reactstrap";
import MetaTags from 'react-meta-tags';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import toastr from "toastr";
import { getOrderByID } from "store/actions"; // Assuming these actions are defined
import "toastr/build/toastr.min.css";
import { postWeb } from "helpers/api_helper";

const CancelOrder = () => {
  const dispatch = useDispatch();
  const { orderByID } = useSelector(state => ({
    orderByID: state.Orders.orderByID,
  }));

  const initailStateOfErrors = {
    orderID: "",
    reason: "",
    approvedBy: "",
    notFound: "",
    failed: "",
  }
  const [orderData, setOrderData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [orderID, setOrderID] = useState("");
  const [reason, setReason] = useState("");
  const [error, setError] = useState(initailStateOfErrors);
  const [approvedBy, setApprovedBy] = useState("");

  useEffect(() => {
    const _orderByID = orderByID?.record;
    if (_orderByID) {
      setOrderData(_orderByID[0]);
      setError(initailStateOfErrors);
    }
  }, [orderByID]);
//if order not found show toastr error
  useEffect(() => {
    if (orderByID?.record?.length == 0 || orderByID?.record == []) {
      setError({ ...error, orderID: "Order not found." });
    }
    setApprovedBy("");
    setReason("");
  }, [orderByID]);

  useEffect(() => {
    if (orderID) {
      setOrderData({});
      setError({...error , failed:false})
    }
  }, [orderID]);

  const handleSearch = async () => {
    setError(initailStateOfErrors);
    if (orderID) {
      setIsLoading(true);
      dispatch(getOrderByID({ orderID }));
      setIsLoading(false);
    } else {
      setError({ ...error, orderID: "Please enter a valid order ID" });
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    // Remove any non-digit characters
    value = value.replace(/[^\d]/g, '');
    value = value.replace(/[^\d]/g, ''); // Remove non-digit characters
    if (value === '0') {
      value = ''; // or set it to some default value if needed
    }

    setOrderID(value);
  };

  const handleReasonChange = (e) => {
    setError({ ...error, reason: "" });
    setReason(e.target.value);
  };

  const handleApprovedBy = (e) => {
    setError({ ...error, approvedBy: "" });
    setApprovedBy(e.target.value);
  };

  const handleCancel = async () => {
    if (reason === "") {
      setError({ ...error, reason: "Please enter a reason for cancellation." });
      return;
    }

    if (approvedBy === "") {
      setError({ ...error, approvedBy: "Please enter the name of the person who approved the cancellation." });
      return;
    }

    setIsLoading(true);
    try {
          const response = await postWeb("orders/cancelOrder", { 
            _id: orderData._id, 
            reason, 
            approvedBy 
          });
          
      if (response.statusCode === "200") {
        toastr.success("Order cancelled successfully.");
        setOrderData({});
        setOrderID("");
        setReason("");
      } else {
        setError({ ...error, failed: response.msg || "Failed to cancel order." });
      }
    } catch (error) {
      setError({ ...error, failed: response.msg || "An unexpected error occurred. Please try again later." });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Order Management | Cancel Order</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Order Management" breadcrumbItem="Cancel Order" />
          
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col sm="12">
                      <div className="me-2 mb-2 d-inline-block">
                        <CardText className="mb-2 pt-2 fs-5 fw-bold">Order ID: </CardText>
                        <div className="input-group">
                          <Input
                            type="text"
                            placeholder="Search Order"
                            className="form-control"
                            value={orderID}
                            onChange={handleChange}
                            onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                            invalid={error.orderID ? true : false}
                          />
                          <Button
                            className="ms-2"
                            color="primary"
                            onClick={handleSearch}
                            disabled={isLoading}
                            >
                            Search
                          </Button>
                        </div>
                            {error.orderID && <div className="text-danger">{error.orderID}</div>}
                      </div>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col sm="12">
                    {orderData && orderID && orderData._id && (
                        <Row className="mt-4">
                          <Col sm="4">
                            <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                            <strong>Order ID:</strong> {orderData.orderID}
                            </CardText>
                          </Col>
                          <Col sm="6">
                            <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                            <strong>Status:</strong> {orderData.status}
                            </CardText>
                          </Col>
                          <Col sm="4">
                            <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                            <strong>Paid:</strong> {orderData.paid ? "Yes" : "No"}
                            </CardText>
                          </Col>
                          <Col sm="6">
                            <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                            <strong>Category:</strong> {orderData.category}
                            </CardText>
                          </Col>
                          <Col sm="4">
                            <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                            <strong>Grand Total:</strong> SAR {orderData.grandTotal}
                            </CardText>
                          </Col>
                          <Col sm="6">
                            <CardText className="mb-2 pt-2 fs-5 d-flex gap-2 align-items-center " style={{ fontSize: '1.25rem' }}>
                            <strong>Approved By: </strong> 
                            <Input
                              type="text"
                              placeholder="Approved By"
                              className="form-control w-50"
                              value={approvedBy}
                              onChange={handleApprovedBy}
                              disabled = {isLoading}
                              invalid={error.approvedBy ? true : false}
                            />
                            </CardText>
                            {error.approvedBy && <div className="text-danger">{error.approvedBy}</div>}
                          </Col>
                      <Row className="mt-3">
                        <Col sm="4">
                          <CardText className="mb-2 pt-2 fs-5 fw-bold">Reason for Cancellation: </CardText>
                          <Input
                            type="textarea"
                            style={{resize: "none"}}
                            placeholder="Enter reason for cancellation"
                            className="form-control"
                            value={reason}
                            onChange={handleReasonChange}
                            invalid={error.reason ? true : false}
                          />
                          {error.reason && <div className="text-danger">{error.reason}</div>}
                        </Col>
                      </Row>
                        </Row>
                      )}
                        <Col sm="6">
                          {orderData && orderData._id && orderID != "" && <Button
                            className="mt-4"
                            color="danger"
                            onClick={handleCancel}
                            disabled={isLoading}
                          >
                            Cancel Order
                          </Button>}
                        </Col>
                        {error.failed && <div className="text-danger fs-4 mt-2">{error.failed}</div>}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CancelOrder;
