import axios from "axios"
import { del, get, postWeb, put } from "./api_helper"
import * as url from "./url_helper"

const fetchCustomersLocation = (data) => postWeb(`${url.GET_CUSTOMERS_LOCATION}`, data)
const fetchCustomersList = (data) => postWeb(`${url.SEARCH_ALL_CUSTOMERS}`, data)
const fetchCustomersOTPList = (data) => postWeb(`${url.OTPLIST_CUSTOMERS}`, data)
const fetchOTP = (data) => postWeb(`${url.GET_OTP}`, data)
const fetchCustomers = (data) => postWeb(`${url.GET_USER_ANALYTICS}`, {...data, isAdmin: true})
const fetchCustomerDetails = (data) => postWeb(`${url.GET_CUSTOMERS_DETAILS_UI}`, {...data, isAdmin: true})
const fetchCustomerSearch = (data) => postWeb(`${url.GET_CUSTOMERS}`, data)


export {
  fetchCustomersLocation,
  fetchCustomersList,
  fetchCustomersOTPList,
  fetchOTP,
  fetchCustomers,
  fetchCustomerDetails,
  fetchCustomerSearch
}