import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Button, Card, CardBody, Col, Container, Modal, Label, Row,
  ModalHeader, ModalFooter, ModalBody, Input } from "reactstrap"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { searchUsers, getArealist as onGetArealist } from "store/actions"
import PropTypes from "prop-types";
import "toastr/build/toastr.min.css";
import Pages404 from "pages/Utility/pages-404";
import { postWeb } from "helpers/api_helper";
import toastr from "toastr";

const AreaNotification = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const [selectedArea, setSelectedArea] = useState({});
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  // set error
  const [error, setError] = useState({});

  const { users, areas } = useSelector(state => ({
    users: state.Users.users,
    areas: state.ecommerce.arealist
  }))
  
  useEffect(() => {
    if(!users) dispatch(searchUsers({ webPermissions: true }))
      dispatch(onGetArealist())
    setSelectedArea(null)
  }, [])
  
  
  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])
  
  const areaGroup = useSelector(state => areas.map(area => {
    return { label: area?.name, value: area?._id }
  }))

  const handleAreaSelect = (area) => {
    setSelectedArea(area);
    setNotificationTitle("");
    setNotificationMessage("");
  };

  const handleSendNotification = () => {
    //if either Notification Title or Notification Message is empty, then show error
    setError({});

    // Check if either Notification Title or Notification Message is empty
    if (!notificationTitle) {
      setError(prev => ({ ...prev, noTitle: "Please Enter Notification Title." }));
      return;
    } else if (!notificationMessage) {
      setError(prev => ({ ...prev, noMessage: "Please Enter Notification  Message." }));
      return;
    }
    setShowConfirmation(true);
  };

  const confirmSendNotification = async () => {
    setIsLoading(true);
    try {
      await postWeb("/customers/sendAreaNotification", {
        areaId: selectedArea?.value,
        title: notificationTitle,
        message: notificationMessage
      });

      toastr.success("Notification Sent Successfully.", "Success");
      setNotificationTitle("");
      setNotificationMessage("");
      setSelectedArea(null);
    } catch (error) {
      setError(error);
      toastr.error("Error while sending notification!", "Error");
    } finally {
      setIsLoading(false);
      setShowConfirmation(false);
    }
  };

  if (userData?.permissions?.allPermissions == true) {
    return (
      <React.Fragment>
        <div className="page-content" style={{ minHeight: "600px" }}>
          <MetaTags>
            <title>Send Notification</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Home" breadcrumbItem="Send Notifications" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="3">
                        <div className="mb-4">
                          <Label className="fs-4">Select Area</Label>
                          <Select
                            value={selectedArea}
                            onChange={handleAreaSelect}
                            placeholder="Select Area"
                            isClearable={true}
                            invalid={error?.noArea}
                            options={areaGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>
                    {selectedArea && (
                      <>
                      <Row>
                        <Col lg="4">
                          <div className="mb-3 mt-2">
                            <Label className="fs-5">Notifications Title</Label>
                            <Input
                              type="text"
                              value={notificationTitle}
                              onChange={(e) => setNotificationTitle(e.target.value)}
                              placeholder="Enter Title."
                              className="form-control"
                              invalid={error?.noTitle}
                            />
                          </div>
                          {error?.noTitle && <p className="text-danger">{error.noTitle}</p>}
                        </Col>
                        </Row>
                        <Row>
                        <Col lg="4">
                          <div className="mb-3">
                            <Label className="fs-5">Notifications Message</Label>
                            <Input
                              type="textarea"
                              style={{resize: "none", height: "35px"}}
                              value={notificationMessage}
                              onChange={(e) => setNotificationMessage(e.target.value)}
                              placeholder="Enter Message."
                              className="form-control"
                              invalid={error?.noMessage}
                            />
                          </div>
                          {error?.noMessage && <p className="text-danger">{error.noMessage}</p>}
                        </Col>
                      </Row>
                      </>
                    )}
                    {selectedArea && (
                      <Row>
                        <Col lg="12">
                          <Button color="primary" onClick={handleSendNotification}>
                            Send Notifications
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

          {/* Confirmation Modal */}
          <Modal isOpen={showConfirmation} toggle={() => setShowConfirmation(false)}>
            <ModalHeader toggle={() => setShowConfirmation(false)}>Confirm Notification</ModalHeader>
            <ModalBody>
              Are you sure you want to send the following notification to customers in <span className="fw-bold">{selectedArea?.label || "Area Not Available !!"}</span>?
              <div>
                {/* Title and message */}
                <div className="mt-3">
                  <div className="fw-bold">Title:</div>
                    <div>{notificationTitle}</div>
                  <div className="fw-bold mt-2">Message:</div>
                    <div>{notificationMessage}</div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setShowConfirmation(false)}>Cancel</Button>
              <Button color="primary" disabled={isLoading} onClick={confirmSendNotification}>Yes, Send</Button>
            </ModalFooter>
          </Modal>
        </div>
      </React.Fragment>
    )
  } else {
    return <Pages404 />
  }

}

AreaNotification.propTypes = {
  users: PropTypes.array,
  allPermissions: PropTypes.bool,
  areas: PropTypes.array,
}

export default AreaNotification   
