import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Form, FormGroup, Label, Alert, Progress } from "reactstrap";
import toastr from "toastr";
import PropTypes from "prop-types";
import { post } from "helpers/api_helper";
import { useDispatch } from "react-redux";
import { getPricelist } from "store/actions";

const ImportPricesModal = ({ isOpen, toggle, clothes, apiData }) => {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch();

  const downloadSample = () => {
    const sampleData = clothes
      .map((cloth) => `${cloth._id},${cloth.nameEn},,,`)
      .join("\n");

    const header = `_id,Item Name,Iron Price,Wash & Iron Price,Dry Clean Price,Express Iron Price,Express Wash & Iron Price,Express Dry Clean Price\n`;
    const blob = new Blob([header + sampleData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "sample_prices.csv";
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setErrorMessage(null);
  };

  const handleUpload = async () => {
    if (!file) {
      setErrorMessage("Please select a file to upload.");
      return;
    }

    if (!apiData) {
      return toastr.error("Pricelist import failed");
    }

    setIsProcessing(true);
    const formData = new FormData();
    formData.append("file", file);
    Object.entries(apiData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const response = await post("/pricelist/importPriceList", formData, true);

      if (response.statusCode == 200) {
        toastr.success("Pricelist imported successfully");
        dispatch(getPricelist(apiData));
        clearState()
        toggle();
      } else {
        toastr.error("Pricelist import failed");
      }
    } catch (error) {
      console.log("ERRRR", error);
      setErrorMessage(error.response?.data?.msg || "Failed to import prices.");
    } finally {
      setIsProcessing(false);
    }
  };

  const clearState = () => {
    setErrorMessage(null);
    setFile(null);
  }

  useEffect(() => {
    if(!isOpen){
      clearState()
    }
  },[isOpen])
  return (
    <Modal isOpen={isOpen} toggle={!isProcessing ? toggle : () => { }}>
      <ModalHeader toggle={!isProcessing ? toggle : () => { }}>Import Prices</ModalHeader>
      <ModalBody>
        {isProcessing ? (
          <div className="d-flex justify-content-center align-items-center">
            <Progress animated color="primary" value={100} style={{ width: '100%' }} />
          </div>
        ) : (
          <>
            <Button color="primary" onClick={downloadSample} className="mb-3">
              Download Sample CSV
            </Button>
            <Form>
              <FormGroup>
                <Label for="fileUpload" className="d-block mb-2">Upload CSV File</Label>
                <Button
                  color="secondary"
                  className="d-block mb-3"
                  onClick={() => document.getElementById("fileUpload").click()}
                >
                  Choose File
                </Button>
                <Input
                  type="file"
                  id="fileUpload"
                  accept=".csv"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                {file && <div className="mt-2">Selected File: {file.name}</div>}
              </FormGroup>
              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            </Form>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={handleUpload} disabled={isProcessing || !file}>
          {isProcessing ? "Uploading..." : "Upload"}
        </Button>
        <Button disabled={isProcessing} color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ImportPricesModal.propTypes = {
  apiData: PropTypes.apiData,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  clothes: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      nameEn: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ImportPricesModal;
