/*  RECORDS */
export const API_FAIL = "API_FAIL"
export const API_SUCCESS = "API_SUCCESS"
export const GET_CUSTOMERS_LOCATION = "GET_CUSTOMERS_LOCATION"
export const GET_CUSTOMERS_LIST = "GET_CUSTOMERS_LIST"
export const GET_CUSTOMERS_OTPLIST = "GET_CUSTOMERS_OTPLIST"
export const GET_OTP = "GET_OTP"
export const GET_CUSTOMER_DETAILS = "GET_CUSTOMER_DETAILS";
export const GET_CUSTOMER_DETAILS_UI = "GET_CUSTOMER_DETAILS_UI";
export const SEARCH_CUSTOMER = "SEARCH_CUSTOMER";
export const CLEAR_CUSTOMER_DATA = "CLEAR_CUSTOMER_DATA";
