import { 
  API_SUCCESS,
  API_FAIL,
  GET_CUSTOMERS_LOCATION,
  GET_CUSTOMERS_LIST,
  GET_CUSTOMERS_OTPLIST,
  GET_OTP,
  GET_CUSTOMER_DETAILS,
  GET_CUSTOMER_DETAILS_UI,
  SEARCH_CUSTOMER,
  CLEAR_CUSTOMER_DATA
} from "./actionTypes"

export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
});

export const getCustomersLocation = (data) => ({
  type: GET_CUSTOMERS_LOCATION,
  payload: data
})

export const getCustomersList = (data) => ({
  type: GET_CUSTOMERS_LIST,
  payload: data
})

export const getCustomerOTPList = (data) => ({
  type: GET_CUSTOMERS_OTPLIST,
  payload: data
})
export const getOTP = (data) => ({
  type: GET_OTP,
  payload: data
})

// individual customer dtails based on either customer id or customer mobile
export const getCustomerDetails = (data) => ({
  type: GET_CUSTOMER_DETAILS,
  payload: data
});

//For customer details UI
export const getCustomerDetailsUI = (data) => ({
  type: GET_CUSTOMER_DETAILS_UI,
  payload: data
});

//For customer details by id
export const searchCustomer = (data) => ({
  type: SEARCH_CUSTOMER,
  payload: data
});

// Clear customer data
export const clearCustomerData = () => ({
  type: CLEAR_CUSTOMER_DATA,
});