import { takeEvery, put, call } from "redux-saga/effects";
import { GET_PART_CHANGES, GET_PART_CHANGES_FAIL, GET_PART_CHANGES_SUCCESS } from "./actionTypes";
import { apiSuccess, apiFail } from './actions';
import { getPartChanges } from "helpers/fakebackend_helper"; // updated import name to avoid conflicts
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function* fetchPartChanges({ payload }) {
  try {
    const { page,
      pageSize,
      dateRange,
      vehicle,
      type,
      serviceProvider,
      web } = payload;
    const response = yield call(getPartChanges, {
      page,
      pageSize,
      dateRange,
      vehicle,
      type,
      serviceProvider,
      web
    });
    yield put(apiSuccess(response, GET_PART_CHANGES_SUCCESS));
    // Check if the response is successful, if so, dispatch the success action
    if (response.statusCode == 200) {
      yield put(apiSuccess(response, GET_PART_CHANGES_SUCCESS));
    } else {
      // Handle error response
      yield put(apiFail(response.message || 'Something went wrong', GET_PART_CHANGES_FAIL));
      toastr.error(response.message || 'Failed to part changes list');
    }
  } catch (error) {
    // Handle any unexpected errors
    console.log('error', error)
    yield put(apiFail(error.message, GET_PART_CHANGES_FAIL));
    toastr.error('An error occurred while part changes list');
  }
}

function* partChangesSaga() {
  yield takeEvery(GET_PART_CHANGES, fetchPartChanges);
}

export default partChangesSaga;
