import { Redirect } from "react-router-dom";
import React from "react";
import Records from "../pages/Records/Records";



// // Pages Component

//Codes or promocodes
import PromoCode from '../pages/PromoCode';

// Profile
import UserProfile from "../pages/Authentication/user-profile";

//create invoices pages
import CreateOrdersInvoice from "../pages/AllOrdersInvoices/CreateOrdersInvoice";

// role permission
import RolePermission from "../pages/RolePermission";
// module permission
import ModulePermission from "../pages/ModulePermission";

// Authentication related pages
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

//  // Inner Authentication

// Dashboard
import Analytics from "../pages/Dashboard/Analytics";
import AnalyticsHome from "../pages/Dashboard/AnalyticsHome";
import DailyReports from "../pages/Dashboard/DailyReports";

//Orders
import OngoingOrders from "../pages/Orders/index";

import CreateOnlineOrder from "pages/Orders/CreateOnlineOrder";
import CancelledOrdersList from "../pages/Orders/CancelOrdersList";
import CompletedOrdersList from "../pages/Orders/CompletedOrdersList";
import Refunds from "../pages/Orders/Refunds";

//Orders
import Users from "../pages/Users/index";

//laundry
import laundry from "../pages/Laundry/index";


//Sliders
import Sliders from "../pages/Sliders/index";

//Redeem Packages
import RedeemPackages from "../pages/LoyaltyPoints/RedeemPackages";

// all area polygon 
import MapsArea from "pages/Maps/MapsArea";

//Pages
import Pages404 from "../pages/Utility/pages-404";

//Contacts
import Clothes from "pages/Clothes";
import DriverList from "pages/Drivers";
//pricelist
import PriceList from "pages/PriceList/PriceListNew";
import PriceListUpdate from "pages/PriceList/PriceListUpdate";

import Annoucements from "pages/Annoucements";
import CarWashOrders from "pages/CarWashOrders";
import CarWashRedeem from "pages/CarWashOrders/CarWashRedeem";
import SubscriptionPackage from "pages/CarWashOrders/SubscriptionPackage";
import Customers from "pages/Customers";
import CustomerDetails from "pages/Customers/CustomerDetails";
import CustomersOTPList from "pages/Customers/customerOtplist";
import LogisticDashboard from "pages/Dashboard/LogisticDashboard";
import DriverLocations from "pages/Drivers/DriverLocations";
import holiday from "pages/Holiday";
import LaundryCoupon from "pages/LaundryCoupon";
import ReturnedOrders from "pages/Orders/ReturnedOrders";
import Reasons from "pages/ReasonsList";
import MonthlyReports from "pages/Reports/MonthlyReports";
import ServiceManagement from "pages/ServiceManagement/ServiceManagment";
import NotificationTexts from "pages/Settings/NotificationTexts";
import Settings from "pages/Settings/Settings";
import TimeSlots from "pages/TimeSlots/TimeSlots";
import b2bCustomer from "pages/b2bCustomer";
import b2bProducts from "pages/b2bProducts";
import FreshDeskAnalytics from "pages/Support-Tools/FreshDeskAnalytics";
import InvoiceLogs from "pages/Invoice-Log/InvoiceLogs";
import FreeMoneySupprt from "pages/Support-Tools/FreeMoneySupport";
import CancelOrder from "pages/Support-Tools/CancelOrder";
import ChangeNumber from "pages/Support-Tools/ChangeMobile";
import Vechicles from "pages/Master/Vechicles";
import LaundryWorkers from "pages/Master/LaundryWorkers";
import Vendors from "pages/Master/Vendors";
import AccidentReports from "pages/Logistics/AccidentReports";
import PartChanges from "pages/Logistics/PartChanges";
import AreaNotification from "pages/Marketing/AreaNotification";
import PosMaintanance from "pages/Operations/PosMaintanance";
import OrderDetails from "pages/Orders/OrderDetails";

var data = JSON.parse(localStorage.getItem("authUser"))

const authProtectedRoutes = [
  { path: "/record", component: Records },
  { path: "/promo-codes", component: PromoCode },
  // { path: "/dashboard", component: Dashboard }, Live data dashboard
  { path: "/sliders", component: Sliders },
  { path: "/analytics-charts", component: Analytics },
  { path: "/analytics", component: AnalyticsHome },
  { path: "/logistic-dashboard", component: LogisticDashboard },
  { path: "/daily-reports", component: DailyReports },

  //Orders
  {path: "/order-details", component: OrderDetails},

   { path: "/ongoing-orders", component: OngoingOrders },
   { path: "/returned-orders", component: ReturnedOrders },
  // { path: "/order-history", component: OrderHistory },
  // { path: "/all-orders", component: AllOrders },
  { path: "/completed-orders", component: CompletedOrdersList },
  { path: "/cancelled-orders", component: CancelledOrdersList },
  { path: "/refunds", component: Refunds },
  { path: "/create-order", component: CreateOnlineOrder },

  { path: "/time-slots", component: TimeSlots },
  { path: "/settings", component: Settings},
  { path: "/customers", component: Customers},
  { path: "/customers-otplist", component: CustomersOTPList},
  { path: "/customer-details", component: CustomerDetails},

    //Support Tools
    { path: "/freshdesk-analytics", component: FreshDeskAnalytics },
    { path: "/freeMoneySupport", component: FreeMoneySupprt },

  {path: "/cancel-order", component: CancelOrder},

  //Master
  { path: "/vehicle-master", component: Vechicles },
  { path: "/laundryWorker-master", component: LaundryWorkers },
  { path: "/vendor-master", component: Vendors },

  //Marketing menu.
  {path: "/area-notification", component: AreaNotification},

  //Users
  { path: "/users", component: Users },
  // { path: "/laundries", component: Laundries },
  { path: "/areas", component: MapsArea },

  //Driver Locations
  { path: "/driver-locations", component: DriverLocations },

  // Logistics
  { path: "/accident-reports", component: AccidentReports },
  { path: "/part-changes", component: PartChanges },

  //laundry
  { path: "/laundry", component: laundry },
  { path: "/clothes", component: Clothes },

  
  //laundry
  { path: "/redeem-packages", component: RedeemPackages },

  { path: "/car-wash-redeem", component: CarWashRedeem },
  { path: "/car-wash-orders", component: CarWashOrders },

  { path: "/subscription-packages", component: SubscriptionPackage },
 
  { path: "/notification-texts", component: NotificationTexts },


  //Annoucements 
  { path: "/announcements", component: Annoucements },


  //Reports
  { path: "/monthly-reports", component: MonthlyReports },

  //B2B Customer
  { path: "/b2blist", component: b2bCustomer },

  //B2B Products
  { path: "/b2bproducts", component: b2bProducts },

  { path: "/service-management", component: ServiceManagement },

  //Holidays
  { path: "/holidays", component: holiday },


  //{ path: "/SimpleMap", component: MapsGoogle },

  //PriceList
  { path: "/price-list", component: PriceList },
  { path: "/update-price-list", component: PriceListUpdate },

  //laundry Counpoun
  { path: "/laundry-coupons", component: LaundryCoupon },
  // //profile
  { path: "/profile", component: UserProfile },

  // Reasons List
  { path: "/reasons", component: Reasons },

  //Invoices
  // { path: "/invoices-list", component: InvoicesList },
  // { path: "/invoices-detail/:id?", component: InvoiceDetail },

  // //create- invoice
  { path: "/create-invoice/:id", component: CreateOrdersInvoice },

  // Role permission
  { path: "/role-permission", component: RolePermission },

  // Module permission
  { path: "/module-permission", component: ModulePermission },

  // Module permission
  // { path: "/data-reports", component: DataReports },

  { path: "/drivers", component: DriverList },

  { path: "/audit-logs", component: InvoiceLogs },
  { path: "/pos-maintanance", component: PosMaintanance },

  //Change customer mobile number:
  { path: "/change-number", component: ChangeNumber },


  // { path: "/all-area", component: AllAreaPolygon },
  // { path: "/edit-area", component: MapsArea },
  
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to={"/analytics"} /> },
  { path: "*", component: Pages404 },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-404", component: Pages404 },
  // { path: "*", component: Pages404 },
]





export { authProtectedRoutes, publicRoutes };

