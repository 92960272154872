import { postWeb } from 'helpers/api_helper';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import toastr from 'toastr';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { getVehicles } from 'store/actions';
import PagesNoPermission from 'pages/Utility/pages-no-permission';

const Vehicles = (props) => {
  const { users ,totalVehiclesCount,vehicleList} = useSelector(state => ({
    users: state.Users.users,
    vehicleList: state.Vehicles.vehicles,
    totalVehiclesCount: state.Vehicles.totalVehicles
  }))
  const initalValueForStatusFilters = false
  const [userData, setUserData] = useState({});
  const [modal, setModal] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [form, setForm] = useState({
    type: '',
    model: '',
    number: '',
    status: true
  });
  const [editIndex, setEditIndex] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteModal, setDeleteModal] = useState(false);
  const [vehicleToDelete, setVehicleToDelete] = useState(null);
  const [totalVehicles, setTotalVehicles] = useState(0)
  const [statusDisabled, setStatusDisabled] = useState(initalValueForStatusFilters)
  const [statusEnabled, setStatusEnabled] = useState(initalValueForStatusFilters)
  const [isLoading, setLoading] = useState(true)
  const [isStatusFilter, setIsStatusFilter] = useState(false)

  const hasCreatePermission = () => userData?.permissions?.allPermissions == true || userData?.permissions?.vehicle.create == true
  const hasUpdatePermission = () => userData?.permissions?.allPermissions == true || userData?.permissions?.vehicle.update == true

  // Toggle Modal
  const toggleModal = () => {
    if (modal) {
      resetForm()
    }
    setModal(!modal)

  };
  const toggleDeleteModal = (vehicle) => {
    setVehicleToDelete(vehicle); // Store the vehicle to be deleted
    setDeleteModal(!deleteModal); // Toggle the delete modal
  };

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])
  // Handle Input Change
  const handleChange = (e) => {
    let { name, value } = e.target;
    if(name == 'status' && value ==  'true'){
      value = true
    } else if(name == 'status' && value ==  'false'){
      value = false
    }
    setForm({ ...form, [name]: value });
  };

  const dispatch = useDispatch()

  const fetchVehicles = async () => {
    setLoading(true)
    try {
      dispatch(getVehicles({
        page: currentPage,
        pageSize: rowsPerPage,
        searchText,
        statusDisabled,
        statusEnabled
      }))
    } catch (error) {
      toastr.error('Error fetching vehicles');
    } finally {
      setIsStatusFilter(false)
      setLoading(false)
    }
  };


  // Handle Add or Edit Vehicle
  const handleSave = async () => {
    setLoading(true)
    try {
      const payload = {
        type: form.type,
        model: form.model,
        number: form.number,
        status: form.status || false
      };
      let response;
      if (editIndex !== null) {
        payload.vehicleId = vehicles[editIndex]._id;
        response = await postWeb('/vehicles/update', payload);
      } else {
        response = await postWeb('/vehicles/create', payload);
      }

      if (response?.statusCode == 200) {
        fetchVehicles();
        toastr.success(response.msg);
      } else {
        toastr.error(response.msg);
      }

      resetForm();
      toggleModal();
    } catch (error) {
      toastr.error('Error saving vehicle');
    } finally {
      setLoading(false)
    }
  };

  // Reset Form and Edit Index
  const resetForm = () => {
    setForm({ type: '', model: '', number: '', status: true });
    setEditIndex(null);
  };

  // Handle Edit Button Click
  const handleEdit = (index) => {
    const { type, model, number, status } = vehicles[index]
    setForm({ type, model, number, status });
    setEditIndex(index);
    toggleModal();
  };

  // Handle Delete Vehicle
  const handleDelete = (vehicle) => {
    toggleDeleteModal(vehicle); // Show the delete confirmation modal
  };

  const deleteVehicle = async () => {
    setLoading(true)
    try {
      const payload = { vehicleId: vehicleToDelete._id };
      const response = await postWeb('/vehicles/delete', payload);
      if (response?.statusCode == 200) {
        fetchVehicles(); // Refresh vehicle list
        toastr.success(response.msg);
      } else {
        toastr.error(response.msg);
      }
    } catch (error) {
      toastr.error('Error deleting vehicle');
    } finally {
      setLoading(false)
      setDeleteModal(false); // Close the modal after deletion
    }
  }


  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page); // Update the current page state
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage); // Update the rows per page state
    setCurrentPage(page); // Optionally reset to page 1 when rows per page changes
  };


  // Columns for DataTable
  const columns = [
    {
      name: '#',
      selector: (row, index) => index + 1,
      width: '50px',
    },
    {
      name: 'Vehicle Type',
      selector: 'type',
      sortable: true,
    },
    {
      name: 'Vehicle Model',
      selector: 'model',
      sortable: true,
    },
    {
      name: 'Vehicle Number',
      selector: 'number',
      sortable: true,
    },
    {
      name: 'Status',
      sortable: true,
      selector: (row) => (row.status ? 'Active' : 'Inactive'),
    },
    ...(
      hasUpdatePermission() == true
        ? [
          {
            name: 'Actions',
            cell: (row, index) => (
              <>
                <Button
                  disabled={isLoading}
                  color="warning"
                  size="sm"
                  className="me-2"
                  onClick={() => handleEdit(index)}
                >
                  <i className="bx bx-edit-alt"></i>
                </Button>
                <Button
                  disabled={isLoading}
                  color="danger"
                  size="sm"
                  onClick={() => handleDelete(row)}
                >
                  <i className="bx bx-trash"></i>
                </Button>
              </>
            ),
          },
        ]
        : []
    ),
  ];

  const debouncedFetchVehicles = _.debounce(fetchVehicles, 800);

  useEffect(() => {
    fetchVehicles();
  }, []);

  useEffect(()=>{
    setVehicles(vehicleList)
    setTotalVehicles(totalVehiclesCount)
  },[vehicleList,totalVehiclesCount])

  // Track if we should reset pagination
  useEffect(() => {
    if (searchText || statusDisabled !== initalValueForStatusFilters || statusEnabled !== initalValueForStatusFilters) {
      setCurrentPage(1); // Reset current page
      setRowsPerPage(10); // Reset rows per page
    }
  }, [searchText, statusDisabled, statusEnabled]);

  // Fetch vehicles only after state updates (pagination reset or filter changes)
  useEffect(() => {
    debouncedFetchVehicles();

    return () => {
      debouncedFetchVehicles.cancel();
    };
  }, [currentPage, rowsPerPage, searchText, statusDisabled, statusEnabled]);

 

  useState(()=>{
    console.log('formm',form)
  },[form])

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.vehicle?.read == true) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Masters | Vehicle</title>
          </MetaTags>
  
          <div className="container-fluid">
            <Breadcrumbs
              title={props.t("Masters")}
              breadcrumbItem={props.t("Vehicle Master")}
            />
            {hasCreatePermission() == true && <Button disabled={isLoading} className="mb-4" color="primary" onClick={toggleModal} style={{ float: 'right' }}>
              Add New Vehicle
            </Button>}
            <div className="mb-4 d-flex align-items-center w-full justify-content-between">
  
              <div className="w-50">
                <Input
                  disabled={isLoading}
                  type="text"
                  placeholder="Search by Model, or Number"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  className='w-full p-2 border rounded'
                />
              </div>
              <div className='d-flex  align-items-center'>
                <div className="d-flex align-items-center">
                  <span className={`me-2 ${statusEnabled ? "text-success font-weight-semibold" : ""}`}>Show Enabled</span>
                  <div className="form-check form-switch form-switch-lg">
                    <input
                      disabled={isLoading}
                      type="checkbox"
                      className="form-check-input form-slider-color-green"
                      defaultChecked={statusEnabled}
                      onChange={(e) => { setStatusEnabled(!statusEnabled) }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className={`me-2 ${statusDisabled ? "text-success font-weight-semibold" : ""}`}>Show Disabled</span>
                  <div className="form-check form-switch form-switch-lg">
                    <input
                      disabled={isLoading}
                      type="checkbox"
                      className="form-check-input form-slider-color-green"
                      defaultChecked={statusDisabled}
                      onChange={(e) => { setStatusDisabled(!statusDisabled) }}
                    />
                  </div>
                </div>
              </div>
  
  
  
            </div>
  
            <DataTable
              columns={columns}
              data={vehicles}
              pagination
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              paginationDefaultPage={currentPage}
              onChangePage={handlePageChange}
              paginationTotalRows={totalVehicles}
              onChangeRowsPerPage={handleRowsPerPageChange}
              progressPending={isLoading}
              progressComponent={<Skeleton height={50} count={5} />}
              paginationServer
            />
  
  
  
            {/* Modal for Add/Edit Vehicle */}
            <Modal isOpen={modal} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}>Add New Vehicle</ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup className="mb-3">
                    <Label for="vehicleType">Vehicle Type</Label>
                    <Input
                      disabled={isLoading}
                      type="select"
                      name="type"
                      id="vehicleType"
                      value={form.type}
                      onChange={handleChange}
                    >
                      <option value="">Choose an Option</option>
                      <option value="Car">Car</option>
                      <option value="Van">Van</option>
                    </Input>
                  </FormGroup>
  
                  <FormGroup className="mb-3">
                    <Label for="vehicleModel">Vehicle Model</Label>
                    <Input
                      disabled={isLoading}
                      type="text"
                      name="model"
                      id="vehicleModel"
                      placeholder="Enter vehicle model"
                      value={form.model}
                      onChange={handleChange}
                    />
                  </FormGroup>
  
                  <FormGroup className="mb-3">
                    <Label for="vehicleNumber">Vehicle Number</Label>
                    <Input
                      disabled={isLoading}
                      type="text"
                      name="number"
                      id="vehicleNumber"
                      placeholder="Enter vehicle number"
                      value={form.number}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label for="vendorStatus">Status</Label>
                    <Input
                      disabled={isLoading}
                      type="select"
                      name="status"
                      id="vendorStatus"
                      value={form.status}
                      onChange={handleChange}
                    >
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </Input>
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button disabled={isLoading} color="secondary" onClick={toggleModal}>Cancel</Button>
                <Button disabled={isLoading} color="primary" onClick={handleSave}>Save</Button>
              </ModalFooter>
            </Modal>
            {/* Delete Confirmation Modal */}
            <Modal isOpen={deleteModal} toggle={() => setDeleteModal(!deleteModal)}>
              <ModalHeader toggle={() => setDeleteModal(!deleteModal)}>
                Confirm Deletion
              </ModalHeader>
              <ModalBody>
                <p>Are you sure you want to delete this vehicle?</p>
                <p><strong>{vehicleToDelete?.type} - {vehicleToDelete?.model} ({vehicleToDelete?.number})</strong></p>
              </ModalBody>
              <ModalFooter>
                <Button disabled={isLoading} color="secondary" onClick={() => setDeleteModal(false)}>Cancel</Button>
                <Button
                  disabled={isLoading}
                  color="danger"
                  onClick={deleteVehicle}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
  
          </div>
        </div>
      </React.Fragment >
    )
  } else {
    return <PagesNoPermission />
  }
};

Vehicles.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Vehicles);
