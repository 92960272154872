import {
  GET_B2B_CUSTOMER_SUCCESS,
  GET_B2B_CUSTOMER_FAIL,
  GET_CART_DATA_FAIL,
  GET_CART_DATA_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_SHOPS_FAIL,
  GET_SHOPS_SUCCESS,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_DETAIL_FAIL,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAIL,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  GET_PRODUCT_COMMENTS_SUCCESS,
  GET_PRODUCT_COMMENTS_FAIL,
  ON_LIKE_COMMENT_SUCCESS,
  ON_LIKE_REPLY_SUCCESS,
  ON_ADD_REPLY_SUCCESS,
  ON_ADD_COMMENT_SUCCESS,
  // pricelist

  GET_CLOTHLIST_SUCCESS,
  GET_PRICELIST_SUCCESS,
  GET_PRICELIST_FAIL,
  ADD_PRICE_SUCCESS,
  ADD_PRICE_FAIL,
  UPDATE_PRICE_SUCCESS,
  UPDATE_PRICE_FAIL,
  DELETE_PRICE_SUCCESS,
  DELETE_PRICE_FAIL,
  //pricelist end
  GET_DRIVER_SUCCESS,
  //laungylist
  GET_LAUNDRIES,
  GET_LAUNDRIES_SUCCESS,
  GET_LAUNDRIES_FAIL,
  // Area 
  GET_AREA,
  GET_AREA_SUCCESS,
  GET_AREA_FAIL,
  GET_TIMESLOT_SUCCESS,
  GET_TIMESLOT_FAIL,
  ADD_NEW_AREA,
  ADD_NEW_AREA_SUCCESS,
  ADD_NEW_AREA_FAIL,
  UPDATE_AREA,
  UPDATE_AREA_SUCCESS,
  UPDATE_AREA_FAIL
} from "./actionTypes"

const INIT_STATE = {
  products: [],
  product: {},
  orders: [],
  pricelist: [],
  laudrylist: [],
  driverlist: [],
  clothlist: [],
  arealist: [],
  cartData: {},
  customers: [],
  shops: [],
  error: {},
  productComments: [],
  timeSlots: [],
  newAreaList: [],
  b2bCustomers: [],
  priceListDownloadLink:""
}

const Ecommerce = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TIMESLOT_SUCCESS:
      return {
        ...state,
        timeSlots: action.payload
      }
    case GET_B2B_CUSTOMER_SUCCESS:
      return {
        ...state,
        b2bCustomers: action.payload
      }
    case GET_TIMESLOT_FAIL:
      return {
        ...state,
        timeSlots: []
      }
    case GET_CLOTHLIST_SUCCESS:
      return {
        ...state,
        clothlist: action.payload,
      }

    case ADD_PRICE_SUCCESS:
      const updatedPricelist = [...state.pricelist]

      const updatedPrice = action.payload
      updatedPrice.cloth = {
        _id: updatedPrice.cloth,
      }

      const updatedPriceIndex = updatedPricelist.findIndex(
        service => service?._id === updatedPrice._id || service.cloth._id === updatedPrice.cloth._id
      )

      if (updatedPriceIndex > -1) {
        updatedPricelist[updatedPriceIndex] = updatedPrice
      } else {
        updatedPricelist.push(updatedPrice)
      }

      return {
        ...state,
        pricelist: updatedPricelist,
      }

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
      }

    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NEW_AREA_SUCCESS:
      return {
        ...state,
        newAreaList: action.payload,
      }

    case ADD_NEW_AREA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_AREA_SUCCESS:
      return {
        ...state,
        newAreaList: action.payload,
      }

    case UPDATE_AREA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        product: action.payload,
      }

    case GET_PRODUCT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload.record,
      }

    case GET_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // pricelist 
    case GET_PRICELIST_SUCCESS:
      if(action.payload.filePath){
        return {
          ...state,
          pricelist: action.payload.record,
          priceListDownloadLink:  action.payload.filePath
        }
      }
      return {
        ...state,
        pricelist: action.payload.record,
        priceListDownloadLink:''
      }

    case GET_PRICELIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // pricelist

    case GET_DRIVER_SUCCESS:
      return {
        ...state,
        driverlist: action.payload.record,
      }

    //laugny list


    case GET_PRICELIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // update pricelist 
    case UPDATE_PRICE_SUCCESS:
      return {
        ...state,
        pricelist: state.pricelist.map(price =>
          price._id.toString() === action.payload._id.toString()
            ? { price, ...action.payload }
            : price
        ),
      }
    case UPDATE_PRICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    // delete pricelist 

    case DELETE_PRICE_SUCCESS:
      return {
        ...state,
        pricelist: state.pricelist.filter(
          price => price._id.toString() !== action.payload._id.toString()
        ),
      }

    case DELETE_PRICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //laugny list

    case GET_LAUNDRIES_SUCCESS:
      return {
        ...state,
        laudrylist: action.payload.record,
      }

    case GET_LAUNDRIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    // area list

    case GET_AREA_SUCCESS:
      return {
        ...state,
        arealist: action.payload.record,
      }

    case GET_AREA_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case ADD_ORDER_SUCCESS:
      return {
        ...state,
        orders: [...state.orders, action.payload],
      }

    case ADD_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        orders: state.orders.map(order =>
          order.id.toString() === action.payload.id.toString()
            ? { order, ...action.payload }
            : order
        ),
      }

    case UPDATE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        orders: state.orders.filter(
          order => order.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CART_DATA_SUCCESS:
      return {
        ...state,
        cartData: action.payload,
      }

    case GET_CART_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
      }

    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: [...state.customers, action.payload],
      }

    case ADD_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: state.customers.map(customer =>
          customer.id.toString() === action.payload.id.toString()
            ? { customer, ...action.payload }
            : customer
        ),
      }

    case UPDATE_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: state.customers.filter(
          customer => customer.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SHOPS_SUCCESS:
      return {
        ...state,
        shops: action.payload,
      }

    case GET_SHOPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PRODUCT_COMMENTS_SUCCESS:
    case ON_LIKE_COMMENT_SUCCESS:
    case ON_LIKE_REPLY_SUCCESS:
    case ON_ADD_REPLY_SUCCESS:
    case ON_ADD_COMMENT_SUCCESS:
      return {
        ...state,
        productComments: action.payload,
      }

    case GET_PRODUCT_COMMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Ecommerce
