import PropTypes from 'prop-types';
import React, { useState } from "react";

import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem, Form, Input, InputGroup } from "reactstrap";

// Reactstrap
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

// Import menuDropdown
import RightSidebar from "../CommonForBoth/RightSidebar";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// import images

import logoLightSvg from "../../assets/images/logo-light.svg";
import logo from "../../assets/images/logo.svg";

//i18n
import { withTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
// Redux Store
import {
  changeSidebarType,
  showRightSidebarAction,
  toggleLeftmenu
} from "../../store/actions";

const Header = props => {
  const [search, setsearch] = useState(false);
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  

  const history = useHistory();

  const toggleTopDrawer = () => {
    setPosition('right');
    setOpen(!open);
  };

  const onDrawerClose = () => {
    setOpen(false);
  };



  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }
  const menuItems = [
    {
      label: "Order ID",
      value: "orderId"
    },
    {
      label: "Mobile",
      value: "mobile"
    },
    {
      label: "Customer ID",
      value: "customerId"
    },
  ]
const [searchCategory, setSearchCategory] = useState(menuItems[0]);
const [searchInput, setSearchInput] = useState('');
const [dropdownOpen, setDropdownOpen] = useState(false);

// Toggle dropdown
const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

// Handle input change
const handleInputChange = (e) => {
  setSearchInput(e.target.value);
};

// Handle category change
const handleCategoryChange = (category) => {
  setSearchCategory(category);
  setSearchInput(''); // Clear input when category changes
};

// Handle search button click
const handleSearchClick = (e) => {
  e.preventDefault();
  if(searchInput.length > 0){
    if(searchCategory.value === "mobile"){
      history.push(`/customer-details?mobile=${searchInput}`);
    }
    if(searchCategory.value === "orderId"){
      history.push(`/order-details?orderId=${searchInput}`);
    }
    if(searchCategory.value === "customerId"){
      history.push(`/customer-details?customerId=${searchInput}`);
    }

    setSearchInput('');
  }
};

 

  return (
    <React.Fragment>
      
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

          </div>
          <Form onSubmit={handleSearchClick} className='' style={{ width: "50%", marginRight: 'auto' }}>
            <InputGroup size="md">
            <Dropdown size="md" isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret style={{
                  backgroundColor: 'white',
                  borderColor: '#ccc',
                  borderRadius:0
                }}
                >
                  <span className='text-black'>{searchCategory.label}</span>
                  <i className='bx bx-chevron-down text-black' ></i>
                </DropdownToggle>
                <DropdownMenu size="md" className='p-0'>
                  {menuItems.map((item) => (
                    <DropdownItem
                      key={item.value}
                      onClick={() => handleCategoryChange(item)}
                      style={{ borderBottom: '1px solid #ccc' }} // Add border-bottom to each item
                    >
                      {item.label}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              <Input
                type="text"
                placeholder={`Search by ${searchCategory.label}`}
                value={searchInput}
                onChange={handleInputChange}
              />
            
              <button
              type='submit'
                className="btn btn-primary"
              >
                <i className='bx bx-search-alt-2 h-4 w-4'></i>
              </button>
            </InputGroup>
          </Form>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
               
            {/* <LanguageDropdown /> */}


            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            {/*<NotificationDropdown />*/}
            <ProfileMenu />

          </div>
        </div>
      </header>
      <ReactDrawer
        open={open}
        position={position}
        onClose={onDrawerClose}
      >
        <RightSidebar onClose={onDrawerClose} />
      </ReactDrawer>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
