import { GET_MAINTANANCE_REQUESTS_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  records: [],
  count: 0,
};

const posMaintanance = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MAINTANANCE_REQUESTS_SUCCESS:
      return { 
        ...state, 
        records: action.payload.record, 
        count: action.payload.totalRecords,
      };
    default:
      return state;
  }
};

export default posMaintanance;
