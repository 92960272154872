import axios from "axios"
import { del, get, post, put, postWeb } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)
// promos code 

const addNewPromos = data => post(url.ADD_NEW_PROMOS_CODE, data)
const updatePromos = data => post(url.UPDATE_PROMOS_CODE, data)
const PromoCodeList = (data) => post(url.PROMOS_CODE_LIST,data);
const getTransactions = data => postWeb(url.GET_TRANSACTIONS, data)
// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// INvoice logs
const getInvoiceLogs = data => post(url.GET_INVOICE_LOG,data)

// posMaintance
const getPosMaintance = data => post(url.GET_POS_MAINTANCE_REPORTS,data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_LOGIN, data); //login api

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

// get orders
export const getOrders = order => post(url.GET_ORDERS, order)

//get pricelist
export const getPricelist = priclist => post(url.GET_PRICELIST, priclist)
export const addPricelist = data => post(url.ADD_PRICELIST, data)
export const updatePricelist = data => post(url.UPDATE_PRICELIST, data)
export const _bulkUpdatePrice = data => postWeb(url.BULK_UPDATE_PRICE, data)
export const deletePricelist = data => post(url.DELETE_PRICELIST, data)
export const getClothlist = data => post(url.CLOTH_SEARCH, data)

//get laundrylist
export const getlaundrylist = priclist => post(url.GET_LAUNDRY, priclist)
//get driverlist
export const getDriverlist = priclist => post(url.GET_DRIVER, priclist)

//Driver Locatoin:
export const getDriverLocation = priclist => post(url.GET_DRIVER_LOCATION, priclist)
//get arealist
export const getArealist = priclist => post(url.GET_AREA_LIST, priclist)
export const getB2bCustomerlist = priclist => postWeb(url.GET_B2B_CUSTOMER, priclist)

//Add new Area
export const addNewArea = newArea => post(url.ADD_NEW_AREA, newArea)

export const updateArealist = priclist => post(url.UPDATE_AREA_LIST, priclist)

// get timeSlot of date
export const getTimeSlotList = data => post(url.GET_TIMESLOT_OF_DATE, data)

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

//get records

export const getRecords = data => post(url.GET_RECORD, data ? data : {})

//get Search records

export const getSearchRecords = data => post(url.GET_SEARCH_RECORD, data ? data : {})

// get contacts
export const getUsers = () => get(url.GET_USERS)

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user)

// search Reason
export const searchReason = data => post(url.REASON_SEARCH, data)

// Refund
export const refundOrder = data => postWeb(url.REFUND_AMOUNT, data)

// Permission
export const getPermission = data => postWeb(url.GET_PERMISSION, data)
export const getAllPermission = data => postWeb(url.GET_ALL_PERMISSIONS, data)

// Permission module
export const getAllModules = data => postWeb(url.GET_ALL_MODULE, data)

export const deleteLaundry = (data) => post(url.DELETE_LAUNDRY, data)

// edit role permission
export const editPermission = data => postWeb(url.UPDATE_PERMISSION, data)

// create module permission
export const createModule = data => postWeb(url.CREATE_MODULE, data)

// create Role
export const createRoles = data => postWeb(url.CREATE_ROLE, data)

// edit module permission
export const editModule = data => postWeb(url.UPDATE_MODULE, data)

export const getDataReport = data => post(url.DATA_REPORT, data)
//  SLIDERS 
export const getSlider = data => post(url.GET_SLIDER, data)
export const addNewSlider = data => post(url.CREATE_SLIDER, data, "content")
export const updateSlider = data => post(url.UPDATE_SLIDER, data, "content")
export const deleteSlider = data => post(url.DELETE_SLIDER, data)

// update user
export const updateUser = user => put(url.UPDATE_USER, user)

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } })

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project)

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project)

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } })

export const getUserProfile = () => get(url.GET_USER_PROFILE)

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS)

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail)

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } })

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS)

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS)

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS)

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS)

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS)

//  get driverlist 
// Permission
export const getDrivers = data => post(url.GET_DRIVERS, data)

export const addDrivers = data => post(url.ADD_DRIVERS, data)

export const updateDrivers = data => post(url.UPDATE_DRIVERS, data)

export const deleteDrivers = data => post(url.DELETE_DRIVERS, data)

export const getCoupon = data => post(url.GET_COUPONS, data)

export const updateCoupons = data => post(url.UPDATE_COUPONS, data)

export const addCoupons = data => post(url.ADD_COUPONS, data)

export const getVendorsList = data=> post(url.GET_VENDORS,data)
export const getLaundryWorkersList = data=> post(url.GET_LAUNDRY_WORKERS,data)
export const getVehicleList = data=> post(url.GET_VEHICLE,data)
export const getAccidentReports = data=> post(url.GET_ACCIDENT_REPORTS,data)
export const getPartChanges = data=> post(url.GET_PART_CHANGES,data)

// get dashboard charts data
//export const getWeeklyData = month => post(url.GET_WEEKLY_DATA,month)
//export const getYearlyData = month => post(url.GET_YEARLY_DATA,month)
/*export const getMonthlyData = (month)=> {
 return {};
  //return  post(url.GET_MONTHLY_DATA, month)
}*/

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS)

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  })
}
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  })
}

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  })
}

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  })
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
  addNewPromos,
  updatePromos,
  PromoCodeList,
  getTransactions,
  getInvoiceLogs,
  getPosMaintance
}
