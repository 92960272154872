import { postWeb } from "helpers/api_helper"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import DataTable from "react-data-table-component"
import { withTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import { MetaTags } from "react-meta-tags"
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import toastr from "toastr"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { getLaundrylist, getPosMaintanance } from "store/actions"
import PagesNoPermission from "pages/Utility/pages-no-permission"
import ImagesModal from "components/ImagesModal"

const statusOptions = [
  { label: "New", value: "new" },
  { label: "In Progress", value: "in_progress" },
  { label: "Resolved", value: "resolved" },
  { label: "Declined", value: "declined" }
];

const categoryOptions = [
  { label: "Safety Hazard", value: "safety_hazard" },
  { label: "Equipment Failure", value: "equipment_failure" },
  { label: "Urgent Repairs", value: "urgent_repairs" },
  { label: "Routine Maintenance", value: "routine_maintenance" }
];


const POSMaintenanceRequests = props => {
  const [maintenanceRequests, setMaintenanceRequests] = useState([])
  const [userData, setUserData] = useState({});
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalRequests, setTotalRequests] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [laundries, setLaudries] = useState([])
  const [filters, setFilters] = useState({
    status: '',
    category: '',
    startDate: '',
    endDate: '',
    laundry: ''
  })
  const [selectedRequest, setSelectedRequest] = useState(null)
  const [isModalOpen, setModalOpen] = useState(false)
  const { laundrylist, requests, totalRecord, users } = useSelector(state => ({
    laundrylist: state.ecommerce.laudrylist,
    requests: state.PosMaintanance.records,
    totalRecord: state.PosMaintanance.count,
    users: state.Users.users,
  }))

  const dispatch = useDispatch()

  const fetchMaintenanceRequests = async () => {
    dispatch(getPosMaintanance({ page: currentPage, limit: rowsPerPage, ...filters, web: true }))
  }

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [filterName]: value }))
    setCurrentPage(1) // Reset to the first page whenever a filter is applied
  }

  const handlePageChange = page => setCurrentPage(page)
  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage)
    setCurrentPage(page)
  }

  const toggleModal = () => setModalOpen(!isModalOpen)

  const handleViewDetails = request => {
    setSelectedRequest(request)
    toggleModal()
  }

  const handleStatusChange = async status => {
    try {
      setLoading(true)
      const response = await postWeb(`/operations/updateMaintenanceIssue`, {
        _id: selectedRequest._id,
        status,
      })

      if (response?.statusCode == 200) {
        toastr.success("Status updated successfully")
        toggleModal()
        fetchMaintenanceRequests()
      } else {
        toastr.error(response?.msg || "Error updating status")
      }
    } catch (error) {
      toastr.error("Error updating status")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {
    fetchMaintenanceRequests()
  }, [currentPage, rowsPerPage, filters])

  useEffect(() => {
    setMaintenanceRequests(requests)
    setTotalRequests(totalRecord)
    setLaudries(laundrylist)
  }, [laundrylist, requests, totalRecord])

  const columns = [
    { name: "Request ID", selector: row => row.requestID || "N/A", sortable: true, center: true },
    { name: "Laundry Name", selector: row => row?.laundry?.nameEn || "N/A", sortable: true, wrap: true, },
    { name: "Reported By", selector: row => row?.reportedBy?.name || "N/A", sortable: true, wrap: true },
    { name: "Category", selector: row => categoryOptions.find(option => option.value === row.category)?.label || "N/A", sortable: true, wrap: true },
    // { name: "Description", selector: row => row.description || "N/A", sortable: true, wrap: true },
    {
      name: "Status",
      center: true,
      cell: row => {
        const status = row?.status
        const baseStyle = {
          color: "white",
          padding: "5px 10px",
          borderRadius: "5px",
          fontSize: "10px",
        };
        let statusStyle;
        let statusLabel;

        switch (status) {
          case "new":
            statusStyle = { ...baseStyle, backgroundColor: "#FF9029" };
            statusLabel = "New";
            break;
          case "in_progress":
            statusStyle = { ...baseStyle, backgroundColor: "#FFDB29" };
            statusLabel = "In Progress";
            break;
          case "resolved":
            statusStyle = { ...baseStyle, backgroundColor: "#0AD200" };
            statusLabel = "Resolved";
            break;
          case "declined":
            statusStyle = { ...baseStyle, backgroundColor: "#FF2929" };
            statusLabel = "Declined";
            break;
          default:
            statusStyle = { ...baseStyle, backgroundColor: "gray" };
            statusLabel = _.capitalize(status || "Unknown");
            break;
        }
        return <span style={statusStyle}>{statusLabel}</span>;
      },
      sortable: true
    },
    // {
    //   name: "audio",
    //   selector: row => {
    //     const [isPlaying, setIsPlaying] = useState(false);
    //     const audio = new Audio(row?.audio);

    //     const togglePlayPause = () => {
    //       if (isPlaying) {
    //         audio.pause();
    //         setIsPlaying(false);
    //       } else {
    //         audio.play();
    //         setIsPlaying(true);
    //         audio.addEventListener("ended", () => setIsPlaying(false)); // Reset to pause on audio end
    //       }
    //     };

    //     return row?.audio ? (
    //       <Button
    //         className="d-flex align-items-center justify-content-between"
    //         color="primary"
    //         outline
    //         size="sm"
    //         onClick={togglePlayPause}
    //       >
    //         <i className={`bx ${isPlaying ? "bx-pause" : "bx-play"} me-1`} />
    //         <span>{isPlaying ? "Pause" : "Play"}</span>
    //       </Button>
    //     ) : (
    //       'Not Uploaded'
    //     );
    //   },
    //   sortable: true,
    // },
    { name: "Requested At", selector: row => moment(row.createdAt).format("YYYY-MM-DD"), sortable: true },
    {
      name: "Actions",
      cell: row => (
        <div>
          <Button size="sm" color="primary" onClick={() => handleViewDetails(row)}>
            View
          </Button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]

  useEffect(() => {
    dispatch(getLaundrylist())
    fetchMaintenanceRequests()
  }, [])


  const hasUpdatePermission = () => userData?.permissions?.allPermissions == true || userData?.permissions?.posMaintance.update == true

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.posMaintance?.read == true) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>POS | Maintenance Requests</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs
              title={props.t("POS")}
              breadcrumbItem={props.t("Maintenance Requests")}
            />
            <div className="mb-4 d-flex flex-wrap w-full">
              <FormGroup className="me-3">
                <Label>Status</Label>
                <Input
                  type="select"
                  value={filters.status}
                  onChange={e => handleFilterChange('status', e.target.value)}
                >
                  <option value="">Select</option>
                  {statusOptions.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup className="me-3">
                <Label>Category</Label>
                <Input
                  type="select"
                  value={filters.category}
                  onChange={e => handleFilterChange('category', e.target.value)}
                >
                  <option value="">Select</option>
                  {categoryOptions.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup className="me-3">
                <Label>Laundry</Label>
                <Input
                  type="select"
                  value={filters.laundry}
                  onChange={e => handleFilterChange('laundry', e.target.value)}
                >
                  <option value="">Select</option>
                  {laundries.length ? laundries.map((option, index) => (
                    <option key={index} value={option._id}>{option.nameEn}</option>
                  )) : null}
                </Input>
              </FormGroup>
              <FormGroup className="me-3">
                <Label>Start Date</Label>
                <Input
                  type="date"
                  value={filters.startDate}
                  onChange={e => handleFilterChange('startDate', e.target.value)}
                />
              </FormGroup>
              <FormGroup className="me-3">
                <Label>End Date</Label>
                <Input
                  type="date"
                  value={filters.endDate}
                  onChange={e => handleFilterChange('endDate', e.target.value)}
                />
              </FormGroup>
            </div>

            <DataTable
              columns={columns}
              data={maintenanceRequests}
              pagination
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              paginationDefaultPage={currentPage}
              onChangePage={handlePageChange}
              paginationTotalRows={totalRequests}
              onChangeRowsPerPage={handleRowsPerPageChange}
              progressPending={isLoading}
              progressComponent={<Skeleton height={50} count={5} />}
              paginationServer

            />
          </div>

          {selectedRequest && (
            <Modal isOpen={isModalOpen} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}>Maintenance Request Details</ModalHeader>
              <ModalBody>
                <div className="d-flex justify-content-between mb-4">
                  <strong>Request ID:</strong>
                  <span>{selectedRequest.requestID}</span>
                </div>
                <div className="d-flex justify-content-between mb-4">
                  <strong>Laundry Name:</strong>
                  <span>{selectedRequest?.laundry?.nameEn || "N/A"}</span>
                </div>
                <div className="d-flex justify-content-between mb-4">
                  <strong>Reported By:</strong>
                  <span>{selectedRequest?.reportedBy?.name || "N/A"}</span>
                </div>
                <div className="d-flex justify-content-between mb-4">
                  <strong>Category:</strong>
                  <span>{categoryOptions.find(option => option.value === selectedRequest.category)?.label}</span>
                </div>
                <div className="d-flex justify-content-between mb-4">
                  <strong>Description:</strong>
                  <span>{selectedRequest.description}</span>
                </div>
                <FormGroup className=" w-full d-flex flex-row align-items-center justify-content-between mb-4">
                  <Label for="statusFilter" className="font-weight-bolder w-25">
                    <strong>Status:</strong>
                  </Label>
                  <Input
                    className="mt-0 w-75"
                    type="select"
                    id="statusFilter"
                    disabled={hasUpdatePermission() != true}
                    value={selectedRequest.status || ""}
                    onChange={e =>
                      setSelectedRequest(prev => ({ ...prev, status: e.target.value }))
                    }
                  >
                    <option value="">Select Status</option>
                    {statusOptions.map((option, index) => (
                      <option
                        disabled={option.value === "new"}
                        key={index}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <div className="d-flex justify-content-between mt-2">
                  <strong>Created At:</strong>
                  <span>{moment(selectedRequest.createdAt).format("YYYY-MM-DD")}</span>
                </div>
                <div className="mt-3">
                  <div className="d-flex justify-content-between">
                    <strong>Images:</strong>
                    {selectedRequest?.images?.length > 0 ? (
                    <ImagesModal images={selectedRequest?.images} title="Maintenance Request Images" />
                    ) : "Image(s) not uploaded."}
                  </div>
                </div>
                {selectedRequest.audio && (
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <strong>Audio:</strong>
                    <audio controls className="w-75">
                      <source src={selectedRequest.audio} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                {hasUpdatePermission() == true ? <Button
                  color="success"
                  onClick={() => handleStatusChange(selectedRequest.status)}
                  disabled={!selectedRequest.status || selectedRequest.status === "new"} // Disable if status is empty or "new"
                >
                  Update
                </Button> : null}
                <Button color="secondary" onClick={toggleModal}>Close</Button>
              </ModalFooter>
            </Modal>
          )}
        </div>
      </React.Fragment>
    )
  } else {
    return <PagesNoPermission />
  }
}

POSMaintenanceRequests.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(POSMaintenanceRequests)
