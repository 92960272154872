import { takeEvery, put, call } from "redux-saga/effects";
import { GET_VENDORS, GET_VENDORS_FAIL, GET_VENDORS_SUCCESS } from "./actionTypes";
import { apiSuccess, apiFail } from './actions';
import { getVendorsList } from "helpers/fakebackend_helper"; // updated import name to avoid conflicts
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function* fetchVendors({ payload }) {
  try {
    const { page, pageSize, searchText,
      statusDisabled,
      statusEnabled } = payload;
    const response = yield call(getVendorsList, {
      page, pageSize, searchText,
      statusDisabled,
      statusEnabled
    });
    yield put(apiSuccess(response, GET_VENDORS_SUCCESS));
    // Check if the response is successful, if so, dispatch the success action
    if (response.statusCode == 200) {
      yield put(apiSuccess(response, GET_VENDORS_SUCCESS));
    } else {
      // Handle error response
      yield put(apiFail(response.message || 'Something went wrong', GET_VENDORS_FAIL));
      toastr.error(response.message || 'Failed to fetch vendor list');
    }
  } catch (error) {
    // Handle any unexpected errors
    console.log('error', error)
    yield put(apiFail(error.message, GET_VENDORS_FAIL));
    toastr.error('An error occurred while fetching vendor list');
  }
}

function* vendorSaga() {
  yield takeEvery(GET_VENDORS, fetchVendors);
}

export default vendorSaga;
