import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Input } from "reactstrap"
import { Button } from "reactstrap"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import "../../assets/css/custom.css"
import { useSelector, useDispatch } from "react-redux"
import {
  getPricelist,
  getClothlist,
  addPriceList,
  updatePriceList,
  searchUsers,
  bulkUpdatePrice,
} from "../../store/actions"
import Switch from "react-switch"
import PropTypes from "prop-types"
import toastr from "toastr"
import Pages404 from "pages/Utility/pages-404"
import CopyPricelistModal from "./modals/CopyPricelistModal"
import ImportPricesModal from "./ImportPricesModal"
import { postWeb } from "helpers/api_helper"

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Off
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      On
    </div>
  )
}

const OffsymbolVAT = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    > 
      {" "}
      W/O
    </div>
  )
}

const OnSymbolVAT = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      W
    </div>
  )
}

function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2")
}

const PriceListUpdate = props => {
  const dispatch = useDispatch()
  const { priceData, clothList, users, laundryList, priceListDownloadLink  } = useSelector(state => ({
    priceData: state.ecommerce.pricelist,
    clothList: state.ecommerce.clothlist,
    users: state.Users.users,
    laundryList: state.Laundry.LaundryList,
    priceListDownloadLink: state.ecommerce.priceListDownloadLink
  }))

  const [priceListData, setPriceListData] = useState([])
  const [isVatPercentValid, setIsVatPercentValid] = useState(false)
  const [pageTitle, SetPageTitle] = useState("Update Price")
  const [pageState, SetPageState] = useState({})
  const [userData, setUserData] = useState({})
  const [inputVat, setInputVat] = useState(false)
  const [vatValue, setVatValue] = useState()
  const [duplicateCloth, setDuplicateCloth] = useState()
  const [isDownload, setDownload] = useState(false)
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!isModalOpen);

  useEffect(() => {
    const hasVatInLaundryData = pageState?.data?.vatPercent > 0
    const isPriceListForArea = pageState?.priceType === "area"
    const hasVatInB2BData = pageState?.data?.laundry?.vatPercent > 0

    const vatValid =
      hasVatInLaundryData || isPriceListForArea || hasVatInB2BData
    setIsVatPercentValid(vatValid)
  }, [pageState])

  useEffect(() => {
    if (isVatPercentValid) {
      setInputVat(true)
      setVatValue(
        pageState?.data?.vatPercent ||
          pageState?.data?.laundry?.vatPercent ||
          15
      )
    }
  }, [isVatPercentValid])

  const state = props.location.state

  useEffect(() => {
    dispatch(searchUsers({ webPermissions: true }))
    SetPageState(state)

    let apiData = { [state.priceType]: state.data.value }
    SetPageTitle(`Price List - ${state?.data?.label} (${state.priceType})` )
    dispatch(getPricelist(apiData))
  }, [])
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

  const exportData = ()=>{
    setDownload(true)
    let apiData = { [state.priceType]: state.data.value, exportToCsv:true }
    dispatch(getPricelist(apiData))
  }

  useEffect(()=>{
    if(priceListDownloadLink && isDownload){
      window.open(`${API_BASE_URL}${priceListDownloadLink}`, '_blank')
      setDownload(false)
    }
  },[priceListDownloadLink,isDownload])

  const getVatPrice = (price, vatPercent) => {
    const res = price + (price * vatPercent) / 100
    return res ? roundToTwo(res) : 0
  }

  const getNormalPrice = (price, vatPercent) => {
    const res = price / (1 + vatPercent / 100)
    return res ? roundToTwo(res) : 0
  }

  useEffect(() => {
    dispatch(getClothlist())
    const priceDataCopy = [...priceData]
    
    // If copying data from other price lists
    // delete pricelist id and price type key
    priceDataCopy.forEach(priceData => {
      if (priceData[state.priceType] !== state.data.value) {
        const keys = ["laundry", "area", "b2bCustomer"];
        
        keys.forEach(key => {
          if(priceData[key] !== undefined){
            delete priceData[key]
          }
        })

        delete priceData._id
      }
    })

    priceDataCopy.forEach(priceData => {
      priceData.iPrice = priceData.iPrice ? priceData.iPrice : 0
      priceData.wPrice = priceData.wPrice ? priceData.wPrice : 0
      priceData.dPrice = priceData.dPrice ? priceData.dPrice : 0
      priceData.iExpressPrice = priceData.iExpressPrice
        ? priceData.iExpressPrice
        : 0
      priceData.wExpressPrice = priceData.wExpressPrice
        ? priceData.wExpressPrice
        : 0
      priceData.dExpressPrice = priceData.dExpressPrice
        ? priceData.dExpressPrice
        : 0
      priceData[state.priceType] = state.data.value
    })
    if (isVatPercentValid) {
      priceDataCopy.forEach(priceData => {
        priceData.iPriceVat = getVatPrice(priceData.iPrice, vatValue)
        priceData.wPriceVat = getVatPrice(priceData.wPrice, vatValue)
        priceData.dPriceVat = getVatPrice(priceData.dPrice, vatValue)
        priceData.iExpressPriceVat = getVatPrice(
          priceData.iExpressPrice,
          vatValue
        )
        priceData.wExpressPriceVat = getVatPrice(
          priceData.wExpressPrice,
          vatValue
        )
        priceData.dExpressPriceVat = getVatPrice(
          priceData.dExpressPrice,
          vatValue
        )
      })
    }

    setPriceListData(priceDataCopy)
    console.log("priceData", priceDataCopy)
  }, [priceData])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0])
    }
  }, [users])

  const savePricelistData = (priceListRow, index) => {
    const priceListID = priceListRow._id
    let oldData = [...priceListData]
    // let oldDataIndex = oldData.findIndex(item => item._id === priceListID)
    let oldDataIndex = index

    const clothID = oldData?.[oldDataIndex]?.cloth?._id

    if (!clothID) {
      oldData[oldDataIndex]["errorCloth"] = true
      toastr.error("Please enter Cloth", "Incomplete Data")
      return
    }

    //verify if cloth is selected multiple times
    let i = 0,
      occurance = 0

    for (i = 0; i < oldData.length; i++) {
      if (oldData?.[i]?.cloth?._id == clothID) {
        occurance++
      }
    }

    if (occurance > 1) {
      toastr.error(`Cloth is selected multiple times.`, "Duplicate Data")
      return
    }

    if (
      oldData[oldDataIndex].cloth?._id == undefined ||
      oldData[oldDataIndex].cloth?._id == ""
    ) {
      oldData[oldDataIndex]["errorCloth"] = true
      toastr.error("Please enter Cloth", "Incomplete Data")
      return
    }

    if (!isValidAmount(oldData[oldDataIndex].iPrice)) {
      oldData[oldDataIndex]["erroriPrice"] = true
      toastr.error("Please enter valid Iorn Price", "Invalid Price")
      return
    }

    if (!isValidAmount(oldData[oldDataIndex].wPrice)) {
      oldData[oldDataIndex]["errorwPrice"] = true
      toastr.error("Please enter valid Wash Price", "Invalid Price")
      return
    }

    if (!isValidAmount(oldData[oldDataIndex].dPrice)) {
      oldData[oldDataIndex]["errordPrice"] =  true
      toastr.error("Please enter valid Dry Clean Price", "Invalid Price")
      return
    }

    if (!isValidAmount(oldData[oldDataIndex].iExpressPrice)) {
      oldData[oldDataIndex]["erroriExpressPrice"] = true
      toastr.error(
        "Please enter valid Iorn Express Price",
        "Invalid Express Price"
      )
      return
    }

    if (!isValidAmount(oldData[oldDataIndex].wExpressPrice)) {
      oldData[oldDataIndex]["errorwExpressPrice"] = true
      toastr.error(
        "Please enter valid Wash Express Price",
        "Invalid Express Price"
      )
      return
    }

    if (!isValidAmount(oldData[oldDataIndex].dExpressPrice)) {
      oldData[oldDataIndex]["errordExpressPrice"] = true
      toastr.error(
        "Please enter valid Dry Clean Express Price",
        "Invalid Express Price"
      )
      return
    }

    let apiData = {
      iPrice: oldData[oldDataIndex].iPrice,
      wPrice: oldData[oldDataIndex].wPrice,
      dPrice: oldData[oldDataIndex].dPrice,
      dExpressPrice: oldData[oldDataIndex].dExpressPrice,
      iExpressPrice: oldData[oldDataIndex].iExpressPrice,
      wExpressPrice: oldData[oldDataIndex].wExpressPrice,
      status: oldData[oldDataIndex].status,
      cloth: oldData[oldDataIndex].cloth._id,
    }

    if (priceListID !== undefined && priceListID != "") {
      apiData._id = priceListID
      dispatch(updatePriceList(apiData))
    } else {
      let searchData = {}
      searchData.abortSearch = true
      if (pageState.priceType == "area") {
        apiData.area = pageState.data.value
        // searchData.area = pageState.data.value
      } else if (pageState.priceType == "laundry") {
        apiData.laundry = pageState.data.value
        // searchData.laundry = pageState.data.value
      } else if (pageState.priceType == "b2bCustomer") {
        apiData.b2bCustomer = pageState.data.value
        // searchData.b2bCustomer = pageState.data.value
      }
      dispatch(addPriceList(apiData, searchData))
    }
  }

  const isValidAmount = amount => {
    let _amount = parseFloat(amount)
    if (_amount === undefined || _amount === "" || _amount < 0) {
      return false
    } else {
      return true
    }
  }

  const handleChangePrice = (val, itemID, changeOf, index, isVatPrice) => {
    val = parseFloat(val)
    let oldData = [...priceListData]
    oldData[index][changeOf] = val
    if (isVatPrice) {
      oldData[index][changeOf.slice(0, -3)] = getNormalPrice(val, vatValue)
    } else {
      oldData[index][changeOf + "Vat"] = getVatPrice(val, vatValue)
    }
    if (!isValidAmount(val)) {
      oldData[index]["error"] = true
    } else {
      oldData[index]["error"] = false
    }
    setPriceListData(oldData)
  }

  const handlePriceStatus = (val, index) => {
    priceListData[index].status = val;
    let oldData = [...priceListData]
    setPriceListData(oldData)
  }

  const handleChangeCloth = (val, index) => {
    let oldData = [...priceListData]
    // let oldDataIndex = oldData.findIndex(item => item._id === itemID)
    let oldDataIndex = index
    oldData[oldDataIndex].cloth = { _id: val }
    setPriceListData(oldData)
    setDuplicateCloth(undefined);
  }

  const handleAddCloth = () => {
    let oldAddData = [...priceListData]
    oldAddData.push({
      iPrice: 0,
      iPriceVat: 0,
      wPrice: 0,
      wPriceVat: 0,
      dPrice: 0,
      dPriceVat: 0,
      cloth: { _id: "" },
      status: true,
      dExpressPrice: 0,
      dExpressPriceVat: 0,
      iExpressPrice: 0,
      iExpressPriceVat: 0,
      wExpressPrice: 0,
      wExpressPriceVat: 0,
      [pageState.priceType] : pageState.data.value
    })
    setPriceListData(oldAddData)
  }

  const handleDeleteCloth = (index) => {
    let oldData = [...priceListData]
    oldData.splice(index, 1)
    setPriceListData(oldData)
  }

  const goBack = () => {
    history.back()
  }

  const infoStyle = {
    color: "#0097CE",
    fontSize: "9px",
    marginLeft: "8px",
    marginTop: "2px",
  }

  const handleCopyPricelist = (entityId, entityType) => {
    dispatch(getPricelist({ [entityType]: entityId }))
    setIsCopyModalOpen(false)
  }

  React.useEffect(() => {
    console.log(priceListData)
  }, [priceListData])

  const bulkSavePriceList = () => {
    const clotheID = [];
    let _duplicateCloth, duplicateClothName;
    priceListData.map((p) => {
     
      const cloth = p?.cloth?._id.toString();
      if(clotheID.includes(cloth)){
        _duplicateCloth = cloth;
      }
      clotheID.push(cloth);
    });

    if(_duplicateCloth !== undefined){
      toastr.error("Duplicate cloth selected");
      setDuplicateCloth(_duplicateCloth);
      return;
    }

    dispatch(bulkUpdatePrice(priceListData, {
      [pageState.priceType]: pageState.data.value,
    }))
  }

  if (
    userData?.permissions?.allPermissions == true ||
    userData?.permissions?.pricelist?.read == true
  ) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Update Price List / Kleen admin</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Price List" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="ms-2 mb-2">
                        <div className="d-flex align-items-center my-2">
                          <h4 className="m-0">{pageTitle}</h4>
                          {isVatPercentValid ? (
                            <span className="mx-2 align-items-center d-flex">
                              <span className="mx-2">Without VAT</span>
                              <Switch
                                uncheckedIcon={<OffsymbolVAT />}
                                checkedIcon={<OnSymbolVAT />}
                                className="me-1 mb-sm-8"
                                onColor="#626ed4"
                                onChange={e => {
                                  setInputVat(!inputVat)
                                }}
                                checked={inputVat}
                                disabled={!isVatPercentValid}
                              />
                              <span className="mx-2">With VAT</span>
                            </span>
                          ) : null}
                          <Button
                            color="info"
                            className="mx-2"
                            onClick={exportData}
                          >
                            Export to CSV
                          </Button>
                          {
                           ( userData?.permissions?.allPermissions == true ||
                            userData?.permissions?.importPricelist?.update == true) ? 
                            <Button className="mx-2" color="primary" onClick={toggleModal}>
                              Import Prices
                            </Button>
                            : null
                          }
                          <Button
                            color="success"
                            onClick={bulkSavePriceList}
                          >
                            Save
                          </Button>
                        </div>
                      </div>

                      <div className="text-sm-end">
                        {priceListData && priceListData.length === 0 && (
                          <Button
                            type="button"
                            color="primary"
                            className="btn-rounded  mb-2 me-2"
                            onClick={() => {
                              setIsCopyModalOpen(true)
                            }}
                          >
                            Copy Pricelist
                          </Button>
                        )}
                        <Button
                          type="button"
                          color="primary"
                          className="btn-rounded mb-2 me-2"
                          onClick={goBack}
                        >
                          <i className="bx bx-left-arrow-alt me-1" />
                          Back
                        </Button>
                      </div>
                    </div>

                    <table className="table table-border">
                      <thead>
                        <tr>
                          <th>Cloth</th>
                          <th>Iron</th>
                          <th>Wash & Iron</th>
                          <th>Dry Clean</th>
                          <th>Exp Iron</th>
                          <th>Exp Wash & Iron</th>
                          <th>Exp Dry Clean</th>
                          <th>Status</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {priceListData.map((item, index) => {
                          return (
                            <tr key={index} className={duplicateCloth==item?.cloth?._id.toString()?"bg-danger":""}>
                              <td>
                                <select
                                  value={item?.cloth?._id}
                                  onChange={e =>
                                    handleChangeCloth(e.target.value, index)
                                  }
                                  className={
                                    "form-select " +
                                    (item.errorCloth == true
                                      ? "error-control"
                                      : "")
                                  }
                                >
                                  <option value={""}>Select Cloth</option>
                                  {clothList?.map((item1, index) => {
                                    return (
                                      <option key={index} value={item1?._id}>
                                        {item1?.nameEn}
                                      </option>
                                    )
                                  })}
                                </select>
                              </td>
                              {inputVat ? (
                                <>
                                  <td>
                                    <Input
                                      name="Iron"
                                      type="number"
                                      className={
                                        "form-control " +
                                        (item.erroriPrice == true
                                          ? "error-control"
                                          : "")
                                      }
                                      placeholder="Iron price"
                                      value={item.iPriceVat}
                                      onChange={val =>
                                        handleChangePrice(
                                          val.target.value,
                                          item._id,
                                          "iPriceVat",
                                          index,
                                          true
                                        )
                                      }
                                    />
                                    <div style={infoStyle}>{item.iPrice}</div>
                                  </td>
                                  <td>
                                    <Input
                                      name="wash"
                                      type="number"
                                      className="form-control"
                                      placeholder="Wash price"
                                      value={item.wPriceVat}
                                      onChange={val =>
                                        handleChangePrice(
                                          val.target.value,
                                          item._id,
                                          "wPriceVat",
                                          index,
                                          true
                                        )
                                      }
                                    />
                                    <div style={infoStyle}>{item.wPrice}</div>
                                  </td>
                                  <td>
                                    <Input
                                      name="dryClean"
                                      type="number"
                                      className="form-control"
                                      placeholder="Dry clean Price"
                                      value={item.dPriceVat}
                                      onChange={val =>
                                        handleChangePrice(
                                          val.target.value,
                                          item._id,
                                          "dPriceVat",
                                          index,
                                          true
                                        )
                                      }
                                    />
                                    <div style={infoStyle}>{item.dPrice}</div>
                                  </td>
                                  <td>
                                    <Input
                                      name="expIron"
                                      type="number"
                                      className="form-control"
                                      placeholder="Iron Express price"
                                      value={item?.iExpressPriceVat}
                                      onChange={val =>
                                        handleChangePrice(
                                          val.target.value,
                                          item._id,
                                          "iExpressPriceVat",
                                          index,
                                          true
                                        )
                                      }
                                    />
                                    <div style={infoStyle}>
                                      {item.iExpressPrice}
                                    </div>
                                  </td>
                                  <td>
                                    <Input
                                      name="expWash"
                                      type="number"
                                      className="form-control"
                                      placeholder="Wash Express price"
                                      value={item?.wExpressPriceVat}
                                      onChange={val =>
                                        handleChangePrice(
                                          val.target.value,
                                          item._id,
                                          "wExpressPriceVat",
                                          index,
                                          true
                                        )
                                      }
                                    />
                                    <div style={infoStyle}>
                                      {item.wExpressPrice}
                                    </div>
                                  </td>
                                  <td>
                                    <Input
                                      name="expDryClean"
                                      type="number"
                                      className="form-control"
                                      placeholder="Dry clean Express Price"
                                      value={item?.dExpressPriceVat}
                                      onChange={val =>
                                        handleChangePrice(
                                          val.target.value,
                                          item._id,
                                          "dExpressPriceVat",
                                          index,
                                          true
                                        )
                                      }
                                    />
                                    <div style={infoStyle}>
                                      {item.dExpressPrice}
                                    </div>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    <Input
                                      name="Iron"
                                      type="number"
                                      className={
                                        "form-control " +
                                        (item.erroriPrice == true
                                          ? "error-control"
                                          : "")
                                      }
                                      placeholder="Iron price"
                                      value={item.iPrice}
                                      onChange={val =>
                                        handleChangePrice(
                                          val.target.value,
                                          item._id,
                                          "iPrice",
                                          index
                                        )
                                      }
                                    />
                                    {isVatPercentValid ? (
                                      <div style={infoStyle}>
                                        {item.iPriceVat}
                                      </div>
                                    ) : null}
                                  </td>
                                  <td>
                                    <Input
                                      name="wash"
                                      type="number"
                                      className="form-control"
                                      placeholder="Wash price"
                                      value={item.wPrice}
                                      onChange={val =>
                                        handleChangePrice(
                                          val.target.value,
                                          item._id,
                                          "wPrice",
                                          index
                                        )
                                      }
                                    />
                                    {isVatPercentValid ? (
                                      <div style={infoStyle}>
                                        {item.wPriceVat}
                                      </div>
                                    ) : null}
                                  </td>
                                  <td>
                                    <Input
                                      name="dryClean"
                                      type="number"
                                      className="form-control"
                                      placeholder="Dry clean Price"
                                      value={item.dPrice}
                                      onChange={val =>
                                        handleChangePrice(
                                          val.target.value,
                                          item._id,
                                          "dPrice",
                                          index
                                        )
                                      }
                                    />
                                    {isVatPercentValid ? (
                                      <div style={infoStyle}>
                                        {item.dPriceVat}
                                      </div>
                                    ) : null}
                                  </td>
                                  <td>
                                    <Input
                                      name="expIron"
                                      type="number"
                                      className="form-control"
                                      placeholder="Iron Express price"
                                      value={item?.iExpressPrice}
                                      onChange={val =>
                                        handleChangePrice(
                                          val.target.value,
                                          item._id,
                                          "iExpressPrice",
                                          index
                                        )
                                      }
                                    />
                                    {isVatPercentValid ? (
                                      <div style={infoStyle}>
                                        {item.iExpressPriceVat}
                                      </div>
                                    ) : null}
                                  </td>
                                  <td>
                                    <Input
                                      name="expWash"
                                      type="number"
                                      className="form-control"
                                      placeholder="Wash Express price"
                                      value={item?.wExpressPrice}
                                      onChange={val =>
                                        handleChangePrice(
                                          val.target.value,
                                          item._id,
                                          "wExpressPrice",
                                          index
                                        )
                                      }
                                    />
                                    {isVatPercentValid ? (
                                      <div style={infoStyle}>
                                        {item.wExpressPriceVat}
                                      </div>
                                    ) : null}
                                  </td>
                                  <td>
                                    <Input
                                      name="expDryClean"
                                      type="number"
                                      className="form-control"
                                      placeholder="Dry clean Express Price"
                                      value={item?.dExpressPrice}
                                      onChange={val =>
                                        handleChangePrice(
                                          val.target.value,
                                          item._id,
                                          "dExpressPrice",
                                          index
                                        )
                                      }
                                    />
                                    {isVatPercentValid ? (
                                      <div style={infoStyle}>
                                        {item.dExpressPriceVat}
                                      </div>
                                    ) : null}
                                  </td>
                                </>
                              )}
                              <td>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  name="status"
                                  className="me-1 mb-sm-8 mb-2"
                                  onColor="#626ed4"
                                  onChange={e =>
                                    handlePriceStatus(!item?.status, index)
                                  }
                                  checked={item?.status}
                                  disabled={
                                    !(
                                      userData?.permissions?.allPermissions ==
                                        true ||
                                      userData?.permissions?.pricelist
                                        ?.update == true ||
                                      (userData?.permissions?.pricelist
                                        ?.create == true &&
                                        item?._id == undefined)
                                    )
                                  }
                                />
                              </td>
                              {/* {userData?.permissions?.allPermissions == true ||
                              userData?.permissions?.pricelist?.update ==
                                true ||
                              (userData?.permissions?.pricelist?.create ==
                                true &&
                                item?._id == undefined) ? (
                                <td>
                                  <Button
                                    type="submit"
                                    color="primary"
                                    className="w-md"
                                    onClick={() =>
                                      savePricelistData(item, index)
                                    }
                                  >
                                    Save
                                  </Button>
                                </td>
                              ) : null} */}
                              {item._id ? null : (
                                <td>
                                  <div style={{ width: "20px"}}>
                                  <a href="#"
                                   onClick={(e) => {
                                    e.preventDefault()
                                    handleDeleteCloth(index)
                                  }}
                                  >
                                    <i className="bx bx-sm bxs-trash-alt text-danger"></i>
                                  </a>
                                  
                                  </div>
                                  {/* <Button
                                    type="submit"
                                    color="primary"
                                    className="w-md"
                                    onClick={() =>
                                      savePricelistData(item, index)
                                    }
                                  >
                                    Save
                                  </Button> */}
                                </td>
                              )}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    {userData?.permissions?.allPermissions == true ||
                    userData?.permissions?.pricelist?.create == true ? (
                      <Button
                        type="submit"
                        color="primary"
                        className="w-md"
                        onClick={() => handleAddCloth()}
                      >
                        Add Cloth
                      </Button>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>

        <CopyPricelistModal
          isOpen={isCopyModalOpen}
          toggle={() => setIsCopyModalOpen(!isCopyModalOpen)}
          onAction={handleCopyPricelist}
        />
      <ImportPricesModal apiData={{ [state.priceType]: state.data.value }} clothes={clothList} isOpen={isModalOpen} toggle={toggleModal} />
      </React.Fragment>
    )
  } else {
    return <Pages404 />
  }
}
PriceListUpdate.propTypes = {
  location: PropTypes.object,
}
export default withRouter(PriceListUpdate)
