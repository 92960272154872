import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"

import { Container, Input, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import { getSettings, updateSetting } from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "components/Common/Breadcrumb"

import Pages404 from "pages/Utility/pages-404"
import SettingRow from "./SettingRow"
import { postWeb } from "helpers/api_helper"
import { UPDATE_SETTING } from  "helpers/url_helper"
import toastr from "toastr"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Settings = props => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({})
  const [settings, setSettings] = useState([])
  const [originalData, setoriginalData] = useState([])
  const [searchValue, setSearchValue] = useState("")

  const { settingsList, users } = useSelector(state => ({
    settingsList: state.Settings.settings,
    users: state.Users.users,
  }))

  
  useEffect(() => {
    dispatch(getSettings())
  }, [])

  useEffect(() => {
    if(settingsList && settingsList.length > 0){
      const settingsMap = {};
      settingsList.forEach(s => {
        settingsMap[s.type] = s;
      });

      const settingTypes = ["androidVersion", "iosVersion", 
        "androidAdminVersion", 
        "androidPosVersion",
        "androidDriverVersion",
        "timeslotDays",
        "dutyStartHours",
        "dutyEndHours",
        "bagFee",
        "customerPriceListVAT",
        "offlineDelFee",
        "offlineMinimumOrder",
        "sms",
        "logisticEmails",
        "carpetServiceTime",
        "clothServiceTime",
        "joining_bonus",
        "joining_bonus_image",
        "orderAutoAssign",
        "carpetClothes",
        "defaultOTP",
        "onlinePackagePromo",
        "onlinePackageBanner",
        "mobilePrefix",
        "orderTerms",
        // "cashback",
        "maxGiftDiscountPerOrder",
        "minRefundCombineGift",
        "loyaltyPointsConfig",
        "updatePricelistEnabled",
        "showCustomerAppDiscount",
        "whatsAppSupport",
        "careersWhatsApp",
        "pausedServices",
        "orderPickupHours",
        "workingHrs",
        "deliveryFeeLaunchDate",
        "freeMoneyPromoCode",
      ];

      const _settings = settingTypes.map(s => {
        if(settingsMap[s]){
          return settingsMap[s];
        }
        else{
          return {
            type : s
          }
        }
      });

      setSettings(_settings);
      setIsLoading(false)
      setSearchValue("")
      setoriginalData(_settings)

    }
  }, [settingsList])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0])
    }
  }, [users])
 

  useEffect(() => {
    if (searchValue === '') {
      setSettings(originalData);
    } else {
      // Filter the data based on the search text
      const newFilteredData = originalData.filter(item =>
        item?.type?.toLowerCase().includes(searchValue?.toLowerCase())
      );
      setSettings(newFilteredData);
    }
  }, [searchValue])
  
  const udpateSetting = async (setting, id) => {
    let data; 
    if(!!setting.get && (setting.get("type") === "joining_bonus_image" || setting.get("type") === "onlinePackageBanner")) {
      if(id != undefined){
        setting.append("_id", id)
      }
      data = setting;
    }
    else{
      data = { _id: id, ...setting };
    }

    const response = await postWeb(`${UPDATE_SETTING}`, data);
    if (response?.statusCode == "200") {
      toastr.success("Setting updated successfully")
    }
    else{
      toastr.error(response.msg ||  "Error in updating settings")
    }
    
    dispatch(getSettings());

  }

  const [activeTab, setActiveTab] = useState("2")
 
  const setTab = (tab)=>{
    setActiveTab(tab)
  }
  if (
    userData?.permissions?.allPermissions == true ||
    userData?.permissions?.settings?.read == true
  ) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Settings</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Settings" />
            <Input
              type="text"
              placeholder="Search by Type"
              style={{width:"100%",maxWidth:"600px", marginBottom:"18px"}}
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />

           
            <Nav tabs className="mb-4">
              { 
                  userData?.permissions?.allPermissions == true &&  <NavItem>
                  <NavLink
                    className={activeTab == "1" ? "active" :""}
                    onClick={()=>setTab("1")}
                  >
                    System
                  </NavLink>
                </NavItem>
              }
    <NavItem>
      <NavLink
          className={activeTab == "2" ? "active" :""}
        onClick={()=>setTab("2")}
      >
        Operation
      </NavLink>
    </NavItem>
  </Nav>
  <TabContent activeTab={activeTab}>
    {
      isLoading &&   <Skeleton count={10} />
    }
    <TabPane tabId="1">
      {settings.filter((settings)=>{
                  return settings?.category == "System" || !settings.category
                }).
                map(setting => {
                  return (
                    <SettingRow
                      key={setting.type}
                      setting={setting}
                      activeTab={activeTab}
                      userData ={userData}
                      onAction={updateSetting =>
                        udpateSetting(updateSetting, setting._id)
                      }
                    />
                  )
                })}
    </TabPane>
    <TabPane tabId="2">
    {settings.filter((settings)=>{
                  return settings?.category == "Operation"
                }).
                map(setting => {
                  return (
                    <SettingRow
                      key={setting.type}
                      setting={setting}
                      userData ={userData}
                      onAction={updateSetting =>
                        udpateSetting(updateSetting, setting._id)
                      }
                    />
                  )
                })}
    </TabPane>
  </TabContent>
          </Container>
        </div>
      </React.Fragment>
    )
  } else {
    return <Pages404 />
  }
}

Settings.propTypes = {}

export default withRouter(Settings)
