import { takeEvery, put, call } from "redux-saga/effects";
import { GET_INVOICE_LOGS_SUCCESS, GET_INVOICE_LOGS_FAIL, GET_INVOICE_LOGS } from "./actionTypes";
import { apiSuccess, apiFail } from './actions';
import { getInvoiceLogs } from "helpers/fakebackend_helper"; // updated import name to avoid conflicts
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function* fetchInvoiceLogs({ payload }) {
  try {
    const { searchText, laundryId, fromDate, toDate, pageNo, pageSize, selectedFilter } = payload;
    const response = yield call(getInvoiceLogs, { searchText, laundryId, fromDate, toDate, pageNo, pageSize, selectedFilter });
    yield put(apiSuccess(response, GET_INVOICE_LOGS_SUCCESS));
    // Check if the response is successful, if so, dispatch the success action
    if (response.statusCode == 200) {
      yield put(apiSuccess(response, GET_INVOICE_LOGS_SUCCESS));
    } else {
      // Handle error response
      yield put(apiFail(response.message || 'Something went wrong', GET_INVOICE_LOGS_FAIL));
    }
  } catch (error) {
    // Handle any unexpected errors
    console.log('error',error)
    yield put(apiFail(error.message, GET_INVOICE_LOGS_FAIL));
    toastr.error('An error occurred while fetching invoice logs');
  }
}

function* invoiceLogsSaga() {
  yield takeEvery(GET_INVOICE_LOGS, fetchInvoiceLogs);
}

export default invoiceLogsSaga;
