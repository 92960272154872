import { GET_VENDORS } from "./actionTypes";

export const apiSuccess = (data, actionType) => ({
  type: actionType,
  payload: data
})

export const apiFail = (data, actionType) => ({
  type: actionType,
  payload: data
})

export const getVendors = (data) => ({
  type: GET_VENDORS,
  payload: data
})