import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags";
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
import Pages404 from "pages/Utility/pages-404";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Card,
  Label,
} from "reactstrap"
import { addUser, updateUser } from "helpers/userHelper"

//import action
import { getUsers, toggleUserPermission, getPermission as getAllPermission } from "../../store/actions"

import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import DataTable from "react-data-table-component";

const Users = props => {
  const [userModal, SetUserModal] = useState(false)
  const [userData, setUserData] = useState({});
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [userRoleOptions, setUserRoleOptions] = useState([])

  const { userList, userDetails, users, roles } = useSelector(state => ({
    userList: state.Users.userList,
    userDetails: state.Users.userDetails,
    roles: state.permission.permissions,
    users: state.Users.users
  }))


  const userListColumns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Role',
      selector: 'role.name',
      sortable: true,
    },
    {
      name: 'Actions',
      cell: row => (
        <div className="d-flex gap-3">
          <Link to="#" className="text-success" onClick={() => handleUserEdit(row)}>
            <i className="bx bx-edit font-size-20"></i>
          </Link>
        </div>
      ),
    },
  ];

  function toggleUserModal() {
    SetUserModal(!userModal);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])

  const fetchRoles = ()=>{
    let userRoles = [];
    if (userData?.permissions?.allPermissions) {
      roles?.map((item) => {
        if (item._id != "637cb53f63f5416964433181") {
          userRoles.push({ label: item.name, value: item._id })
        }
      })
    } else if (userData?.permissions?.users?.validations?.createRoles) {
      roles?.map((item) => {
        if (userData?.permissions?.users?.validations?.createRoles?.includes(item._id)) {
          userRoles.push({ label: item.name, value: item._id })
        }
      })
    }
    setUserRoleOptions(userRoles)
  }

  useEffect(() => {
    fetchRoles()
  }, [userData, roles])


  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 3000,
    extendedTimeOut: 1000,
    closeButton: true,
    debug: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000
  };

  const handleValidSubmit = async (event, values) => {
    
    values["web"] = true
    values["status"] = status ? "active" : "inactive"
    const userResponse = await addUser(values);
    if (userResponse.statusCode == 400) {
      toastr.warning(userResponse.msg, "Unable to add User");
    }
    else if (userResponse.statusCode == 200) {
      SetUserModal(false);
      dispatch(getUsers());
      toastr.success("User has been added", "User Added");
    }
  }

  const getPermission = (perm, action) => {
    if (userDetails.permissions && userDetails.permissions[perm] && userDetails.permissions[perm][action] == true) {
      return true;
    }
    else {
      return false;
    }
  }

  const togglePermission = (perm, action) => {
    dispatch(toggleUserPermission({
      user: userDetails,
      perm: perm,
      action: action
    }));
  }

  const [editUser, setEditUser] = useState(false);
  const [editUserDetails, setEditUserDetails] = useState({});
  const [status, setStatus] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const handleUserEdit = (user) => {  
    setEditUser(true);
    setEditUserDetails(user);
    setStatus(user?.status == "active" ? true : false);
  }
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEditUserSubmit = async (event, values) => {
    values["id"] = editUserDetails?._id;
    values["role"] = editUserDetails?.role?._id;
    values["status"] = status ? "active" : "inactive";
    const userResponse = await updateUser(values);
    if (userResponse.statusCode == 400) {
      toastr.warning(userResponse.msg, "Unable to update User");
    }
    else if (userResponse.statusCode == 200) {
      setEditUser(false);
      dispatch(getUsers());
      toastr.success("User has been updated", "User Updated");
    }
  }

  const filterUsers = () => {
    const filteredUsersList = userList.filter((user) => {
      return user.status == (status ? "active" : "inactive")
    });
    setFilteredUsers(filteredUsersList);
  }

  useEffect(() => {
    filterUsers();
  }, [status]);

  useEffect(() => {
    setFilteredUsers(userList);
  }, [userList]);

  const PermCheckbox = (props) => {

    return (
      <React.Fragment>
        <tr>
          <td>{props.title}</td>
          <td>
            <input
              type="checkbox"
              checked={getPermission(props.perm, 'create')}
              onChange={() => togglePermission(props.perm, 'create')}
            />
          </td>
          <td>
            <input
              type="checkbox"
              checked={getPermission(props.perm, 'search')}
              onChange={() => togglePermission(props.perm, 'search')}
            />
          </td>
          <td>
            <input
              type="checkbox"
              checked={getPermission(props.perm, 'update')}
              onChange={() => togglePermission(props.perm, 'update')}
            />
          </td>

        </tr>
      </React.Fragment>
    )
  }

  PermCheckbox.propTypes = {
    title: PropTypes.string,
    perm: PropTypes.string
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllPermission({}))
    fetchRoles()
  }, []);

  const handleAddUserModal = () => {
    SetUserModal(true)
    setName("")
    setEmail("")
    setPassword("")
  }

  useEffect(() => {
    if(userModal==true){
      setTimeout(() => {
        setName("")
        setEmail("")
        setPassword("")
      }, 1000);
    }
    
  }, [userModal])
  useEffect(() => {
    const filteredList = searchTerm
      ? userList.filter(user =>
          user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.role.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : userList;

    setFilteredUsers(filteredList);
  }, [searchTerm, userList]);
  const totalSize = filteredUsers.length || userList.length;

  if (userData?.permissions?.allPermissions !== true && userData?.permissions?.users?.read !== true) {
    return <Pages404 />
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users | Kleen Admin Panel</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Home")}
            breadcrumbItem={props.t("Users")}
          />
       <Row>
            <Col>
              <Row className="mb-2 d-flex justify-content-between align-items-center">
              <Col sm="4">
                  <div className="search-box mb-2">
                    <div className="d-flex align-items-center">
                      <input
                        id="search-bar-10"
                        type="text"
                        aria-labelledby="search-bar-10-label"
                        className="form-control"
                        style={{ width: "100%", height: "38px" }} // Use 100% width for better responsiveness
                        placeholder="Search"
                        onChange={handleSearch} // Use handleChange to debounce setSearchValue
                      />
                      <i className="bx bx-search-alt search-icon ms-2" />
                    </div>
                  </div>
                </Col>

                <Col sm="4">
                  <div className="d-flex align-items-center ">
                    <div className="d-flex align-items-center">
                      <span className={`me-2 ${status ? 'text-success font-weight-semibold' : ''}`}>
                        Status: {status ? 'Active' : 'Inactive'}
                      </span>
                      <div className="form-check form-switch form-switch-lg">
                        <input
                          type="checkbox"
                          className="form-check-input form-slider-color-green"
                          defaultChecked={status}
                          name="statusFilter"
                          onChange={() => setStatus(!status)}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                {
                  userData?.permissions?.allPermissions === true || userData?.permissions?.users?.create === true ? (
                    <Col sm="2">
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded mb-2 me-2"
                          onClick={handleAddUserModal}
                        >
                          <i className="mdi mdi-plus me-1" />
                          Add User
                        </Button>
                      </div>
                    </Col>
                  ) : null
                }
              </Row>

              <DataTable
                columns={userListColumns}
                data={filteredUsers}
                pagination
                highlightOnHover
                pointerOnHover
                noHeader
              />
            </Col>
          </Row>
          <div>
            <Modal
              size="lg"
              isOpen={userModal}
              toggle={() => {
                toggleUserModal();
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Create User
                </h5>
                <button
                  type="button"
                  onClick={() => {
                    SetUserModal(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Card>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Name
                        </Label>
                        <AvField
                          type="text"
                          className="form-control"
                          id="horizontal-firstname-Input"
                          placeholder="Display Name"
                          name="name"
                          errorMessage="Please enter display name"
                          value={name}
                          onChange={(e) => { setName(e.target.value) }}
                          validate={{ required: { value: true } }}
                        />
                      </div>
                      <div className="col-md-6 mb-2">
                        <Label
                          htmlFor="horizontal-email-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Email
                        </Label>
                        <AvField
                          type="email"
                          name="email"
                          className="form-control"
                          id="horizontal-email-Input"
                          placeholder="Email"
                          errorMessage="Invalid Email"
                          value={email}
                          onChange={(e) => { setEmail(e.target.value) }}
                          validate={{
                            required: { value: true },
                            email: { value: true },
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <Label
                          htmlFor="horizontal-password-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Password
                        </Label>
                        <AvField
                          type="password"
                          name="password"
                          className={"form-control "}
                          id="horizontal-password-Input"
                          value={password}
                          onChange={(e) => { setPassword(e.target.value) }}
                          placeholder="Enter Password"
                          errorMessage="Please enter password"
                          validate={{
                            required : {value : true, errorMessage:"Please enter password"},
                            pattern: {
                              value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,16}$/, 
                              errorMessage:"Password must be at least 8 chars, 1 capital, 1 small letter, 1 number and 1 sepacial charater"}
                          }}
                         
                        />
                       
                      </div>
                      <div className="col-md-6 mb-2">
                        <Label
                          htmlFor="horizontal-password-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Role
                        </Label>
                        <AvField
                          type="select"
                          name="role"
                          // defaultValue="0"
                          className="form-select"
                          errorMessage="Please select User role"
                          validate={{ required: { value: true } }}
                        // required
                        >
                          <option value="">Select User Role</option>
                          {
                            userRoleOptions?.map((item) => {
                              return <option value={item?.value} key={item?.value}>{item?.label}</option>
                            })
                          }
                          {/* <option value="admin">Admin</option>
                            <option value="operation_manager">Operation Manager</option> */}
                        </AvField >
                      </div>
                    </div>

                    <div className="row">
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center mx-3">
                        <span className={`me-2 ${status ? "text-success font-weight-semibold" : ""}`}>
                          Status: {status ? "Active" : "Inactive"}
                        </span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={status}
                            name="status"
                            onChange={(e) => { setStatus(!status) }}
                          />
                        </div>
                      </div>
                    </div>
                      </div>

                    <div className="d-flex mt-4 text-end" style={{ float: "right" }}>
                      <Button
                        type="submit"
                        color="primary"
                      >Add User</Button>
                      <Button
                        type="button"
                        onClick={() => {
                          toggleUserModal();
                        }}
                        className="mx-2"
                      // data-dismiss="modal"
                      >Close</Button>
                    </div>
                  </AvForm>
                </Card>
              </div>
            </Modal>

            <Modal
              size="lg"
              isOpen={editUser}
              toggle={() => {
                setEditUser(!editUser);
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Edit User
                </h5>
                <button
                  type="button"
                  onClick={() => {
                    setEditUser(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Card>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleEditUserSubmit(e, v);
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Name
                        </Label>
                        <AvField
                          type="text"
                          className="form-control"
                          id="horizontal-firstname-Input"
                          placeholder="Display Name"
                          name="name"
                          errorMessage="Please enter display name"
                          value={editUserDetails && editUserDetails?.name}
                          validate={{ required: { value: true } }}
                          disabled={true}
                        />
                      </div>
                      <div className="col-md-6 mb-2">
                        <Label
                          htmlFor="horizontal-role-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Role
                        </Label>
                        <AvField
                          type="text"
                          name="role"
                          className="form-control"
                          id="horizontal-role-Input"
                          placeholder="User Rolw"
                          errorMessage="Select user role"
                          value={editUserDetails && editUserDetails?.role?.name}
                          validate={{required: { value: true },}}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div className="row">
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center mx-3">
                        <span className={`me-2 ${status ? "text-success font-weight-semibold" : ""}`}>
                          Status: {status ? "Active" : "Inactive"}
                        </span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={status}
                            name="status"
                            onChange={(e) => { setStatus(!status) }}
                          />
                        </div>
                      </div>
                    </div>
                      </div>                     

                    <div className="d-flex mt-4 text-end" style={{ float: "right" }}>
                      <Button
                        type="submit"
                        color="primary"
                      >Update User</Button>
                      <Button
                        type="button"
                        onClick={() => {
                          setEditUser(false);
                        }}
                        className="mx-2"
                      // data-dismiss="modal"
                      >Close</Button>
                    </div>
                  </AvForm>
                </Card>
              </div>
            </Modal>
          </div >
        </Container >
      </div >

    </React.Fragment >

  )
}

Users.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Users)
