import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { useLocation,useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardText, Col, Input, Row } from "reactstrap";
import { getOrderByID } from "store/actions"; // Assuming these actions are defined
import "toastr/build/toastr.min.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const OrderDetails = () => {
  const dispatch = useDispatch();
  const { orderByID } = useSelector(state => ({
    orderByID: state.Orders.orderByID,
  }));

  const initailStateOfErrors = {
    orderID: "",
    reason: "",
    approvedBy: "",
    notFound: "",
    failed: "",
  }
  const [orderData, setOrderData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [orderID, setOrderID] = useState("");
  const [error, setError] = useState(initailStateOfErrors);
  const [isInitialSearch, setIsInitialSearch] = useState(false); // Tracks if search is from query param
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
  
  const orderId = searchParams.get("orderId");
  if (orderId) {
    setOrderID(orderId);
    setIsInitialSearch(true); // Set flag to true for initial search
  }
}, []);

 // Trigger search if it's an initial search
 useEffect(() => {
  if (isInitialSearch) {
    handleSearch({ preventDefault: () => {} });
    setIsInitialSearch(false); // Reset the flag
  }
}, [isInitialSearch]);

  useEffect(() => {
    const _orderByID = orderByID?.record;
    if (_orderByID) {
      setOrderData(_orderByID[0]);
      setError(initailStateOfErrors);
    }
  }, [orderByID]);
//if order not found show toastr error
  useEffect(() => {
    if (orderByID?.record?.length == 0 || orderByID?.record == []) {
      setError({ ...error, orderID: "Order not found." });
    }
  }, [orderByID]);

  useEffect(() => {
    if (orderID) {
      setOrderData({});
      setError({...error , failed:false})
    }
  }, [orderID]);

  const handleSearch = async () => {
    setError(initailStateOfErrors);
    if (orderID) {
      setIsLoading(true);
      dispatch(getOrderByID({ orderID }));
      setIsLoading(false);
    } else {
      setError({ ...error, orderID: "Please enter a valid order ID" });
    }
    history.replace(location.pathname); // This will remove the query parameters
  };

  const handleChange = (e) => {
    let value = e.target.value;
    // Remove any non-digit characters
    value = value.replace(/[^\d]/g, '');
    value = value.replace(/[^\d]/g, ''); // Remove non-digit characters
    if (value === '0') {
      value = ''; // or set it to some default value if needed
    }

    setOrderID(value);
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Order Management | Order Details</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Order Management" breadcrumbItem="Order Details" />
          
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col sm="12">
                      <div className="me-2 mb-2 d-inline-block">
                        <CardText className="mb-2 pt-2 fs-5 fw-bold">Order ID: </CardText>
                        <div className="input-group">
                          <Input
                            type="text"
                            placeholder="Search Order"
                            className="form-control"
                            value={orderID}
                            onChange={handleChange}
                            onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                            invalid={error.orderID ? true : false}
                          />
                          <Button
                            className="ms-2"
                            color="primary"
                            onClick={handleSearch}
                            disabled={isLoading}
                            >
                            Search
                          </Button>
                        </div>
                            {error.orderID && <div className="text-danger">{error.orderID}</div>}
                      </div>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col sm="12">
                    {orderData && orderID && orderData._id && (
                        <Row className="mt-4">
                          <Col sm="4">
                            <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                            <strong>Order ID:</strong> {orderData.orderID}
                            </CardText>
                          </Col>
                          <Col sm="4">
                            <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                            <strong>Customer Id:</strong> {orderData.customer?.customerID}
                            </CardText>
                          </Col>
                          <Col sm="4">
                            <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                            <strong>Customer Name:</strong> {orderData.customer?.name}
                            </CardText>
                          </Col>
                          <Col sm="4">
                            <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                            <strong>Status:</strong> {orderData.status}
                            </CardText>
                          </Col>
                          <Col sm="4">
                            <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                            <strong>Paid:</strong> {orderData.paid ? "Yes" : "No"}
                            </CardText>
                          </Col>
                          <Col sm="4">
                            <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                            <strong>Category:</strong> {orderData.category}
                            </CardText>
                          </Col>
                          <Col sm="4">
                            <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                            <strong>Grand Total:</strong> SAR {orderData.grandTotal}
                            </CardText>
                          </Col>
                          <Col sm="4">
                            <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                            <strong>Laundry:</strong> {orderData.laundry?.nameEn}
                            </CardText>
                          </Col>
                 
                        </Row>
                      )}
                     
                        {error.failed && <div className="text-danger fs-4 mt-2">{error.failed}</div>}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderDetails;
