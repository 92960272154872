import axios from "axios"
import { del, get, post, put, postWeb } from "./api_helper"
import * as url from "./url_helper"

const fetchUserList = (data) => postWeb(`${url.USERS_LIST_ALL}`, { data })
const postAddUser = (data) => postWeb(`${url.POST_ADD_USER}`, data)
const postUpdateUser = (data) => postWeb(`${url.POST_UPDATE_USER}`, data)
const getUser = (userId) => post(`${url.GET_USER}`, { userId: userId })
const userDelete = (userId) => post(`${url.USER_DELETE}`, { userId: userId })
const fetchUsers = (data) => postWeb(`${url.SEARCH_USERS}`, data)
const addUser = (data) => post(`${url.ADD_USER}`, data)
const updateUser = (data) => post(`${url.POST_UPDATE_USER}`, data);
const updateCredentials = data=> post(`${url.UPDATE_CREDENTIALS}`, data)
export {
  fetchUserList,
  postAddUser,
  getUser,
  postUpdateUser,
  userDelete,
  fetchUsers,
  addUser,
  updateUser,
  updateCredentials
}
