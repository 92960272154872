import React, { useState } from "react"
import { Row, Col, Card, CardBody, Input, Button, CardText } from "reactstrap"
import MetaTags from 'react-meta-tags'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import 'react-loading-skeleton/dist/skeleton.css'
import Pages404 from "pages/Utility/pages-404";
import "toastr/build/toastr.min.css";
import { searchCustomer } from "store/actions"
import { postWeb } from "helpers/api_helper"
import toastr from "toastr"

//Component to change Customer Mobile Number
const ChangeNumber = () => {
  
  const phCode = process.env.REACT_APP_PHONE_PREFIX || 966;

  const dispatch = useDispatch()
  const { users, searchedCustomer } = useSelector(state => ({
    users: state.Users.users,
    searchedCustomer: state.Customers.searchedCustomer
  }))
  const initailStateOfErrors = {
    newMobile:'',
    approvedBy:'',
    reason:'',
    notFound:''
  }
  const [customerData, setCustomerData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [customerID, setCustomerID] = useState("");
  const [mobile, setMobile] = useState("");
  const [newMobile, setNewMobile] = useState("");
  const [error, setError] = useState(initailStateOfErrors);
  const [phoneMaxLen, setPhoneMaxLen] = useState(
    phCode == 91 ? 10 : 9
  );
  const [reason, setReason] = useState("");
  const [approvedBy, setApprovedBy] = useState("")
  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {
    if (searchedCustomer && Object.keys(searchedCustomer).length) {
      setError(initailStateOfErrors)
      setCustomerData(searchedCustomer)
      setMobile(searchedCustomer.mobile)
    } else if(customerID && !Object.keys(searchedCustomer).length){
      setError({notFound:"Customer details not found"})
    }
    setIsLoading(false);
  }, [searchedCustomer])

  useEffect(() => {
    if (customerID) {
      setCustomerData({}); // Reset customerData whenever searching for a new customer
      setApprovedBy('');
      setReason('');
      setNewMobile('');
    }
  }, [customerID]);

  const handleSearch = async () => {
    setError(initailStateOfErrors)
    if (customerID) {
      setCustomerData({});
      setIsLoading(true);
      dispatch(searchCustomer({customerID}));
      setIsLoading(false);
    } else {
      setCustomerData({})
      setError({notFound:"Please enter a valid customer ID"})
    }
  }
  
  const handleChange = (e) => {
    let value = e.target.value;
    // Remove any non-digit characters
    value = value.replace(/[^\d]/g, '');
    if (value === '0') {
      value = ''; // or set it to some default value if needed
    }
    setCustomerID(value);
  };
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  }
  const handleApprovedByChange = (e) => {
    setApprovedBy(e.target.value);
  }

  const handleNewMobileChange = (e) => {
    setError({...error, newMobile:''})
    let value = e.target.value;

    if (value === '') {
      setError(initailStateOfErrors);
      setNewMobile('');
      return;
    }

    if ( isNaN(value) || value <= 0) {
      return;
    }
    // Remove any non-digit characters
    value = value.replace(/[^\d]/g, '');

    if (value.length <= 10) {
      setNewMobile(e.target.value);
      validateMobile(e.target.value);
    }
  };

  const validateMobile = (number) => {
    let regex;
    if (phCode == 91) {
      // India: 10 digits, starting with 6, 7, 8, or 9
      regex = /^[6-9]\d{9}$/;
      setPhoneMaxLen(10);
    } else if (phCode == 966) {
      // Saudi Arabia: 9 digits, or 10 if starting with 0
      regex = /^0?\d{9}$/;
      if (number[0] == '0') {
        setPhoneMaxLen(10);
      } else {
        setPhoneMaxLen(9);
      }
    } else {
      // Handle other cases or default to invalid
      setError({...error, newMobile:"Invalid country code."});
      return;
    }

    if (number === "" || regex.test(number)) {
      setError({...error, newMobile:''});
    } else {
      setError(
       {...error, newMobile: phCode == 91
          ? "Mobile number must be 10 digits long and start with 6, 7, 8, or 9."
          : "Mobile number must be 9 digits long, or 10 if starting with 0."}
      );
    }
  };
  
  const handleUpdate = async () => {
    // Reset errors at the beginning
    setError(initailStateOfErrors);

    const fullNewMobile = `${phCode}${newMobile}`; // Combine phone code and new mobile
  
    if (newMobile && customerData?.mobile !== fullNewMobile) {
      if ( newMobile.length == phoneMaxLen) {
        if (reason === "") {
          setError(prevError => ({ ...prevError, reason: 'Please enter a reason for changing the mobile number.' }));
          return;
        }
        if (!approvedBy) {
          setError(prevError => ({
            ...prevError,
            approvedBy: 'This field is required!'
          }));
          return;
        }
  
        setIsLoading(true);
        try {
          const response = await postWeb("customers/updateMobile", {
            _id: customerData._id,
            newMobile: fullNewMobile,
            approvedBy,
            reason
          });
  
          if (response.statusCode === "200") {
            toastr.success("Mobile number updated successfully.");
            setMobile(fullNewMobile);
            setNewMobile("");
            setReason("");
            setApprovedBy('');
            setCustomerID("");
            setCustomerData({});
          } else {
            setError(prevError => ({ ...prevError, newMobile: response.msg || "Failed to update mobile number." }));
          }
        } catch (error) {
          setError(prevError => ({ ...prevError, newMobile: "An unexpected error occurred. Please try again later." }));
        } finally {
          setIsLoading(false);
        }
      } else {
        setError(prevError => ({ ...prevError, newMobile: 'Please enter a valid mobile number.' }));
      }
    } else {
      setError(prevError => ({ ...prevError, newMobile: 'Please enter a new mobile number different from the current one.' }));
    }
  };
  
  if (userData?.permissions?.allPermissions == true || userData?.permissions?.supportChangeMobile?.update == true) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Kleen | Change Number</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Support Tools" breadcrumbItem="Change Mobile No." />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {/* Search tag to search customer ID */}
                    <Row className="mb-3">
                      <Col sm="12">
                        <div className="me-2 mb-2 d-inline-block">
                          <div className="position-relative d-flex gap-1 justify-content-evenly align-items-center">
                            <CardText className="mb-2 pt-2 fs-5 fw-bold">Customer ID: </CardText>
                            <div className="ms-2" style={{ width: "10rem" }}>
                              {/* Input to search customer by Customer ID */}
                              <div className="input-group">
                                <Input
                                  type="text"
                                  placeholder="Search Customer"
                                  className="form-control"
                                  value={customerID}
                                  onChange={handleChange}
                                  invalid={error?.notFound.length ? true : false}
                                  onKeyPress={handleKeyPress} // Call handleSearch on Enter key press
                                />
                              {error?.notFound.length ? <div className="text-danger">{error.notFound}</div> : null}
                              </div>
                            </div>

                            <Button
                              className="ms-2"
                              color="primary"
                              onClick={handleSearch}
                              disabled={isLoading}
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {/* Display customers name, current mobile number and input for new number */}
                   {customerData && (!error?.notFound.length && Object.keys(customerData).length > 0 ) && customerID && <Row>
                      <Col sm="12">
                        <Row>
                          <Col sm="6" className="d-flex gap-1">
                            <CardText className="mb-2 pt-2 fs-5 fw-bold">Customer Name: </CardText>
                            <CardText className="mb-2 pt-2 fs-5">{customerData ? customerData?.name : ""}</CardText>
                          </Col>
                          <Col sm="6" className="d-flex gap-1">
                            <CardText className="mb-2 pt-2 fs-5 fw-bold">Current Mobile: </CardText>
                            <CardText className="mb-2 pt-2 fs-5">{customerID ? mobile : ""}</CardText>
                          </Col>
                        </Row>
                        <Row className="mt-3 d-flex align-items-center">
                        <Col sm="6">
                            <CardText className="mb-2 pt-2 fs-5 w-50 fw-bold">New Mobile Number: </CardText>
                            <Input
                              type="text"
                              placeholder="Enter new mobile number"
                              className="form-control w-50"
                              value={newMobile}
                              onChange={handleNewMobileChange}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleNewMobileChange();
                                }}
                              }
                              invalid={error?.newMobile.length ? true : false}
                            />
                        {error?.newMobile.length ? <div className="text-danger">{error.newMobile}</div> : null}                           
                        </Col>
                          <Col sm="6" className="mt-4">
                            <CardText className="mb-2 pt-2 fs-5 fw-bold">Reason for Change: </CardText>
                            <Input
                              placeholder="Enter reason for change"
                              className="form-control w-50"
                              value={customerData ? reason: ""}
                              onChange={handleReasonChange}
                              invalid={error?.reason.length ? true : false}
                             aria-multiline={true}
                            />
                        {error?.reason.length ? <div className="text-danger">{error?.reason}</div> : null}

                          </Col>
                          <Col sm="6" className="mt-4">
                            <CardText className="mb-2 pt-2 fs-5 fw-bold">Approved By: </CardText>
                            <Input
                              placeholder="Approved By..."
                              className="form-control w-50"
                              value={customerData ? approvedBy: ""}
                              onChange={handleApprovedByChange}
                              invalid={error?.approvedBy.length? true : false}
                            />
                        {error?.approvedBy.length ? <div className="text-danger">{error.approvedBy}</div> : null}
                        </Col>
                        <Col sm='12'  className="mt-4"  >
                        <Button
                              color="success"
                              onClick={handleUpdate}
                              disabled={isLoading}
                            >
                              Update
                            </Button>                        
                        </Col>
                        </Row>
                      </Col>
                    </Row>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  } else {
    return <Pages404 />
  }
}

export default ChangeNumber

