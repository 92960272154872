import { GET_VEHICLES } from "./actionTypes";

export const apiSuccess = (data, actionType) => ({
  type: actionType,
  payload: data
})

export const apiFail = (data, actionType) => ({
  type: actionType,
  payload: data
})

export const getVehicles = (data) => ({
  type: GET_VEHICLES,
  payload: data
})