import { takeEvery, put, call } from "redux-saga/effects";
import { GET_LAUNDRY_WORKERS, GET_LAUNDRY_WORKERS_FAIL, GET_LAUNDRY_WORKERS_SUCCESS } from "./actionTypes";
import { apiSuccess, apiFail } from './actions';
import { getLaundryWorkersList } from "helpers/fakebackend_helper"; // updated import name to avoid conflicts
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function* fetchLaundryWorkers({ payload }) {
  try {
    const { page, pageSize, searchText, } = payload;
    const response = yield call(getLaundryWorkersList, {
      page, pageSize, searchText
    });
    yield put(apiSuccess(response, GET_LAUNDRY_WORKERS_SUCCESS));
    // Check if the response is successful, if so, dispatch the success action
    if (response.statusCode == 200) {
      yield put(apiSuccess(response, GET_LAUNDRY_WORKERS_SUCCESS));
    } else {
      // Handle error response
      yield put(apiFail(response.message || 'Something went wrong', GET_LAUNDRY_WORKERS_FAIL));
      toastr.error(response.message || 'Failed to fetch laundry workers list');
    }
  } catch (error) {
    // Handle any unexpected errors
    console.log('error', error)
    yield put(apiFail(error.message, GET_LAUNDRY_WORKERS_FAIL));
    toastr.error('An error occurred while fetching laundry workers list');
  }
}

function* laundryWorkersSaga() {
  yield takeEvery(GET_LAUNDRY_WORKERS, fetchLaundryWorkers);
}

export default laundryWorkersSaga;
