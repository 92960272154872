import { GET_PART_CHANGES_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  partChanges: [],
  total: 0,  // for pagination
};

const partChange = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PART_CHANGES_SUCCESS:
      return {
        ...state,
        partChanges: action.payload.records || [],
        total: action.payload.totalRecords || 0,
      };
    default:
      return state;
  }
};

export default partChange;
