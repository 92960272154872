import { GET_LAUNDRY_WORKERS_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  workers: [],
  totalWorkers: 0,  // for pagination
};

const laundryWorkers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LAUNDRY_WORKERS_SUCCESS:
      return {
        ...state,
        workers: action.payload.data || [],
        totalWorkers: action.payload.totalWorkers || 0,
      };
    default:
      return state;
  }
};

export default laundryWorkers;
