import React from 'react';
import { Table } from 'reactstrap';
import { getClothName } from './JsonTableViewer';
import PropTypes from 'prop-types';

const ClotheChanges = ({ oldClothes = [], newClothes = [], clothes = [] }) => {
  const allClothes = new Set([
    ...oldClothes.map(item => item.cloth),
    ...newClothes.map(item => item.cloth),
  ]);

  const getItem = (clothName, clothesArray) =>
    clothesArray.find(item => item.cloth === clothName) || {};

  const getColor = (oldCount, newCount) => {
    if (oldCount === 0 && newCount === 1) return 'lightgreen';
    if (oldCount < newCount) return 'blue';
    if (oldCount > newCount) return 'red';
    return 'black';
  };

  const renderCell = (oldCount, newCount) => (
    <>
      <td className='text-center' style={{ color: getColor(oldCount, newCount), border: "1px solid black" }}>
        {oldCount}
      </td>
      <td className='text-center' style={{ color: getColor(oldCount, newCount), border: "1px solid black" }}>
        {newCount}
      </td>
    </>
  );

  return (
    <Table bordered hover responsive size="sm" style={{ fontSize: '0.9rem' }}>
      <thead>
        <tr>
          <th style={{ border: "1px solid black" }}></th>
          {['Wash', 'Iron', 'Dry Clean'].map(type => (
            <React.Fragment key={type}>
              <th className='text-center' style={{ border: "1px solid black" }} colSpan="2">{type}</th>
            </React.Fragment>
          ))}
        </tr>
        <tr>
          <th className='text-center' style={{ border: "1px solid black" }}>Cloth Name</th>
          {['Old', 'New', 'Old', 'New', 'Old', 'New'].map((header, index) => (
            <th key={index} className='text-center' style={{ border: "1px solid black" }}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...allClothes].map((clothName, index) => {
          const oldItem = getItem(clothName, oldClothes);
          const newItem = getItem(clothName, newClothes);
          return (
            <tr key={index}>
              <td className='text-center' style={{ border: "1px solid black" }}>{getClothName(clothName, clothes)}</td>
              {renderCell(oldItem.wCount || 0, newItem.wCount || 0)}
              {renderCell(oldItem.iCount || 0, newItem.iCount || 0)}
              {renderCell(oldItem.dCount || 0, newItem.dCount || 0)}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};


ClotheChanges.propTypes = {
  oldClothes: PropTypes.array,
  newClothes: PropTypes.array,
  clothes: PropTypes.array,
};

export default ClotheChanges;
